import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Button, Modal } from '@mui/material';
import { add, format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';
import { grey200, muiBorderColor } from '../../global/css';
import { defaultDisabledMonitoringList, getMonitoringTableHeader } from '../../global/types';
import { LoginUserContext } from '../../hooks/LoginUserHook';
import DatePicker from '../DatePicker';
import Loading from '../Loading';
import Table from '../table/Table';
import TableColumnFilter from '../table/TableColumnFilter';
import TablePagination from '../table/TablePagination';
import MonitoringDetailInfo from './MonitoringDetailInfo';

const MonitoringDetailMonitoringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  & > div {
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ModalContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${grey200};
  border-radius: 12px;
  box-sizing: border-box;
  background-color: white;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 20px;
  min-width: 70%;
  max-height: 100vh;
  overflow-y: auto;
`;

const ModalBottomWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 16px 0 0;
  box-sizing: border-box;
`;

const ModalContentWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${grey200};
  border-radius: 12px;
  box-sizing: border-box;
  background-color: white;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 4px 8px;
`;

type MonitoringDetailMonitoringProps = {
  data: { [props: string]: any };
  upDownStatus: number;
};

const MonitoringDetailMonitoring = (props: MonitoringDetailMonitoringProps) => {
  const { loginUser } = useContext(LoginUserContext);
  const { data, upDownStatus } = props;

  const [selectedDateRange, setSelectedDateRange] = useState<[Range]>([
    {
      startDate: add(new Date(), { days: -7 }),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [modalToggle, setModalToggle] = useState<boolean>(false);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);

  const [selectedSort, setSelectedSort] = useState('');

  const [dataList, setDataList] = useState<{ [props: string]: any }[]>([]);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<{ [props: string]: any }>({});

  const [tableColumnInactiveListToggle, setTableColumnInactiveListToggle] = useState<boolean>(false);
  const [tableColumnInactiveList, setTableColumnInactiveList] = useState<string[]>([]);
  const [tableColumnInactiveListLoaded, setTableColumnInactiveListLoaded] = useState<boolean>(false);

  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const handleDate = (item: Range) => {
    setSelectedDateRange(() => [item]);
  };

  const handleModal = (data: any) => {
    setSelectedData(data);
  };

  const handleColumnFilter = () => {
    setTableColumnInactiveListToggle(() => true);
  };

  const handleFilterReset = () => {
    localStorage.removeItem('monitoringDetailMonitoringFilter');
    setTableColumnInactiveList([...defaultDisabledMonitoringList]);
  };

  const handleExport = () => {
    setDownloadLoading(() => true);

    let params: { [props: string]: any } = {};

    if (!!selectedDateRange[0].startDate) {
      params.from = format(new Date(selectedDateRange[0].startDate), 'yyyy-MM-dd');
    }

    if (!!selectedDateRange[0].endDate) {
      params.to = format(new Date(selectedDateRange[0].endDate), 'yyyy-MM-dd');
    }
    axiosInstance({
      method: 'get',
      url: `/monitoring/devices/${data['device_id']}/export`,
      params: params,
      responseType: 'blob',
    })
      .then((res) => {
        console.log('export =>', res);

        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        const fileObjectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileObjectUrl;
        link.style.display = 'none';

        link.download = `teladin-monitoring_${data['model_name']}(${data['serial_number']})_${params.from}_${params.to}`;

        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(fileObjectUrl);
      })
      .catch((error) => {
        console.log('export error => ', error);
      })
      .finally(() => {
        setDownloadLoading(() => false);
      });
  };

  useEffect(() => {
    if (!selectedData || !Object.keys(selectedData).length) return;
    setModalToggle(() => true);
  }, [selectedData]);

  useEffect(() => {
    if (!!modalToggle) return;
    setSelectedData({});
  }, [modalToggle]);

  const handleSearch = (newPage?: boolean) => {
    if (!data || !data['device_id'] || !!isFetching) return;
    setIsFetching(() => true);
    let params: { [props: string]: any } = {
      limit: pageSize,
      page: pageNum,
    };

    if (newPage) {
      params.page = 1;
      setPageNum(1);
    }

    if (!!selectedDateRange[0].startDate) {
      params.from = format(new Date(selectedDateRange[0].startDate), 'yyyy-MM-dd');
    }

    if (!!selectedDateRange[0].endDate) {
      params.to = format(new Date(selectedDateRange[0].endDate), 'yyyy-MM-dd');
    }

    if (!!selectedSort) {
      const [first, second] = selectedSort.split('-sort-');
      if (!!first && !!second) {
        params.orderBy = first;
        params.order = second;
      }
    }

    axiosInstance({
      method: 'get',
      url: `/monitoring/devices/${data['device_id']}`,
      params: params,
    })
      .then((res) => {
        console.log(`/monitoring/devices/${data['device_id']} =>`, res);
        setDataList([...res.data.items]);
        setPageNum(res.data.page);
        setAllListCount(res.data.totalCount);
      })
      .catch((error) => {
        console.log(`/monitoring/devices/${data['device_id']} error =>`, error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });
  };

  useEffect(() => {
    handleSearch();
  }, [data, refresh, pageNum, pageSize, selectedSort]);

  useEffect(() => {
    handleSearch(true);
  }, [selectedDateRange]);

  useEffect(() => {
    if (!tableColumnInactiveListLoaded) return;

    localStorage.setItem('monitoringDetailMonitoringFilter', JSON.stringify(tableColumnInactiveList));
  }, [tableColumnInactiveList, tableColumnInactiveListLoaded]);

  useEffect(() => {
    const til = localStorage.getItem('monitoringDetailMonitoringFilter');
    if (!!til) {
      setTableColumnInactiveList(JSON.parse(til));
    } else {
      setTableColumnInactiveList([...defaultDisabledMonitoringList]);
    }
    setTableColumnInactiveListLoaded(() => true);
  }, []);

  return (
    <MonitoringDetailMonitoringWrapper>
      <SearchWrapper>
        <div>
          <Button title='엑셀 양식 다운로드' onClick={handleExport} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <FileDownloadIcon />
          </Button>
          <Button title='필터 설정 적용' onClick={handleColumnFilter} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <SettingsOutlinedIcon />
          </Button>
          <DatePicker onChange={handleDate} size='medium' color='secondary' />
          <Button title='새로고침' onClick={() => setRefresh((prev) => !prev)} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <RefreshIcon />
          </Button>
        </div>
      </SearchWrapper>
      <TableWrapper>
        <Table
          tableId='monitoring_detail_monitoring_table'
          maxHeight={`${upDownStatus - 264}px`}
          fullWidth
          height={``}
          size='small'
          dataId='current_time'
          list={dataList}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          header={[
            ...getMonitoringTableHeader(
              [
                'tags',
                'serial_number',
                'model_name',
                'connection_status',
                'description1',
                'description2',
                ...tableColumnInactiveList,
                ...(loginUser.organization_id !== 1 ? ['created_at'] : []),
              ],
              true,
              loginUser.permission === 'admin'
            ),
            {
              title: '상세 보기',
              value: 'detail',
              render: (data: any) => {
                return (
                  <Button onClick={() => handleModal(data)} variant='outlined' color='secondary' size='small'>
                    상세 보기
                  </Button>
                );
              },
              width: '100px',
            },
          ]}
          isFetching={isFetching}
        />
        <div>
          {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
        </div>
      </TableWrapper>
      <Modal open={modalToggle} onClose={() => setModalToggle(() => false)}>
        <>
          <ModalContent>
            <MonitoringDetailInfo data={selectedData} />
            <ModalBottomWrapper>
              <Button onClick={() => setModalToggle(() => false)} variant='outlined' color='secondary' size='medium'>
                닫기
              </Button>
            </ModalBottomWrapper>
          </ModalContent>
        </>
      </Modal>
      <Modal open={tableColumnInactiveListToggle} onClose={() => setTableColumnInactiveListToggle(() => false)}>
        <>
          <ModalContentWrapper>
            <TableColumnFilter
              onReset={handleFilterReset}
              inactiveList={tableColumnInactiveList}
              setInactiveList={setTableColumnInactiveList}
              list={[
                ...getMonitoringTableHeader(
                  [
                    ...['current_time', 'description1', 'description2', 'tags', 'serial_number', 'model_name', 'connection_status'],
                    ...(loginUser.organization_id !== 1 ? ['created_at'] : []),
                  ],
                  false,
                  loginUser.permission === 'admin'
                ).map((d) => {
                  return {
                    title: d.title,
                    value: d.value,
                  };
                }),
              ]}
            />
          </ModalContentWrapper>
        </>
      </Modal>
      <Modal open={downloadLoading}>
        <>
          <Loading />
        </>
      </Modal>
    </MonitoringDetailMonitoringWrapper>
  );
};

export default MonitoringDetailMonitoring;
