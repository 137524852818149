import { Button, Switch, TextField } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { caption1, caption2, grey600, subtitle2 } from '../../global/css';

const TableColumnFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  row-gap: 8px;
  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      & > span {
        ${caption1};
      }
      & > p {
        ${caption2};
        color: ${grey600};
      }
    }
  }
`;

const ColumnListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: scroll;
  row-gap: 8px;
  box-sizing: border-box;
  & > div {
    display: flex;
    align-items: center;
    & > span {
      ${subtitle2};
      cursor: pointer;
    }
  }
`;

type TableColumnFilterProps = {
  list: { title: string; value: string }[];
  inactiveList: string[];
  setInactiveList: Function;
  onReset: Function;
};

const TableColumnFilter = (props: TableColumnFilterProps) => {
  const { onReset, list = [], inactiveList = [], setInactiveList } = props;

  const [search, setSearch] = useState('');

  const handleChange = (value: string) => {
    if (value === 'all') {
      if (!inactiveList.length) {
        setInactiveList(list.map((l) => l.value));
      } else {
        setInactiveList([]);
      }
    } else {
      if (inactiveList.includes(value)) {
        setInactiveList((prev: string[]) => [...prev.filter((p) => p !== value)]);
      } else {
        setInactiveList((prev: string[]) => [...prev, value]);
      }
    }
  };

  return (
    <TableColumnFilterWrapper>
      <div>
        <div>
          <span>속성 열</span>
          <p>표시할 속성 열 선택</p>
        </div>
        <Button onClick={() => onReset()} variant='outlined' color='secondary' size='small'>
          초기화
        </Button>
      </div>
      <TextField placeholder='속성 열 검색' size='small' onChange={(e) => setSearch(e.target.value)} />
      <ColumnListWrapper>
        <div>
          <Switch onClick={() => handleChange('all')} size='small' checked={!inactiveList.length} />
          <span onClick={() => handleChange('all')}>전체</span>
        </div>
        {!!list &&
          !!list.length &&
          list
            .filter((l) => l.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || l.value.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
            .map((l, i) => {
              return (
                <div key={i}>
                  <Switch onClick={() => handleChange(l.value)} size='small' checked={!inactiveList.includes(l.value)} />
                  <span onClick={() => handleChange(l.value)}>{l.title}</span>
                </div>
              );
            })}
      </ColumnListWrapper>
    </TableColumnFilterWrapper>
  );
};

export default TableColumnFilter;
