import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { caption1, caption2, caption3, grey600, grey900, subtitle1 } from '../../global/css';
import { LoginUserContext } from '../../hooks/LoginUserHook';
import TabBox from '../TabBox';
import Tag from '../Tag';
import MonitoringDetailControl from './MonitoringDetailControl';
import MonitoringDetailHistory from './MonitoringDetailHistory';
import MonitoringDetailInfo from './MonitoringDetailInfo';
import MonitoringDetailMonitoring from './MonitoringDetailMonitoring';
import MonitoringDetailSignal from './MonitoringDetailSignal';
import MonitoringEventHistory from './MonitoringEventHistory';
import EditIcon from '@mui/icons-material/Edit';
import { Button, TextField } from '@mui/material';
import CheckBox from '../CheckBox';
import Popup from '../Popup';
import { axiosInstance } from '../../global/axiosSetting';

const MonitoringDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div:first-of-type {
    display: flex;
    column-gap: 16px;
    align-items: center;
    & > p {
      width: fit-content;
      min-width: fit-content;
      color: ${grey600};
      ${caption1};
      & > span {
        ${subtitle1};
        color: ${grey900};
      }
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 12px 0;
`;

const TagListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 4px 0;
  box-sizing: border-box;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-sizing: border-box;
    padding: 4px 0;
    width: 100%;
    & > p {
      ${caption2};
      display: flex;
      & > span {
        ${caption3};
        display: flex;
        font-feature-settings: 'tnum';
        color: ${grey600};
        align-items: center;
      }
    }
  }
`;

type MonitoringDetailProps = {
  data?: { [props: string]: any };
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  upDownStatus?: number;
  setRefresh: Function;
};

const MonitoringDetail = (props: MonitoringDetailProps) => {
  const { data, selectedIndex, setSelectedIndex, upDownStatus, setRefresh } = props;
  const { loginUser } = useContext(LoginUserContext);
  const [editToggle, setEditToggle] = useState(false);
  const [editDescription1, setEditDescription1] = useState<string>('');
  const [editDescription1Toggle, setEditDescription1Toggle] = useState<boolean>(false);
  const [editDescription2, setEditDescription2] = useState<string>('');
  const [editDescription2Toggle, setEditDescription2Toggle] = useState<boolean>(false);

  const handleTabIndex = (index: number) => {
    setSelectedIndex(index);
  };

  const handleEditDevice = (id: number) => {
    console.log(`handleEditDevice id =>`, id);
    if (!id) return;
    let data: { [props: string]: any } = {};

    if (!!editDescription1Toggle) {
      data.description1 = '';
    } else if (!!editDescription1) {
      data.description1 = editDescription1;
    }

    if (!!editDescription2Toggle) {
      data.description2 = '';
    } else if (!!editDescription2) {
      data.description2 = editDescription2;
    }

    axiosInstance({
      method: 'patch',
      url: `/devices/${id}`,
      data: data,
    })
      .then((res) => {
        console.log(`patch /devices/ =>`, res);
        setEditToggle(() => false);
        setEditDescription1('');
        setEditDescription2('');
        setEditDescription1Toggle(false);
        setEditDescription2Toggle(false);
        setRefresh(true);
      })
      .catch((error) => {
        console.log(`patch /devices/ error =>`, error);
      });
  };

  useEffect(() => {
    if (!editToggle) return;
    setEditDescription1(data?.description1 || '');
    setEditDescription2(data?.description2 || '');
  }, [editToggle, data]);

  if (!data) return null;

  return (
    <MonitoringDetailWrapper>
      <div>
        <p>
          모델명 : <span>{data['model_name']}</span>
        </p>
        <p>
          일련번호 :<span>{data['serial_number']}</span>
        </p>
        <TagListWrapper>
          {!!data.tags &&
            data.tags.map((t: { name: string; id: number }) => {
              return <Tag key={t.id} text={t.name} />;
            })}
        </TagListWrapper>
      </div>
      <DescriptionWrapper>
        <div>
          <p>
            <span>설명1 :</span>
            &nbsp;{data?.description1 || '-'}
          </p>
          <p>
            <span>설명2 :</span>
            &nbsp;{data?.description2 || '-'}
          </p>
        </div>
        <Button onClick={() => setEditToggle(true)} sx={{ columnGap: '8px', minWidth: 'fit-content' }} size='small' variant='outlined' color='secondary'>
          <EditIcon sx={{ width: '16px' }} /> 설명 수정
        </Button>
      </DescriptionWrapper>
      <ContentWrapper>
        <TabBox
          onChange={handleTabIndex}
          contentPadding='8px 0'
          defaultValue={selectedIndex || 0}
          key={data['device_id']}
          size='small'
          dataList={tabList.filter((t) => (loginUser.permission === 'admin' ? true : !t.isAdmin))}
        >
          <div tabIndex={selectedIndex}>
            {tabList
              .filter((tab) => tab.index === selectedIndex)[0]
              ?.content({
                data: data,
                upDownStatus: upDownStatus,
                setRefresh: setRefresh,
              })}
          </div>
        </TabBox>
      </ContentWrapper>

      <Popup toggle={editToggle} setToggle={setEditToggle}>
        <PopupContentWrapper>
          <EditWrapper>
            <TextField
              fullWidth
              size='small'
              label='장치 설명 1'
              disabled={editDescription1Toggle}
              name='description1'
              value={editDescription1 || ''}
              onChange={(e) => setEditDescription1(e.target.value)}
            />

            <CheckBox
              onClick={() => setEditDescription1Toggle((prev) => !prev)}
              selected={editDescription1Toggle}
              checkBoxStyle={{ paddingLeft: '8px' }}
              size='small'
              label='제거'
            />
          </EditWrapper>
          <EditWrapper>
            <TextField
              fullWidth
              size='small'
              label='장치 설명 2'
              disabled={editDescription2Toggle}
              name='description2'
              value={editDescription2 || ''}
              onChange={(e) => setEditDescription2(e.target.value)}
            />

            <CheckBox
              onClick={() => setEditDescription2Toggle((prev) => !prev)}
              selected={editDescription2Toggle}
              checkBoxStyle={{ paddingLeft: '8px' }}
              size='small'
              label='제거'
            />
          </EditWrapper>

          <PopupButtonWrapper>
            <Button variant='contained' size='medium' color='secondary' onClick={() => setEditToggle(() => false)}>
              취소
            </Button>

            <Button onClick={() => handleEditDevice(data.device_id)} color='warning' variant='contained' size='medium'>
              수정
            </Button>
          </PopupButtonWrapper>
        </PopupContentWrapper>
      </Popup>
    </MonitoringDetailWrapper>
  );
};

export default MonitoringDetail;

const tabList = [
  {
    index: 0,
    title: '세부정보',
    content: (data: any) => <MonitoringDetailInfo disabledList={['model_name', 'serial_number']} {...data} />,
  },
  {
    index: 1,
    title: '신호',
    content: (data: any) => <MonitoringDetailSignal {...data} />,
  },
  {
    index: 2,
    title: '모니터링',
    content: (data: any) => <MonitoringDetailMonitoring {...data} />,
  },
  {
    index: 3,
    title: '이벤트 히스토리',
    content: (data: any) => <MonitoringEventHistory {...data} />,
    isAdmin: true,
  },
  {
    index: 4,
    title: '제어',
    content: (data: any) => <MonitoringDetailControl {...data} />,
    isAdmin: true,
  },
  {
    index: 5,
    title: '제어 히스토리',
    content: (data: any) => <MonitoringDetailHistory {...data} />,
  },
];
const EditWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const PopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 300px;
`;
const PopupButtonWrapper = styled.div`
  padding-top: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
`;
