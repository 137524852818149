import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import { add, format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import styled from 'styled-components';
import DatePicker from '../components/DatePicker';
import Loading from '../components/Loading';
import Popup from '../components/Popup';
import Slideup from '../components/Slideup';
import ManagementGroupSelect from '../components/managements/ManagementGroupSelect';
import MonitoringDetail from '../components/monitorings/MonitoringDetail';
import Table from '../components/table/Table';
import TableColumnFilter from '../components/table/TableColumnFilter';
import TablePagination from '../components/table/TablePagination';
import { axiosInstance } from '../global/axiosSetting';
import { grey200, grey700, muiBorderColor } from '../global/css';
import { defaultDisabledMonitoringList, getMonitoringTableHeader } from '../global/types';
import { getChidren, getGroupPathName } from '../global/utils';
import { LoginUserContext } from '../hooks/LoginUserHook';

const MonitoringPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid ${grey200};
  padding: 16px;
  row-gap: 8px;
`;

const FilterWrapper = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
  width: 100%;
  & > div {
    display: flex;
    column-gap: 8px;
  }
`;

const SearchWrapper = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    column-gap: 8px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalContentWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${grey200};
  border-radius: 12px;
  box-sizing: border-box;
  background-color: white;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 4px 8px;
`;

const PopupButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  box-sizing: border-box;
  & > div {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
  }
`;

const MonitoringPage = () => {
  const { loginUser } = useContext(LoginUserContext);
  const [monitoringList, setMonitoringList] = useState<{ [props: string]: any }[]>([]);
  const [modelNameList, setModelNameList] = useState<string[]>([]);
  const [versionList, setVersionList] = useState<string[]>([]);
  const [tagList, setTagList] = useState<string[]>([]);

  const [selectedModelName, setSelectedModelName] = useState<string>('');
  const [selectedVersion, setSelectedVersion] = useState<string>('');
  const [selectedTag, setSelectedTag] = useState<string>('');
  const [connectionStatus, setConnectionStatus] = useState<string>('');
  const [signalStrength, setSignalStrength] = useState<string>('');
  const [selectedDateRange, setSelectedDateRange] = useState<[Range]>([
    {
      startDate: add(new Date(), { days: -7 }),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);

  const [search, setSearch] = useState<string>('');
  const [selectedSort, setSelectedSort] = useState('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);

  const [selectedTableItem, setSelectedTableItem] = useState<string>('');

  const [slideToggle, setSlideToggle] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [tableColumnInactiveListToggle, setTableColumnInactiveListToggle] = useState<boolean>(false);
  const [tableColumnInactiveList, setTableColumnInactiveList] = useState<string[]>([]);
  const [tableColumnInactiveListLoaded, setTableColumnInactiveListLoaded] = useState<boolean>(false);

  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const [upDownStatus, setUpDownStatus] = useState<number>(0);

  const [isTagFetching, setIsTagFetching] = useState<boolean>(false);

  const [groupSelectPopupToggle, setGroupSelectPopupToggle] = useState<boolean>(false);
  const [selectedPopupGroup, setSelectedPopupGroup] = useState<{ [props: string]: any }>({});
  const [tempPopupGroup, setTempPopupGroup] = useState<{ [props: string]: any }>({});
  const [allGroupList, setAllGroupList] = useState<{ [props: string]: any }[]>([]);
  const [errorEventStatus, setErrorEventStatus] = useState<string>('');

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleUpDownStatus = (status: number) => {
    setUpDownStatus(() => status);
  };

  const handleSelectGroupPopupOpen = () => {
    if (!!selectedPopupGroup && !!selectedPopupGroup.id) {
      setTempPopupGroup({ ...selectedPopupGroup });
    }
    setGroupSelectPopupToggle(() => true);
  };

  const handleCancelPopupSelectGroup = () => {
    setTempPopupGroup({});
    setGroupSelectPopupToggle(() => false);
  };

  const handleConfirmPopupSelectGroup = () => {
    setSelectedPopupGroup(tempPopupGroup);
    handleCancelPopupSelectGroup();
  };

  const handleResetPopupSelectGroup = () => {
    setSelectedPopupGroup({});
    handleCancelPopupSelectGroup();
  };

  const handleExport = () => {
    setDownloadLoading(() => true);

    let params: { [props: string]: any } = {};

    if (!!selectedDateRange[0].startDate) {
      params.from = format(new Date(selectedDateRange[0].startDate), 'yyyy-MM-dd');
    }

    if (!!selectedDateRange[0].endDate) {
      params.to = format(new Date(selectedDateRange[0].endDate), 'yyyy-MM-dd');
    }

    if (!!selectedModelName) {
      params.model_name = selectedModelName;
    }
    if (!!selectedVersion) {
      params.sw_version = selectedVersion;
    }

    if (!!selectedTag) {
      params.tag = selectedTag;
    }

    if (!!connectionStatus) {
      params.connection_status = connectionStatus === 'online' ? true : connectionStatus === 'offline' ? false : null;
    }

    if (!!signalStrength) {
      params.signal_strength = signalStrength;
    }

    if (!!errorEventStatus) {
      params.error_event = errorEventStatus === 'true' ? true : errorEventStatus === 'false' ? false : null;
    }

    if (!!search) {
      params.search = search;
    }

    if (!!selectedPopupGroup && !!selectedPopupGroup.id) {
      params.organization_id = selectedPopupGroup.id;
    }

    if (!!selectedSort) {
      const [first, second] = selectedSort.split('-sort-');
      if (!!first && !!second) {
        params.orderBy = first;
        params.order = second;
      }
    }

    axiosInstance({
      method: 'get',
      url: '/monitoring/export',
      params: params,
      responseType: 'blob',
    })
      .then((res) => {
        console.log('export =>', res);

        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        const fileObjectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileObjectUrl;
        link.style.display = 'none';

        link.download = `teladin-monitoring_${params.from}_${params.to}`;

        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(fileObjectUrl);
      })
      .catch((error) => {
        console.log('export error => ', error);
      })
      .finally(() => {
        setDownloadLoading(() => false);
      });
  };

  const handleRowClick = (item: any) => {
    setSelectedTableItem(() => item.device_id);
    setSlideToggle(() => true);
  };

  const getTagList = () => {
    setIsTagFetching(() => true);
    setTagList([]);
    axiosInstance({
      method: 'get',
      url: '/monitoring/tags',
    })
      .then((res) => {
        console.log('/monitoring/tags =>', res);
        setTagList([...res.data]);
      })
      .catch((error) => {
        console.log('/monitoring/tags error =>', error);
      })
      .finally(() => {
        setIsTagFetching(() => false);
      });
  };

  const handleColumnFilter = () => {
    setTableColumnInactiveListToggle(() => true);
  };

  const handleSearch = (slideToggle?: boolean, newPage?: boolean) => {
    if (!slideToggle) {
      setSlideToggle(() => false);
    }
    if (isFetching) return;
    setIsFetching(() => true);

    let params: { [props: string]: any } = {
      page: pageNum,
      limit: pageSize,
    };

    if (newPage) {
      params.page = 1;
      setPageNum(1);
    }

    if (!!selectedDateRange[0].startDate) {
      params.from = format(new Date(selectedDateRange[0].startDate), 'yyyy-MM-dd');
    }

    if (!!selectedDateRange[0].endDate) {
      params.to = format(new Date(selectedDateRange[0].endDate), 'yyyy-MM-dd');
    }

    if (!!selectedModelName) {
      params.model_name = selectedModelName;
    }
    if (!!selectedVersion) {
      params.sw_version = selectedVersion;
    }

    if (!!selectedTag) {
      params.tag = selectedTag;
    }

    if (!!connectionStatus) {
      params.connection_status = connectionStatus === 'online' ? true : connectionStatus === 'offline' ? false : null;
    }

    if (!!signalStrength) {
      params.signal_strength = signalStrength;
    }

    if (!!errorEventStatus) {
      params.error_event = errorEventStatus === 'true' ? true : errorEventStatus === 'false' ? false : null;
    }

    if (!!search) {
      params.search = search;
    }

    if (!!selectedPopupGroup && !!selectedPopupGroup.id) {
      params.organization_id = selectedPopupGroup.id;
    }

    if (!!selectedSort) {
      const [first, second] = selectedSort.split('-sort-');
      if (!!first && !!second) {
        params.orderBy = first;
        params.order = second;
      }
    }

    const type = sessionStorage.getItem('monitoringType');

    if (!!type) {
      if (type === 'online' || type === 'offline') {
        params.connection_status = type === 'online' ? true : type === 'offline' ? false : null;
        setConnectionStatus(type);
      } else if (type === 'strong' || type === 'medium' || type === 'weak') {
        params.signal_strength = type;
        setSignalStrength(type);
      }
    }

    axiosInstance({
      method: 'get',
      url: '/monitoring',
      params: params,
    })
      .then((res) => {
        console.log('/monitoring =>', res);
        setMonitoringList([...res.data.items]);
        setAllListCount(res.data.totalCount);
      })
      .catch((error) => {
        console.log('/monitoring error =>', error);
      })
      .finally(() => {
        setIsFetching(() => false);
        setRefresh(false);
        sessionStorage.removeItem('monitoringType');
      });
  };

  const handleDate = (item: Range) => {
    setSelectedDateRange(() => [item]);
  };

  const checkEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleFilterReset = () => {
    localStorage.removeItem('monitoringFilter');
    setTableColumnInactiveList(defaultDisabledMonitoringList);
  };

  useEffect(() => {
    handleSearch();
  }, [errorEventStatus, selectedPopupGroup, signalStrength, connectionStatus, selectedModelName, selectedVersion, selectedSort, selectedTag, pageNum, pageSize]);

  useEffect(() => {
    handleSearch(false, true);
  }, [selectedDateRange]);

  useEffect(() => {
    if (!refresh) return;
    handleSearch(true);
  }, [refresh]);

  useEffect(() => {
    axiosInstance({
      method: 'get',
      url: '/monitoring/model-names',
    })
      .then((res) => {
        console.log('/monitoring/model-names =>', res);
        setModelNameList([...res.data]);
      })
      .catch((error) => {
        console.log('/monitoring/model-names error =>', error);
      });

    axiosInstance({
      method: 'get',
      url: '/monitoring/ati-versions',
    })
      .then((res) => {
        console.log('/monitoring/ati-versions =>', res);
        setVersionList([...res.data]);
      })
      .catch((error) => {
        console.log('/monitoring/ati-versions error =>', error);
      });

    axiosInstance({
      method: 'get',
      url: '/monitoring/tags',
    })
      .then((res) => {
        console.log('/monitoring/tags =>', res);
        setTagList([...res.data]);
      })
      .catch((error) => {
        console.log('/monitoring/tags error =>', error);
      });

    axiosInstance({
      url: '/organizations',
      method: 'GET',
    })
      .then((res) => {
        console.log('/organizations =>', res);
        const arr: { [props: string]: any }[] = [];

        getChidren(res.data, arr);
        setAllGroupList(arr);
      })
      .catch((error) => {
        console.log('/organizations error =>', error);
      });

    //테이블 높이 계산
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setInnerHeight(window.innerHeight);
      });
    };
  }, []);

  useEffect(() => {
    if (!slideToggle) {
      setSelectedTableItem('');
      setSelectedIndex(0);
    }
  }, [slideToggle]);

  useEffect(() => {
    if (!tableColumnInactiveListLoaded) return;

    localStorage.setItem('monitoringFilter', JSON.stringify(tableColumnInactiveList));
  }, [tableColumnInactiveList, tableColumnInactiveListLoaded]);

  useEffect(() => {
    const til = localStorage.getItem('monitoringFilter');
    if (!!til) {
      setTableColumnInactiveList(JSON.parse(til));
    } else {
      setTableColumnInactiveList(defaultDisabledMonitoringList);
    }
    setTableColumnInactiveListLoaded(() => true);
  }, []);

  return (
    <MonitoringPageWrapper>
      <FilterWrapper>
        <div>
          <Autocomplete
            onChange={(_, v: any) => setSelectedModelName(v ?? '')}
            sx={{ minWidth: '140px' }}
            size='small'
            options={modelNameList}
            renderInput={(params: any) => <TextField {...params} label='모델명' />}
          />
          <Autocomplete
            color='secondary'
            onChange={(_, v: any) => setSelectedVersion(v ?? '')}
            sx={{ minWidth: '220px' }}
            size='small'
            options={versionList}
            renderInput={(params: any) => <TextField {...params} label='버전' />}
          />
          <Autocomplete
            color='secondary'
            onChange={(_, v: any) => setSelectedTag(v ?? '')}
            sx={{ minWidth: '220px' }}
            size='small'
            options={tagList}
            renderInput={(params: any) => <TextField {...params} label='태그' />}
            loading={isTagFetching}
            onFocus={() => getTagList()}
          />
          <FormControl sx={{ minWidth: '150px' }} size='small'>
            <InputLabel id='connection_status-select'>연결 상태</InputLabel>
            <Select labelId='connection_status-select' value={connectionStatus} label='연결 상태' onChange={(e) => setConnectionStatus(e.target.value)}>
              <MenuItem value={''}>전체</MenuItem>
              <MenuItem value={'online'}>온라인</MenuItem>
              <MenuItem value={'offline'}>오프라인</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: '150px' }} size='small'>
            <InputLabel id='signal_strength-select'>신호 강도</InputLabel>
            <Select labelId='signal_strength-select' value={signalStrength} label='신호 강도' onChange={(e) => setSignalStrength(e.target.value)}>
              <MenuItem value={''}>전체</MenuItem>
              <MenuItem value={'strong'}>강전계</MenuItem>
              <MenuItem value={'medium'}>중전계</MenuItem>
              <MenuItem value={'weak'}>약전계</MenuItem>
            </Select>
          </FormControl>

          {!!loginUser && !!loginUser.permission && loginUser.permission === 'admin' && (
            <FormControlLabel
              sx={{ color: grey700, margin: '0 4px 0 0' }}
              control={
                <Checkbox
                  size='small'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setErrorEventStatus(`${e.target.checked}`)}
                  value={true}
                  checked={errorEventStatus === 'true'}
                />
              }
              label='에러 이벤트'
            />
          )}

          <Button onClick={handleSelectGroupPopupOpen} sx={{ borderColor: muiBorderColor }} variant='outlined' color='secondary'>
            {!!selectedPopupGroup && (!!selectedPopupGroup.id || selectedPopupGroup.id === 0) && !!getGroupPathName(selectedPopupGroup, allGroupList)
              ? getGroupPathName(selectedPopupGroup, allGroupList)
              : '조직 선택'}
          </Button>
        </div>
      </FilterWrapper>
      <SearchWrapper>
        <TextField
          size='small'
          sx={{ minWidth: '555px' }}
          placeholder='검색 (모델명, 일련번호, 설명, 전화번호, IMEI, ICCID)'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ cursor: 'pointer' }} onClick={() => handleSearch()} />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={checkEnter}
        />
        <div>
          <Button title='엑셀 양식 다운로드' onClick={handleExport} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <FileDownloadIcon />
          </Button>
          <Button title='필터 설정 적용' onClick={handleColumnFilter} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <SettingsOutlinedIcon />
          </Button>

          <DatePicker onChange={handleDate} size='medium' color='secondary' />

          <Button title='새로고침' onClick={() => handleSearch()} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <RefreshIcon />
          </Button>
        </div>
      </SearchWrapper>
      <TableWrapper>
        <Table
          tableId='monitoring_table'
          onRowClick={handleRowClick}
          fullWidth
          isFetching={isFetching}
          maxHeight={`${innerHeight / (!!slideToggle ? 2 : 1) - 244 - (!!slideToggle ? 0 : 20)}px`}
          size='small'
          dataId='device_id'
          list={monitoringList}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          header={getMonitoringTableHeader([...tableColumnInactiveList, loginUser.organization_id !== 1 ? 'created_at' : ''])}
          selectedItemList={[selectedTableItem]}
        />
        <div>
          {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
        </div>
      </TableWrapper>

      <Slideup onChangeStatus={handleUpDownStatus} toggle={slideToggle} setToggle={setSlideToggle} hasBackWrapper={false}>
        <MonitoringDetail
          upDownStatus={upDownStatus}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          data={monitoringList.find((m) => m['device_id'] === selectedTableItem)}
          setRefresh={setRefresh}
        />
      </Slideup>
      <Modal open={tableColumnInactiveListToggle} onClose={() => setTableColumnInactiveListToggle(() => false)}>
        <>
          <ModalContentWrapper>
            <TableColumnFilter
              onReset={handleFilterReset}
              inactiveList={tableColumnInactiveList}
              setInactiveList={setTableColumnInactiveList}
              list={[
                ...getMonitoringTableHeader([...['model_name', 'serial_number', 'current_time'], ...(loginUser.organization_id !== 1 ? ['created_at'] : [])]).map((d) => {
                  return {
                    title: d.title,
                    value: d.value,
                  };
                }),
              ]}
            />
          </ModalContentWrapper>
        </>
      </Modal>
      <Modal open={downloadLoading}>
        <>
          <Loading />
        </>
      </Modal>
      <Popup toggle={groupSelectPopupToggle} setToggle={setGroupSelectPopupToggle}>
        <ManagementGroupSelect selected={tempPopupGroup} setSelected={setTempPopupGroup} />
        <PopupButtonWrapper>
          <div>
            {!!selectedPopupGroup && !!selectedPopupGroup.id && (
              <Button variant='contained' size='medium' color='info' onClick={handleResetPopupSelectGroup}>
                선택해제
              </Button>
            )}
          </div>
          <div>
            <Button variant='contained' size='medium' color='secondary' onClick={handleCancelPopupSelectGroup}>
              취소
            </Button>
            <Button variant='contained' size='medium' onClick={handleConfirmPopupSelectGroup}>
              확인
            </Button>
          </div>
        </PopupButtonWrapper>
      </Popup>
    </MonitoringPageWrapper>
  );
};

export default MonitoringPage;
