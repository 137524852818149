import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { green600, grey300, grey400, headline2, subtitle1 } from '../global/css';

const TabWrapper = styled.div<{ $maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${(props) => (!!props.$maxWidth ? props.$maxWidth : '100%')};
`;
const TabBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${grey300};
  position: relative;
`;
const TabListWrapper = styled.div<{ $headerBackgroundColor?: string }>`
  display: flex;
  column-gap: 40px;
  padding-bottom: 12px;
  ${(props) =>
    !!props.$headerBackgroundColor &&
    css`
      background-color: ${props.$headerBackgroundColor};
    `}
`;
const TabItem = styled.span<{ $size?: string; $selected?: boolean }>`
  ${(props) => (!!props.$size && props.$size === 'small' ? subtitle1 : headline2)};
  color: ${(props) => (!!props.$selected ? green600 : grey400)};
  cursor: pointer;
`;

const IndicatorWrapper = styled.div`
  display: flex;

  & > div {
    width: 0;
    position: absolute;
    height: 2px;
    background-color: ${green600};
    transition: all 0.3s;
    z-index: 1;
  }
`;

const TabContentWrapper = styled.div<{ $contentPadding?: string }>`
  display: flex;
  position: relative;
  width: 100%;
  & > div {
    width: 100%;
    position: absolute;
    transition: all 0.5s;
    opacity: 0;
    left: 0;
    overflow-y: hidden;
    box-sizing: border-box;
    ${(props) =>
      !!props.$contentPadding &&
      css`
        padding: ${props.$contentPadding};
      `}
  }
`;

type TabBoxType = {
  contentPadding?: string;
  dataList?: { index: number; title: string }[];
  children?: React.ReactNode;
  onChange?: (index: number) => void;
  headerBackgroundColor?: string;
  defaultValue?: number;
  size?: string;
  maxWidth?: string;
  dataId?: string;
};

const TabBox = (props: TabBoxType) => {
  const { dataId = '', contentPadding, dataList = [], children, onChange = () => {}, headerBackgroundColor, defaultValue, size, maxWidth } = props;
  const [selected, setSelected] = useState<string | number>('');

  const handleSelect = (index: any) => {
    setSelected(() => index);
    if (!!onChange) onChange(index);
  };

  useEffect(() => {
    if (!defaultValue && defaultValue !== 0) return;

    setSelected(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if ((!selected || selected === '') && selected !== 0) return;

    const selectedTab = document.getElementById(`tabItem${dataId}-${selected}`);
    if (!selectedTab) return;

    const w = selectedTab.offsetWidth;
    const l = selectedTab.offsetLeft;

    const indi = document.getElementById(`tabIndicator${dataId}`);
    if (!!indi) {
      indi.style.width = `${w}px`;
      indi.style.left = `${l}px`;
    }

    //

    const tabC = document.getElementById(`tabContent${dataId}`);
    if (!!tabC) {
      const cList = tabC.children;

      let h = 0;
      if (!!cList) {
        for (const cItem of cList) {
          const c = cItem as HTMLElement;
          if (Number(c.getAttribute(`tabIndex`)) === selected) {
            c.style.transition = 'all 0.5s';
            c.style.opacity = '1';
            c.style.zIndex = '2';
            c.style.height = 'fit-content';
            h = c.offsetHeight + 8;
          } else {
            c.style.transition = '0s';
            c.style.opacity = '0';
            c.style.zIndex = '1';
            c.style.height = '0';
          }
        }
      }
      if (!!tabC) {
        tabC.style.height = `${h}px`;
        tabC.style.maxHeight = `${h}px`;
      }
    }

    //
  }, [selected]);

  return (
    <TabWrapper $maxWidth={maxWidth}>
      <TabBoxWrapper>
        <TabListWrapper $headerBackgroundColor={headerBackgroundColor}>
          {dataList
            .sort((a, b) => (a.index < b.index ? -1 : 1))
            .map((l, i) => {
              return (
                <TabItem $size={size} id={`tabItem${dataId}-${l.index}`} onClick={() => handleSelect(l.index)} $selected={selected === l.index} key={i}>
                  {l.title}
                </TabItem>
              );
            })}
        </TabListWrapper>
        <IndicatorWrapper>
          <div id={`tabIndicator${dataId}`} />
        </IndicatorWrapper>
      </TabBoxWrapper>
      <TabContentWrapper $contentPadding={contentPadding} id={`tabContent${dataId}`}>
        {children}
      </TabContentWrapper>
    </TabWrapper>
  );
};

export default TabBox;
