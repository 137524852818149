import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import styled, { css } from 'styled-components';
import { body1, caption3, green600, grey600 } from '../../global/css';

const RecursiveGroupWrapper = styled.div<{ $hasSearch: boolean; $isSearched: boolean; $selected: boolean; $isClose: boolean }>`
  display: flex;
  flex-direction: column;
  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
    & > div:first-of-type {
      display: flex;
      align-items: center;

      & > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 32px;
      }
      & > span {
        ${body1};
        cursor: pointer;
        display: flex;
        width: fit-content;
        padding: 4px 8px;
        box-sizing: border-box;
        border-radius: 8px;
        &:hover {
          color: ${grey600};
        }

        ${(props) =>
          !!props.$selected &&
          css`
            background-color: ${green600} !important;
            color: white !important;
          `}
      }
      & > p {
        ${caption3};
        color: ${grey600};
        display: flex;
        align-items: center;
        padding-left: 20px;
      }

      ${(props) =>
        !!props.$hasSearch &&
        css`
          & > div {
            display: none;
          }
          ${!props.$isSearched &&
          css`
            display: none;
          `}
        `}
    }
  }
  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    box-sizing: border-box;
    ${(props) =>
      !!props.$isClose &&
      css`
        display: none;
      `}
    ${(props) =>
      !!props.$hasSearch &&
      css`
        padding-left: 0;
      `}
  }
`;

type RecursiveGroupProps = {
  data: { [props: string]: any };
  originalSelected?: { [props: string]: any };
  selected: { [props: string]: any };
  setSelected: Function;
  closeList: number[];
  setCloseList: Function;
  search: string;
  setSearch: Function;
  type?: string;
};

const RecursiveGroup = (props: RecursiveGroupProps) => {
  const { originalSelected, type, setSearch, search, data, selected, setSelected, closeList, setCloseList } = props;

  const handleClose = () => {
    if (closeList.includes(data.id)) {
      setCloseList((prev: number[]) => [...prev.filter((item: number) => item !== data.id)]);
    } else {
      setCloseList((prev: number[]) => [...prev, data.id]);
    }
  };

  const handleSelect = () => {
    setSelected(data);
    setSearch('');
  };

  if (!data || !data.id) return <></>;
  return (
    <RecursiveGroupWrapper
      $hasSearch={!!search}
      $isSearched={!!data && !!data.name && data.name.includes(search)}
      $selected={selected.id === data.id}
      $isClose={closeList.includes(data.id)}
    >
      <div>
        {type !== 'edit' ||
        !originalSelected ||
        !originalSelected.id ||
        !(data.path.split('/').length > originalSelected.path.split('/').length && data.path.split('/').includes(`${originalSelected.id}`)) ? (
          <div>
            {!!data.children && !!data.children.length ? (
              <div onClick={handleClose}>
                {closeList.includes(data.id) ? <ArrowRightIcon sx={{ width: '32px', height: '32px' }} /> : <ArrowDropDownIcon sx={{ width: '32px', height: '32px' }} />}
              </div>
            ) : (
              <div />
            )}

            <span onClick={handleSelect}>{data.name}</span>

            {!!type && type === 'page' && <p>{data.description}</p>}
          </div>
        ) : (
          <div />
        )}
      </div>
      <div>
        {!!data.children &&
          !!data.children.length &&
          data.children.map((item: any, index: number) => (
            <RecursiveGroup
              originalSelected={originalSelected}
              setSearch={setSearch}
              search={search}
              selected={selected}
              setSelected={setSelected}
              closeList={closeList}
              setCloseList={setCloseList}
              key={index}
              data={item}
              type={type}
            />
          ))}
      </div>
    </RecursiveGroupWrapper>
  );
};

export default RecursiveGroup;
