import CircleIcon from "@mui/icons-material/Circle";
import { Button } from "@mui/material";
import { format } from "date-fns";
import Tag from "../components/Tag";
import { green600, red600 } from "./css";

export type DeviceStateOrderBy =
  | "current_time"
  | "model_name"
  | "serial_number"
  | "memory_free"
  | "sw_version"
  | "radio_signal_strength"
  | "link_quality"
  | "sinr"
  | "ip_address"
  | "apn"
  | "cell_id"
  // | 'smnc'
  // | 'smcc'
  | "mac"
  | "wan_ip_address"
  | "lan_ip_address"
  | "wan_up_time"
  | "tell_no"
  | "imei"
  | "iccid"
  | "band"
  | "tx_data"
  | "rx_data"
  | "client_version"
  | "wifi_status"
  | "wifi_ssid"
  // | 'frequency'
  | "rssi"
  | "operating_up_time"
  | "monitoring_period_minutes"
  | "check_command_period_seconds"
  | "created_at";

export type DeviceStateOrder = "asc" | "desc";

export type DeviceCommandOrderBy =
  | "command_type"
  | "created_at"
  | "updated_at"
  | "command_status";

export type DeviceCommandOrder = "asc" | "desc";

export const getMonitoringTableHeader = (
  disabledList?: string[],
  noSort?: boolean,
  isAdmin?: boolean
) => {
  const headerList = [
    {
      title: "모델명",
      value: "model_name",
      width: "88px",
      sort: true,
      index: 0,
    },
    {
      title: "현재 상태",
      value: "connection_status",
      width: "96px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["connection_status"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["connection_status"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 0.11,
    },
    {
      title: "태그",
      value: "tags",
      width: "216px",
      sort: false,
      index: 0.2,
      render: (data: any) => {
        const arr = data["tags"];

        if (!!arr && !!arr.length) {
          return (
            <div
              style={{
                display: "flex",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              {arr.map((item: any) => {
                if (!!item && typeof item === "string") {
                  return <Tag text={item} key={item} />;
                } else {
                  return <Tag text={item.name} key={item.id} />;
                }
              })}
            </div>
          );
        } else {
          return "-";
        }
      },
    },

    {
      title: "설명1",
      value: "description1",
      width: "216px",
      sort: true,
      index: 0.3,
    },
    {
      title: "설명2",
      value: "description2",
      width: "216px",
      sort: true,
      index: 0.4,
    },
    {
      title: "일련번호",
      value: "serial_number",
      width: "96px",
      sort: true,
      index: 0.1,
    },
    {
      title: "메모리 가용량",
      value: "memory_free",
      width: "136px",
      sort: true,
      index: 2,
    },
    {
      title: "모니터링 시간",
      value: "current_time",
      width: "168px",
      sort: true,
      render: (data: any) => {
        return !!data.current_time
          ? format(new Date(data.current_time), "yyyy.MM.dd HH:mm:ss")
          : "-";
      },
      index: 3,
    },
    {
      title: "버전",
      value: "sw_version",
      width: "152px",
      sort: true,
      index: 3.5,
    },
    {
      title: "RSRP",
      value: "radio_signal_strength",
      width: "96px",
      sort: true,
      render: (data: any) => {
        const signalStrength = Number(data["radio_signal_strength"]);

        const isSignalStrengthAvailable = data["radio_signal_strength"] != null;
        const isWithinRange = signalStrength >= -150 && signalStrength <= -10;

        if (isSignalStrengthAvailable && isWithinRange) {
          return `${signalStrength} (dB)`;
        } else {
          return "-";
        }
      },
      index: 4,
    },
    {
      title: "RSRQ",
      value: "link_quality",
      width: "96px",
      sort: true,
      render: (data: any) => {
        const linkQuality = Number(data["link_quality"]);

        const isLinkQualityAvailable = data["link_quality"] != null;
        const isWithinRange = linkQuality > -30;

        if (isLinkQualityAvailable && isWithinRange) {
          return `${linkQuality} (dB)`;
        } else {
          return "-";
        }
      },
      index: 5,
    },
    {
      title: "SINR",
      value: "sinr",
      width: "96px",
      sort: true,
      render: (data: any) => {
        const sinrValue = Number(data["sinr"]);

        const isSinrAvailable = data["sinr"] != null;
        const isWithinRange = sinrValue >= -300 && sinrValue < 500;

        if (isSinrAvailable && isWithinRange) {
          return `${sinrValue / 10} (dB)`;
        } else {
          return "-";
        }
      },
      index: 6,
    },
    {
      title: "LTE IP",
      value: "ip_address",
      width: "130px",
      sort: true,
      index: 7,
    },
    {
      title: "APN",
      value: "apn",
      sort: false,
      width: "216px",
      render: (data: any) => {
        const apnArr = `${!!data["apn"] ? data["apn"] : ""}`
          .replaceAll(/[^d]: /g, "")
          .split(" ");

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "4px",
            }}
          >
            {!!apnArr.length &&
            apnArr[0] !== "" &&
            apnArr[0] !== " " &&
            apnArr[0] !== "null"
              ? apnArr.map((apn: string, index: number) => {
                  return (
                    <p key={index}>
                      {index}: {apn}
                    </p>
                  );
                })
              : "-"}
          </div>
        );
      },
      index: 8,
    },
    {
      title: "Serving Cell ID",
      value: "cell_id",
      width: "160px",
      sort: true,
      index: 9,
    },

    {
      title: "모바일 네트워크 MCC/MNC",
      value: "smcc/smnc",
      width: "200px",
      sort: false,
      index: 11,
      render: (data: any) => {
        return !!data["smcc"] && !!data["smnc"]
          ? `${data["smcc"]}/${data["smnc"]}`
          : "-";
      },
    },
    {
      title: "Bridge MAC",
      value: "mac",
      width: "152px",
      sort: true,
      index: 12,
    },
    {
      title: "WAN IP",
      value: "wan_ip_address",
      width: "128px",
      sort: true,
      index: 13,
    },
    {
      title: "LAN IP",
      value: "lan_ip_address",
      width: "132px",
      sort: true,
      index: 14,
    },
    {
      title: "LAN0 Link Up",
      value: "lan0_link_up",
      width: "152px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["lan0_link_up"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["lan0_link_up"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 15,
    },
    {
      title: "LAN1 Link Up",
      value: "lan1_link_up",
      width: "152px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["lan1_link_up"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["lan1_link_up"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 16,
    },
    {
      title: "단말기 부팅 시간",
      value: "wan_up_time",
      width: "168px",
      sort: true,
      index: 17,
    },
    {
      title: "모뎀 번호",
      value: "tell_no",
      width: "136px",
      sort: true,
      index: 18,
    },
    {
      title: "IMEI",
      value: "imei",
      width: "160px",
      sort: true,
      index: 19,
    },
    {
      title: "ICCID",
      value: "iccid",
      width: "200px",
      sort: true,
      index: 20,
    },
    {
      title: "LTE Band",
      value: "band",
      width: "104px",
      sort: true,
      index: 25,
    },
    {
      title: "LTE Frequency",
      value: "frequency",
      width: "160px",
      sort: true,
      render: (data: any) => {
        return !!data["frequency"] || data["frequency"] === 0
          ? Number(data["frequency"])
          : "-";
      },
      index: 22,
    },
    {
      title: "Reject Cause",
      value: "error_cause",
      width: "160px",
      index: 22.5,
    },
    {
      title: "RSSI",
      value: "rssi",
      width: "112px",
      sort: true,
      render: (data: any) => {
        return !!data["rssi"] || data["rssi"] === 0
          ? Number(data["rssi"]) + " (dBm)"
          : "-";
      },
      index: 23,
    },
    {
      title: "발신 데이터",
      value: "tx_data",
      width: "128px",
      sort: true,
      render: (data: any) => {
        const txData = Number(data["tx_data"]);

        if (!txData && txData !== 0) return "-";

        const txDataStr =
          txData < 1024
            ? txData + " (Byte)"
            : txData / 1024 < 1024
            ? Math.round((txData / 1024) * 100) / 100 + " (KB)"
            : txData / 1024 / 1024 < 1024
            ? Math.round((txData / 1024 / 1024) * 100) / 100 + " (MB)"
            : Math.round((txData / 1024 / 1024 / 1024) * 100) / 100 + " (GB)";

        return txDataStr;
      },
      index: 24,
    },
    {
      title: "수신 데이터",
      value: "rx_data",
      width: "128px",
      sort: true,
      render: (data: any) => {
        const rxData = Number(data["rx_data"]);

        if (!rxData && rxData !== 0) return "-";

        const rxDataStr =
          rxData < 1024
            ? rxData + " (Byte)"
            : rxData / 1024 < 1024
            ? Math.round((rxData / 1024) * 100) / 100 + " (KB)"
            : rxData / 1024 / 1024 < 1024
            ? Math.round((rxData / 1024 / 1024) * 100) / 100 + " (MB)"
            : Math.round((rxData / 1024 / 1024 / 1024) * 100) / 100 + " (GB)";

        return rxDataStr;
      },
      index: 21,
    },
    {
      title: "관제 클라이언트 버전",
      value: "client_version",
      width: "210px",
      sort: true,
      index: 26,
    },
    {
      title: "WiFi 상태",
      value: "wifi_status",
      width: "120px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {data["wifi_status"] === "1" ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["wifi_status"] === "0" ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 27,
    },
    {
      title: "WiFi SSID",
      value: "wifi_ssid",
      width: "172px",
      sort: true,
      index: 28,
    },
    {
      title: "WiFi 주파수",
      value: "wifi_frequency",
      width: "128px",
      sort: true,
      index: 29,
    },
    {
      title: "동작 시간",
      value: "operating_up_time",
      width: "128px",
      sort: true,
      render: (data: any) => {
        const operatingUpTime = Number(data["operating_up_time"]);
        const h = Math.floor(operatingUpTime / 60);
        const m = operatingUpTime % 60;

        return `${h}시간 ${m}분`;
      },
      index: 30,
    },

    {
      title: "주변주파수대역",
      value: "rsrp/rsrq",
      width: "320px",
      sort: false,
      render: (data: any) => {
        const rsrqArr = data["rsrq_surrounding_cells"];
        const rsrpArr = data["rsrp_surrounding_cells"];
        return (
          <div>
            {!!rsrpArr.length && (
              <p>
                <span>RSRP: </span>
                {!!rsrpArr.length &&
                  rsrpArr.map((item: any, index: number) => {
                    return (
                      <span key={index}>
                        {index !== 0 && ", "}
                        {item.rsr_value}({item.frequency_band})
                      </span>
                    );
                  })}
              </p>
            )}
            {!!rsrqArr.length && (
              <p>
                <span>RSRQ: </span>
                {!!rsrqArr.length &&
                  rsrqArr.map((item: any, index: number) => {
                    return (
                      <span key={index}>
                        {index !== 0 && ", "}
                        {item.rsr_value}({item.frequency_band})
                      </span>
                    );
                  })}
              </p>
            )}
            {!rsrqArr.length && !rsrpArr.length && <span>-</span>}
          </div>
        );
      },
      index: 32,
    },
    {
      title: "DMZ 설정 여부",
      value: "dmz",
      width: "144px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["dmz"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["dmz"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 33,
    },
    {
      title: "IP/Port Filtering 설정 여부",
      value: "ip_port_filter",
      width: "208px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["ip_port_filter"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["ip_port_filter"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 34,
    },
    {
      title: "Port Forwarding 설정 여부",
      value: "port_forwarding",
      width: "208px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["port_forwarding"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["port_forwarding"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 35,
    },
    {
      title: "DDNS 설정 여부",
      value: "ddns",
      width: "152px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["ddns"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["ddns"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 36,
    },
    {
      title: "VPN 설정 여부",
      value: "vpn",
      width: "144px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["vpn"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["vpn"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 37,
    },
    {
      title: "OneM2M 설정 여부",
      value: "onem2m",
      width: "168px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["onem2m"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["onem2m"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 38,
    },
    {
      title: "관제 주기",
      value: "monitoring_period_minutes",
      width: "120px",
      sort: true,
      render: (data: any) => {
        return !!data["monitoring_period_minutes"] ||
          data["monitoring_period_minutes"] === 0
          ? `${data["monitoring_period_minutes"]} (분)`
          : "-";
      },
      index: 40,
    },
    // {
    //   title: '명령 주기',
    //   value: 'command_check_period_seconds',
    //   width: '120px',
    //   sort: true,
    //   render: (data: any) => {
    //     return !!data['command_check_period_seconds'] || data['command_check_period_seconds'] === 0 ? `${data['command_check_period_seconds']} (초)` : '-';
    //   },
    //   index: 41,
    // },
    {
      title: "수신 시간",
      value: "created_at",
      sort: true,
      width: "168px",
      render: (data: any) => {
        return !!data.created_at
          ? format(new Date(data.created_at), "yyyy.MM.dd HH:mm:ss")
          : "-";
      },
      index: 42,
    },
  ];

  const adminList = ["error_cause"];

  const resultList =
    !!disabledList && !!disabledList.length
      ? headerList.filter((item) => !disabledList.includes(item.value))
      : headerList;
  const checkedAdminList = !!isAdmin
    ? resultList
    : resultList.filter((item) => !adminList.includes(item.value));

  const sortedList = !!noSort
    ? checkedAdminList.map((r) => {
        return {
          ...r,
          sort: false,
        };
      })
    : checkedAdminList;

  return sortedList.sort((a, b) => (a.index < b.index ? -1 : 1));
};

function formatBytes(bytes: number): string {
  const units = ["(Byte)", "(KB)", "(MB)", "(GB)"];
  let unitIndex = 0;

  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024;
    unitIndex++;
  }

  return `${bytes.toFixed(2)} ${units[unitIndex]}`;
}

export const getMonitoringInfoTitle = (
  disabledList?: string[],
  noSort?: boolean
) => {
  const headerList = [
    {
      title: "모델명",
      value: "model_name",
      width: "104px",
      sort: true,
      index: 0,
    },
    {
      title: "웹 UI 활성화 여부",
      value: "web_ui_enabled",
      sort: false,
      index: 0.12,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["web_ui_enabled"] ? (
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                }}
              >
                <CircleIcon sx={{ color: green600 }} />
                <Button
                  onClick={() =>
                    window.open(`http://${data.ip_address}:8090`, "blank")
                  }
                  sx={{ height: "24px" }}
                  size="small"
                  variant="outlined"
                  color="secondary"
                >
                  열기
                </Button>
              </div>
            ) : data["web_ui_enabled"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "현재 상태",
      value: "connection_status",
      width: "112px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["connection_status"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["connection_status"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 0.11,
    },
    {
      title: "태그",
      value: "tags",
      width: "216px",
      sort: false,
      index: 0.2,
      render: (data: any) => {
        const arr = data["tags"];

        if (!!arr && !!arr.length) {
          return (
            <div
              style={{
                display: "flex",
                gap: "4px",
                flexWrap: "wrap",
              }}
            >
              {arr.map((item: any) => {
                if (!!item && typeof item === "string") {
                  return <Tag text={item} key={item} />;
                } else {
                  return <Tag text={item.name} key={item.id} />;
                }
              })}
            </div>
          );
        } else {
          return "-";
        }
      },
    },

    {
      title: "설명1",
      value: "description1",
      width: "216px",
      sort: true,
      index: 0.3,
    },
    {
      title: "설명2",
      value: "description2",
      width: "216px",
      sort: true,
      index: 0.4,
    },
    {
      title: "일련번호",
      value: "serial_number",
      width: "104px",
      sort: true,
      index: 0.1,
    },
    {
      title: "메모리 가용량",
      value: "memory_free",
      width: "136px",
      sort: true,
      index: 2,
    },
    {
      title: "모니터링 시간",
      value: "current_time",
      width: "168px",
      sort: true,
      render: (data: any) => {
        return !!data.current_time
          ? format(new Date(data.current_time), "yyyy.MM.dd HH:mm:ss")
          : "-";
      },
      index: 3,
    },
    {
      title: "버전",
      value: "sw_version",
      width: "152px",
      sort: true,
      index: 3.5,
    },
    {
      title: "RSRP",
      value: "radio_signal_strength",
      width: "152px",
      sort: true,
      render: (data: any) => {
        const signalStrength = Number(data["radio_signal_strength"]);

        const isSignalStrengthAvailable = data["radio_signal_strength"] != null;
        const isWithinRange = signalStrength >= -150 && signalStrength <= -10;

        if (isSignalStrengthAvailable && isWithinRange) {
          return `${signalStrength} (dB)`;
        } else {
          return "-";
        }
      },
      index: 4,
    },
    {
      title: "RSRQ",
      value: "link_quality",
      width: "152px",
      sort: true,
      render: (data: any) => {
        const linkQuality = Number(data["link_quality"]);

        const isLinkQualityAvailable = data["link_quality"] != null;
        const isWithinRange = linkQuality > -30;

        if (isLinkQualityAvailable && isWithinRange) {
          return `${linkQuality} (dB)`;
        } else {
          return "-";
        }
      },
      index: 5,
    },
    {
      title: "SINR",
      value: "sinr",
      width: "144px",
      sort: true,
      render: (data: any) => {
        const sinrValue = Number(data["sinr"]);

        const isSinrAvailable = data["sinr"] != null;
        const isWithinRange = sinrValue >= -300 && sinrValue < 500;

        if (isSinrAvailable && isWithinRange) {
          return `${sinrValue / 10} (dB)`;
        } else {
          return "-";
        }
      },
      index: 6,
    },
    {
      title: "LTE IP",
      value: "ip_address",
      width: "130px",
      sort: true,
      index: 7,
    },
    {
      title: "APN",
      value: "apn",
      sort: false,
      width: "216px",
      render: (data: any) => {
        const apnArr = `${!!data["apn"] ? data["apn"] : ""}`
          .replaceAll(/[^d]: /g, "")
          .split(" ");

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "4px",
            }}
          >
            {!!apnArr.length &&
            apnArr[0] !== "" &&
            apnArr[0] !== " " &&
            apnArr[0] !== "null"
              ? apnArr.map((apn: string, index: number) => {
                  return (
                    <p key={index}>
                      {index}: {apn}
                    </p>
                  );
                })
              : "-"}
          </div>
        );
      },
      index: 8,
    },
    {
      title: "Serving Cell ID",
      value: "cell_id",
      width: "160px",
      sort: true,
      index: 9,
    },

    {
      title: "모바일 네트워크 MCC/MNC",
      value: "smcc/smnc",
      width: "200px",
      sort: false,
      index: 11,
      render: (data: any) => {
        return !!data["smcc"] && !!data["smnc"]
          ? `${data["smcc"]}/${data["smnc"]}`
          : "-";
      },
    },
    {
      title: "Bridge MAC",
      value: "mac",
      width: "152px",
      sort: true,
      index: 12,
    },
    {
      title: "WAN IP",
      value: "wan_ip_address",
      width: "128px",
      sort: true,
      index: 13,
    },
    {
      title: "LAN IP",
      value: "lan_ip_address",
      width: "128px",
      sort: true,
      index: 14,
    },
    {
      title: "LAN0 Link Up",
      value: "lan0_link_up",
      width: "152px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["lan0_link_up"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["lan0_link_up"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 15,
    },
    {
      title: "LAN1 Link Up",
      value: "lan1_link_up",
      width: "152px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["lan1_link_up"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["lan1_link_up"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 16,
    },
    {
      title: "단말기 부팅 시간",
      value: "wan_up_time",
      width: "168px",
      sort: true,
      index: 17,
    },
    {
      title: "모뎀 번호",
      value: "tell_no",
      width: "136px",
      sort: true,
      index: 18,
    },
    {
      title: "IMEI",
      value: "imei",
      width: "160px",
      sort: true,
      index: 19,
    },
    {
      title: "ICCID",
      value: "iccid",
      width: "200px",
      sort: true,
      index: 20,
    },
    {
      title: "LTE Band",
      value: "band",
      width: "120px",
      sort: true,
      index: 25,
    },
    {
      title: "LTE Frequency",
      value: "frequency",
      width: "160px",
      sort: true,
      render: (data: any) => {
        return !!data["frequency"] || data["frequency"] === 0
          ? Number(data["frequency"])
          : "-";
      },
      index: 22,
    },
    {
      title: "RSSI",
      value: "rssi",
      width: "112px",
      sort: true,
      render: (data: any) => {
        return !!data["rssi"] || data["rssi"] === 0
          ? Number(data["rssi"]) + " (dBm)"
          : "-";
      },
      index: 23,
    },
    {
      title: "발신 데이터",
      value: "tx_data",
      width: "128px",
      sort: true,
      render: (data: any) => {
        const txData = Number(data["tx_data"]);

        if (!txData && txData !== 0) return "-";

        const txDataStr = formatBytes(txData);

        return txDataStr;
      },
      index: 24,
    },
    {
      title: "수신 데이터",
      value: "rx_data",
      width: "128px",
      sort: true,
      render: (data: any) => {
        const rxData = Number(data["rx_data"]);

        if (!rxData && rxData !== 0) return "-";

        const rxDataStr = formatBytes(rxData);

        return rxDataStr;
      },
      index: 21,
    },
    {
      title: "관제 클라이언트 버전",
      value: "client_version",
      width: "210px",
      sort: true,
      index: 26,
    },
    {
      title: "WiFi 상태",
      value: "wifi_status",
      width: "120px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {data["wifi_status"] === "1" ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["wifi_status"] === "0" ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 27,
    },
    {
      title: "WiFi SSID",
      value: "wifi_ssid",
      width: "172px",
      sort: true,
      index: 28,
    },
    {
      title: "WiFi 주파수",
      value: "wifi_frequency",
      width: "128px",
      sort: true,
      index: 29,
    },
    {
      title: "동작 시간",
      value: "operating_up_time",
      width: "128px",
      sort: true,
      render: (data: any) => {
        const operatingUpTime = Number(data["operating_up_time"]);
        const h = Math.floor(operatingUpTime / 60);
        const m = operatingUpTime % 60;

        return `${h}시간 ${m}분`;
      },
      index: 30,
    },

    {
      title: "주변주파수대역",
      value: "rsrp/rsrq",
      width: "320px",
      sort: false,
      render: (data: any) => {
        const rsrqArr = data["rsrq_surrounding_cells"];
        const rsrpArr = data["rsrp_surrounding_cells"];
        return (
          <div>
            {!!rsrpArr.length && (
              <p>
                <span>RSRP: </span>
                {!!rsrpArr.length &&
                  rsrpArr.map((item: any, index: number) => {
                    return (
                      <span key={index}>
                        {index !== 0 && ", "}
                        {item.rsr_value}({item.frequency_band})
                      </span>
                    );
                  })}
              </p>
            )}
            {!!rsrqArr.length && (
              <p>
                <span>RSRQ: </span>
                {!!rsrqArr.length &&
                  rsrqArr.map((item: any, index: number) => {
                    return (
                      <span key={index}>
                        {index !== 0 && ", "}
                        {item.rsr_value}({item.frequency_band})
                      </span>
                    );
                  })}
              </p>
            )}
            {!rsrqArr.length && !rsrpArr.length && <span>-</span>}
          </div>
        );
      },
      index: 32,
    },
    {
      title: "DMZ 설정 여부",
      value: "dmz",
      width: "144px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["dmz"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["dmz"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 33,
    },
    {
      title: "IP/Port Filtering 설정 여부",
      value: "ip_port_filter",
      width: "208px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["ip_port_filter"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["ip_port_filter"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 34,
    },
    {
      title: "Port Forwarding 설정 여부",
      value: "port_forwarding",
      width: "208px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["port_forwarding"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["port_forwarding"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 35,
    },
    {
      title: "DDNS 설정 여부",
      value: "ddns",
      width: "152px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["ddns"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["ddns"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 36,
    },
    {
      title: "VPN 설정 여부",
      value: "vpn",
      width: "144px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["vpn"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["vpn"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 37,
    },
    {
      title: "OneM2M 설정 여부",
      value: "onem2m",
      width: "168px",
      sort: true,
      render: (data: any) => {
        return (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {!!data["onem2m"] ? (
              <CircleIcon sx={{ color: green600 }} />
            ) : data["onem2m"] === false ? (
              <CircleIcon sx={{ color: red600 }} />
            ) : (
              "NA"
            )}
          </div>
        );
      },
      index: 38,
    },
    {
      title: "관제 주기",
      value: "monitoring_period_minutes",
      width: "120px",
      sort: true,
      render: (data: any) => {
        return !!data["monitoring_period_minutes"] ||
          data["monitoring_period_minutes"] === 0
          ? `${data["monitoring_period_minutes"]} (분)`
          : "-";
      },
      index: 40,
    },
    // {
    //   title: '명령 주기',
    //   value: 'command_check_period_seconds',
    //   width: '120px',
    //   sort: true,
    //   render: (data: any) => {
    //     return !!data['command_check_period_seconds'] || data['command_check_period_seconds'] === 0 ? `${data['command_check_period_seconds']} (초)` : '-';
    //   },
    //   index: 41,
    // },
    {
      title: "수신 시간",
      value: "created_at",
      sort: true,
      width: "168px",
      render: (data: any) => {
        return !!data.created_at
          ? format(new Date(data.created_at), "yyyy.MM.dd HH:mm:ss")
          : "-";
      },
      index: 42,
    },
  ];

  const resultList =
    !!disabledList && !!disabledList.length
      ? headerList.filter((item) => !disabledList.includes(item.value))
      : headerList;

  const sortedList = !!noSort
    ? resultList.map((r) => {
        return {
          ...r,
          sort: false,
        };
      })
    : resultList;

  return sortedList.sort((a, b) => (a.index < b.index ? -1 : 1));
};

export const getControlTableHeader = (disabledList?: string[]) => {
  const headerList = [
    {
      title: "명령 등록 시간",
      value: "create_at",
      render: (data: any) => {
        return !!data && !!data.created_at
          ? format(new Date(data.created_at), "yyyy.MM.dd HH:mm:ss")
          : "";
      },
      width: "168px",
    },
    {
      title: "요청자",
      value: "user_id",
      render: (data: any) => {
        return !!data && !!data.user && !!data.user.name ? data.user.name : "";
      },
      width: "144px",
    },
    {
      title: "제어 명령",
      value: "command_type",
      width: "208px",
    },
    {
      title: "Parameter 1",
      value: "parameter1",
      width: "272px",
      render: (data: any) => {
        if (data.command_type === "change_data_limit") {
          const dataToggleMap: { [props: string]: string } = {
            "0": "Off",
            "1": "On",
          };

          const dataLimitMap: { [props: string]: string } = {
            "0": "일별",
            "1": "주별",
            "2": "월별",
            "3": "날짜 지정",
          };
          const arr = data["parameter1"].split(",");
          if (dataToggleMap[arr[0]] === "Off") {
            return <div>{dataToggleMap[arr[0]]}</div>;
          } else {
            return (
              <div>
                <p>
                  {dataToggleMap[arr[0]]}: [ {dataLimitMap[arr[1]]} ]{" "}
                  {!!arr[8] && !!arr[9] && `${arr[8]}${arr[9]}`}
                </p>
                {!!arr[2] &&
                  !!arr[3] &&
                  !!arr[4] &&
                  !!arr[5] &&
                  !!arr[6] &&
                  !!arr[7] && (
                    <p>
                      {arr[2]}.{arr[3]}.{arr[4]} ~ {arr[5]}.{arr[6]}.{arr[7]}
                    </p>
                  )}
              </div>
            );
          }
        } else if (data.command_type === "open_web_ui") {
          const webUIOpenMap: { [props: string]: string } = {
            0: "비활성화",
            1: "활성화",
          };

          return webUIOpenMap[data["parameter1"]];
        } else {
          return data["parameter1"];
        }
      },
    },
    {
      title: "Parameter 2",
      value: "parameter2",
      width: "264px",
    },
    {
      title: "상태",
      value: "command_status",
      width: "80px",
      render: (data: any) => {
        return data["command_status"] === "done"
          ? "완료"
          : data["fetch_count"] < 2
          ? "대기"
          : "만료";
      },
    },
  ];

  return !!disabledList && !!disabledList.length
    ? headerList.filter((item) => !disabledList.includes(item.value))
    : headerList;
};

export const getMemberTableHeader = (disabledList?: string[]) => {
  const headerList = [
    {
      title: "이메일",
      value: "email",
      width: "240px",
    },
    {
      title: "이름",
      value: "name",
      width: "144px",
    },
    {
      title: "역할",
      value: "permission",
      width: "120px",
      render: (data: any) => {
        return data["permission"] === "admin" ? "관리자" : "뷰어";
      },
    },
    {
      title: "생성일",
      value: "created_at",
      width: "168px",
      render: (data: any) => {
        return format(new Date(data["created_at"]), "yyyy.MM.dd HH:mm:ss");
      },
    },
  ];

  return !!disabledList && !!disabledList.length
    ? headerList.filter((item) => !disabledList.includes(item.value))
    : headerList;
};

export const defaultDisabledMonitoringList = [
  "memory_free",
  "link_quality",
  "apn",
  "cell_id",
  "smcc/smnc",
  "mac",
  "wan_ip_address",
  "lan_ip_address",
  "lan0_link_up",
  "lan1_link_up",
  "wan_up_time",
  "imei",
  "iccid",
  "frequency",
  "rssi",
  "tx_data",
  "rx_data",
  "client_version",
  "wifi_status",
  "wifi_ssid",
  "wifi_frequency",
  "operating_up_time",
  "rsrp/rsrq",
  "dmz",
  "ip_port_filter",
  "port_forwarding",
  "ddns",
  "vpn",
  "onem2m",
  "monitoring_period_minutes",
  "command_check_period_seconds",
  "error_cause",
];

export const packageStatusMap: { [props: string]: string } = {
  ready: "대기",
  success: "완료",
  failed: "실패",
  proceeding: "진행중",
};
export const deviceStatusMap: { [props: string]: string } = {
  none: "대기",
  succeed: "성공",
  failed: "실패",
  proceeding: "진행중",
};
