import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import { grey100, grey200 } from '../../global/css';

const BodyRowWrapper = styled.div<{ $tableWidth: number; $selected?: boolean; $hasClick?: boolean; $fullWidth: boolean; $autoWidth: number; $type: string }>`
  width: fit-content;
  display: flex;
  box-sizing: border-box;
  min-width: ${(props) => (!!props.$tableWidth ? props.$tableWidth : 0)}px;
  min-height: 48px;
  ${(props) =>
    !!props.$autoWidth &&
    css`
      display: grid;
      width: 100%;
      grid-template-columns: ${!!props.$autoWidth && `${props.$type === 'check' || props.$type === 'radio' ? '72px' : ''} repeat(${props.$autoWidth}, 1fr)`};
    `}
  ${(props) =>
    !!props.$hasClick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${grey100};
      }
    `}

  ${(props) =>
    !!props.$selected &&
    css`
      background-color: ${grey200} !important;
    `}

  ${(props) =>
    !!props.$fullWidth &&
    css`
      width: 100%;
    `}
`;

type BodyRowProps = {
  type: string;
  autoWidth: number;
  selected?: boolean;
  fullWidth?: boolean;
  hasClick?: boolean;
  onClick: () => void;
  children?: React.ReactNode;
  tableWidth?: number;
};

const BodyRow = (props: BodyRowProps) => {
  const { tableWidth, type, autoWidth, selected, fullWidth, hasClick, onClick, children } = props;

  const { ref, inView } = useInView({
    threshold: 0.01,
  });

  return (
    <BodyRowWrapper
      $tableWidth={!!tableWidth ? tableWidth : 0}
      ref={ref}
      $type={type}
      $autoWidth={autoWidth}
      $selected={!!selected}
      $fullWidth={!!fullWidth}
      $hasClick={!!hasClick}
      onClick={onClick}
    >
      {!!inView && children}
    </BodyRowWrapper>
  );
};

export default BodyRow;
