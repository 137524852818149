import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';
import { getMonitoringTableHeader } from '../../global/types';
import Table from '../table/Table';
import TablePagination from '../table/TablePagination';

type CampaignSelectDeviceProps = {
  selected: { [props: string]: any }[];
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  modelName: string;
};

const CampaignSelectDevice = ({ selected, setSelected, modelName }: CampaignSelectDeviceProps) => {
  const [deviceList, setDeviceList] = useState<{ [props: string]: any }[]>([]);
  const [search, setSearch] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedTag, setSelectedTag] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [tableSelected, setTableSelected] = useState<string[]>([]);
  const [allListCount, setAllListCount] = useState<number>(0);

  const [tagList, setTagList] = useState<string[]>([]);
  const [isTagFetching, setIsTagFetching] = useState<boolean>(false);

  const [allGroupList, setAllGroupList] = useState<{ [props: string]: any }[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number>();

  const handleGroup = (group: any) => {
    setSelectedGroupId(allGroupList.find((g) => g.name === group)?.id);
  };

  const getTagList = () => {
    setIsTagFetching(() => true);
    setTagList([]);
    axiosInstance({
      method: 'get',
      url: '/monitoring/tags',
    })
      .then((res) => {
        console.log('/monitoring/tags =>', res);
        setTagList([...res.data]);
      })
      .catch((error) => {
        console.log('/monitoring/tags error =>', error);
      })
      .finally(() => {
        setIsTagFetching(() => false);
      });
  };

  const handleSearch = () => {
    if (!modelName) return;
    setIsFetching(() => true);
    axiosInstance({
      method: 'get',
      url: `/devices/search-for-campaign`,
      params: {
        model_name: modelName,
        search: search,
        page: pageNumber,
        limit: pageSize,
        organization_id: selectedGroupId || null,
        tag: selectedTag || null,
      },
    })
      .then((res) => {
        console.log('deviceList => ', res);
        setAllListCount(res.data.totalCount);
        setDeviceList(res.data.items);
        setAllGroupList(res.data.organizations);
      })
      .catch((error) => {
        console.log('deviceList error => ', error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });
  };

  const checkEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    handleSearch();
  }, [pageNumber, pageSize, selectedTag, selectedGroupId]);

  useEffect(() => {
    if (!!selected && !!selected.length) {
      setTableSelected(selected.map((s) => s.id));
    }
  }, []);

  useEffect(() => {
    const visibleList = deviceList.filter((d) => tableSelected.includes(d.id));
    const deleteList = deviceList.filter((d) => !tableSelected.includes(d.id));

    setSelected([...visibleList, ...selected.filter((s) => !visibleList.map((v) => v.id).includes(s.id)).filter((s) => !deleteList.map((d) => d.id).includes(s.id))]);
  }, [tableSelected]);

  return (
    <CampaignSelectDeviceWrapper>
      <SearchWrapper>
        <TextField
          size='small'
          sx={{ minWidth: '555px' }}
          placeholder='검색 (일련번호, 설명, 버전)'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ cursor: 'pointer' }} onClick={handleSearch} />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={checkEnter}
        />
        <SearchInnerWrapper>
          <Autocomplete
            color='secondary'
            onChange={(_, v: any) => setSelectedTag(v ?? '')}
            sx={{ minWidth: '220px' }}
            size='small'
            options={tagList}
            renderInput={(params: any) => <TextField {...params} label='태그' />}
            loading={isTagFetching}
            onFocus={() => getTagList()}
          />
          <Autocomplete
            color='secondary'
            onChange={(_, v: any) => handleGroup(v)}
            sx={{ minWidth: '220px' }}
            size='small'
            options={allGroupList.map((g) => g.name)}
            renderInput={(params: any) => <TextField {...params} label='조직 선택' />}
            loading={isFetching}
          />
        </SearchInnerWrapper>
      </SearchWrapper>
      <TableWrapper>
        <Table
          tableId='campaign-add-device'
          fullWidth
          maxHeight={`${window.innerHeight - 320}px`}
          isFetching={isFetching}
          size='small'
          dataId='id'
          list={deviceList}
          header={[
            ...getMonitoringTableHeader(
              getMonitoringTableHeader(['model_name', 'serial_number', 'connection_status', 'tags', 'description1', 'description2', 'sw']).map((h) => h.value)
            ),
            {
              title: 'SW 버전',
              value: 'sw_version',
              width: '144px',
              render: (data: any) => {
                return data?.latest_device_state?.sw_version || '-';
              },
            },
          ]}
          selectedItemList={tableSelected}
          setSelectedItemList={setTableSelected}
          checkType='add'
          type='check'
        />
        <div>
          {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNumber} rowsPerPage={pageSize} setPage={setPageNumber} setRowsPerPage={setPageSize} />}
        </div>
      </TableWrapper>
    </CampaignSelectDeviceWrapper>
  );
};

export default CampaignSelectDevice;

const CampaignSelectDeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;
`;

const SearchWrapper = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
`;

const SearchInnerWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

const PopupButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  box-sizing: border-box;
  & > div {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
