import { Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Popup from '../components/Popup';
import TabBox from '../components/TabBox';
import ManagementPassword from '../components/managements/ManagementPassword';
import ManagementUser from '../components/managements/ManagementUser';
import { axiosInstance } from '../global/axiosSetting';
import { grey200, subtitle1 } from '../global/css';
import ManagementDevice from '../components/managements/ManagementDevice';
import ManagementGroup from '../components/managements/ManagementGroup';
import { LoginUserContext } from '../hooks/LoginUserHook';

const ManagementPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid ${grey200};
  padding: 16px;
  row-gap: 8px;
`;

const MessagePopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  & > p {
    ${subtitle1};
  }
  & > div:last-of-type {
    display: flex;
    justify-content: center;
    column-gap: 12px;
  }
`;

const ManagementPage = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const { loginUser } = useContext(LoginUserContext);

  const [messagePopupToggle, setMessagePopupToggle] = useState<boolean>(false);
  const [messagePopupContent, setMessagePopupContent] = useState<{ [props: string]: any }>({});

  const handleTabIndex = (index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (!messagePopupToggle) {
      setMessagePopupContent({});
    }
  }, [messagePopupToggle]);

  useEffect(() => {
    if (!!messagePopupContent && !!messagePopupContent.message) {
      setMessagePopupToggle(() => true);
    }
  }, [messagePopupContent]);

  useEffect(() => {
    if (!loginUser || !loginUser.permission) return;
    const savedIndex = sessionStorage.getItem('managementPageSelectedIndex');

    if (!!savedIndex && (!!Number(savedIndex) || Number(savedIndex) === 0)) {
      setSelectedIndex(Number(savedIndex));
    } else {
      if (loginUser.permission === 'admin') {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(3);
      }
    }
  }, [loginUser]);

  useEffect(() => {
    if (selectedIndex >= 0) {
      sessionStorage.setItem('managementPageSelectedIndex', selectedIndex.toString());
    }
  }, [selectedIndex]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('managementPageSelectedIndex');
    };
  }, []);

  return (
    <ManagementPageWrapper>
      <TabBox
        onChange={handleTabIndex}
        contentPadding='12px 0'
        defaultValue={selectedIndex}
        size='small'
        dataList={
          !!loginUser && !!loginUser.permission
            ? tabList
                .filter((t) => t.permission.includes(loginUser.permission))
                .map((t) => {
                  return {
                    index: t.index,
                    title: t.title,
                  };
                })
            : []
        }
      >
        <div tabIndex={selectedIndex}>
          {!!loginUser && !!loginUser.permission && tabList.find((t) => t.index === selectedIndex)?.render({ setMessage: setMessagePopupContent, loginUser, selectedIndex })}
        </div>
      </TabBox>

      <Popup toggle={messagePopupToggle} setToggle={setMessagePopupToggle}>
        <MessagePopupContentWrapper>
          <p>{messagePopupContent.message}</p>
          <div>
            <Button color={messagePopupContent.type === 'confirm' ? 'secondary' : 'primary'} variant='contained' size='medium' onClick={() => setMessagePopupToggle(() => false)}>
              {messagePopupContent.type === 'confirm' ? '취소' : '확인'}
            </Button>
            {messagePopupContent.type === 'confirm' && (
              <Button variant='contained' size='medium' onClick={!!messagePopupContent.confirm ? messagePopupContent.confirm : () => {}} color='error'>
                확인
              </Button>
            )}
          </div>
        </MessagePopupContentWrapper>
      </Popup>
    </ManagementPageWrapper>
  );
};

export default ManagementPage;

const tabList = [
  {
    index: 0,
    title: '장치 관리',
    permission: ['admin'],
    render: (data: any) => <ManagementDevice {...data} />,
  },
  {
    index: 1,
    title: '사용자 및 엑세스',
    permission: ['admin'],
    render: (data: any) => <ManagementUser {...data} />,
  },
  {
    index: 2,
    title: '조직 관리',
    permission: ['admin'],
    render: (data: any) => <ManagementGroup {...data} />,
  },
  {
    index: 3,
    title: '내 비밀번호 변경',
    permission: ['admin', 'viewer'],
    render: (data: any) => <ManagementPassword {...data} />,
  },
];
