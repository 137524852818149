import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Popup from '../components/Popup';
import { axiosInstance } from '../global/axiosSetting';
import { body1, subtitle1 } from '../global/css';
import LoginPage from '../pages/LoginPage';
import DashboardRouter from './DashboardRouter';

const PopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  & > span {
    ${subtitle1};
  }
  & > p {
    ${body1};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const MainRouter = () => {
  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<{ [props: string]: string }>({});

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      setPopupMessage({
        title: error.response.data.error,
        message: error.response.data.message,
      });

      return Promise.reject(error);
    }
  );
  useEffect(() => {
    if (!!popupToggle) return;
    setPopupMessage({});
  }, [popupToggle]);

  useEffect(() => {
    if (!popupMessage || !Object.keys(popupMessage).length) return;
    setPopupToggle(() => true);
  }, [popupMessage]);
  return (
    <React.Fragment>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='/dashboard/*' element={<DashboardRouter />} />
      </Routes>
      <Popup canClose toggle={popupToggle} setToggle={setPopupToggle}>
        <PopupContentWrapper>
          <span>{popupMessage.title}</span>
          <p>{popupMessage.message}</p>
          <ButtonWrapper>
            <Button variant='outlined' color='secondary' size='medium' onClick={() => setPopupToggle(() => false)}>
              확인
            </Button>
          </ButtonWrapper>
        </PopupContentWrapper>
      </Popup>
    </React.Fragment>
  );
};

export default MainRouter;
