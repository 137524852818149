import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Button, InputAdornment, TextField } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from '../../components/table/Table';
import TablePagination from '../../components/table/TablePagination';
import { axiosInstance } from '../../global/axiosSetting';
import { body2, grey200, muiBorderColor, subtitle1 } from '../../global/css';
import { packageStatusMap } from '../../global/types';
import Popup from '../Popup';
import FullPackage from './FullPackage';

type DiffPackageProps = {
  isPopup?: boolean;
  itemList?: string[];
  setItemList?: React.Dispatch<React.SetStateAction<any>>;
};

const DiffPackage = ({ isPopup, itemList = [], setItemList }: DiffPackageProps) => {
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);

  const [search, setSearch] = useState<string>('');
  const [fotaList, setFotaList] = useState<{ [props: string]: any }[]>([]);

  const [selectedDiff, setSelectedDiff] = useState<{ [props: string]: any }>();
  const [deletePopupToggle, setDeletePopupToggle] = useState<boolean>(false);

  const [addPopupToggle, setAddPopupToggle] = useState<boolean>(false);

  const [selectedBaseVersion, setSelectedBaseVersion] = useState<{
    [props: string]: any;
  }>({});
  const [selectedNewVersion, setSelectedNewVersion] = useState<{
    [props: string]: any;
  }>({});
  const [diffDescription, setDiffDescription] = useState<string>('');

  const [selectPackagePopupType, setSelectPackagePopupType] = useState<'base' | 'new' | ''>('');
  const [selectPackagePopupToggle, setSelectPackagePopupToggle] = useState<boolean>(false);

  const [selectedPackage, setSelectedPackage] = useState<{
    [props: string]: any;
  }>({});

  //
  const [itemIdList, setItemIdList] = useState<string[]>([...itemList]);

  useEffect(() => {
    if (!!setItemList) {
      setItemList(fotaList.find((item: any) => itemIdList.includes(item.id)));
    }
  }, [itemIdList]);
  //

  useEffect(() => {
    if (!!addPopupToggle) return;
    setDiffDescription('');
    setSelectedBaseVersion({});
    setSelectedNewVersion({});
  }, [addPopupToggle]);

  useEffect(() => {
    if (!selectPackagePopupType) return;
    setSelectPackagePopupToggle(true);
  }, [selectPackagePopupType]);

  useEffect(() => {
    if (!!selectPackagePopupToggle) {
    } else {
      setSelectedPackage({});
      setSelectPackagePopupType('');
    }
  }, [selectPackagePopupToggle]);

  const handleResetSelectPackage = () => {
    if (selectPackagePopupType === 'base') {
      setSelectedBaseVersion({});
      setSelectedNewVersion({});
    } else if (selectPackagePopupType === 'new') {
      setSelectedNewVersion({});
    }
    setSelectPackagePopupToggle(false);
  };

  const handleSelectPackage = () => {
    console.log(selectedPackage);
    if (selectPackagePopupType === 'base') {
      setSelectedBaseVersion(selectedPackage);
    } else if (selectPackagePopupType === 'new') {
      setSelectedNewVersion(selectedPackage);
    }
    setSelectPackagePopupToggle(false);
  };

  const handleSearch = (reset?: boolean) => {
    if (isFetching) return;

    if (!!reset) {
      if (pageNum !== 1) {
        setPageNum(1);
        return;
      }
    }

    setIsFetching(true);
    axiosInstance({
      method: 'get',
      url: 'devices/package-diffs',
      params: {
        page: pageNum,
        limit: pageSize,
        search: search,
      },
    })
      .then((res) => {
        console.log('devices/package-diffs =>', res);
        setFotaList([...res.data.items]);
        setAllListCount(res.data.totalCount);
      })
      .catch((error) => {
        console.log('devices/package-diffs error =>', error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleDelete = (id: number) => {
    axiosInstance({
      method: 'delete',
      url: `/devices/package-diffs/${id}`,
    })
      .then((res) => {
        console.log(`/devices/package-diffs/${id} =>`, res);
        handleSearch();
      })
      .catch((error) => {
        console.log(`/devices/package-diffs/${id} error =>`, error);
      })
      .finally(() => {
        setDeletePopupToggle(() => false);
      });
  };

  const checkEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch(true);
    }
  };

  const handleAddDiff = () => {
    const data = {
      from_package_id: selectedBaseVersion.id,
      to_package_id: selectedNewVersion.id,
      description: diffDescription,
    };

    axiosInstance({
      method: 'post',
      url: '/devices/package-diffs',
      data: data,
    })
      .then((res) => {
        console.log('/devices/package-diffs =>', res);
        handleSearch(true);
      })
      .catch((error) => {
        console.log('/devices/package-diffs error =>', error);
      })
      .finally(() => {
        setAddPopupToggle(() => false);
      });
  };

  useEffect(() => {
    if (!selectedDiff || !selectedDiff.id) return;
    setDeletePopupToggle(() => true);
  }, [selectedDiff]);

  useEffect(() => {
    if (!!deletePopupToggle) return;
    setSelectedDiff({});
  }, [deletePopupToggle]);

  useEffect(() => {
    handleSearch();
  }, [pageNum, pageSize]);

  useEffect(() => {
    //테이블 높이 계산
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setInnerHeight(window.innerHeight);
      });
    };
  }, []);

  return (
    <DiffPackageWrapper $fullWidth={!isPopup}>
      <SearchWrapper>
        <TextField
          size='small'
          sx={{ minWidth: '555px' }}
          placeholder='검색 (모델명, 버전, 설명)'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ cursor: 'pointer' }} onClick={() => handleSearch(true)} />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={checkEnter}
        />
        <div>
          <Button title='새로고침' onClick={() => handleSearch()} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <RefreshIcon />
          </Button>
          <Button title='Diff 생성' onClick={() => setAddPopupToggle(true)} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            Diff 생성
          </Button>
        </div>
      </SearchWrapper>
      <TableWrapper>
        <Table
          type={isPopup ? 'radio' : ''}
          tableId='fota_diff_table'
          fullWidth
          isFetching={isFetching}
          maxHeight={`${innerHeight}px`}
          setSelectedItemList={setItemIdList}
          selectedItemList={itemIdList}
          size='small'
          dataId='id'
          list={fotaList}
          header={
            !isPopup
              ? [
                  ...fotaTableHeader,
                  {
                    title: '',
                    value: 'edit',
                    width: '200px',
                    sort: false,
                    index: 4,
                    render: (data: any) => {
                      return (
                        <TableButtonWrapper>
                          {data.status === 'success' && (
                            <Button
                              size='small'
                              variant='outlined'
                              color='secondary'
                              onClick={() => {
                                window.open(data.url, '_blank');
                              }}
                            >
                              다운로드
                            </Button>
                          )}

                          {data.status === 'success' && (
                            <Button size='small' variant='outlined' color='secondary' onClick={() => setSelectedDiff(data)}>
                              삭제
                            </Button>
                          )}
                        </TableButtonWrapper>
                      );
                    },
                  },
                ]
              : fotaTableHeader
          }
        />
        <div>
          {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
        </div>
      </TableWrapper>

      <Popup toggle={deletePopupToggle} setToggle={setDeletePopupToggle}>
        <DeletePopupContentWrapper>
          <div>
            <p>베이스 버전: {selectedDiff?.from_package?.version}</p>
            <p>새로운 버전: {selectedDiff?.to_package?.version}</p>
            <p>패키지(Diff)를 삭제하시겠습니까?</p>
          </div>
          <div>
            <Button color={'secondary'} variant='contained' size='medium' onClick={() => setDeletePopupToggle(() => false)}>
              취소
            </Button>

            <Button variant='contained' size='medium' onClick={() => handleDelete(selectedDiff?.id)} color='error'>
              확인
            </Button>
          </div>
        </DeletePopupContentWrapper>
      </Popup>
      <Popup toggle={addPopupToggle} setToggle={setAddPopupToggle}>
        <AddPopupContentWrapper>
          <div>
            <Button fullWidth onClick={() => setSelectPackagePopupType('base')} sx={{ borderColor: muiBorderColor }} variant='outlined' color='secondary'>
              {!!selectedBaseVersion && !!selectedBaseVersion.id ? selectedBaseVersion.version : '베이스 버전 선택'}
            </Button>
            <Button
              fullWidth
              disabled={!selectedBaseVersion || !selectedBaseVersion.id}
              onClick={() => setSelectPackagePopupType('new')}
              sx={{ borderColor: muiBorderColor }}
              variant='outlined'
              color='secondary'
            >
              {!!selectedNewVersion && !!selectedNewVersion.id ? selectedNewVersion.version : '새로운 버전 선택'}
            </Button>
            <TextField fullWidth size='small' label='설명' name='description' value={diffDescription} onChange={(e) => setDiffDescription(e.target.value)} />
          </div>

          <div>
            <Button color={'secondary'} variant='contained' size='medium' onClick={() => setAddPopupToggle(() => false)}>
              취소
            </Button>

            <Button variant='contained' size='medium' onClick={handleAddDiff}>
              확인
            </Button>
          </div>
        </AddPopupContentWrapper>
      </Popup>
      <Popup toggle={!!selectPackagePopupToggle} setToggle={setSelectPackagePopupToggle}>
        <SelectPackagePopupWrapper>
          <FullPackage
            isPopup
            itemList={selectPackagePopupType === 'base' ? [selectedBaseVersion.id] : selectPackagePopupType === 'new' ? [selectedNewVersion.id] : []}
            setItemList={setSelectedPackage}
            modelName={selectPackagePopupType === 'new' && !!selectedBaseVersion && !!selectedBaseVersion.id ? selectedBaseVersion.model_name! : null}
          />
        </SelectPackagePopupWrapper>
        <PopupButtonWrapper>
          <div>
            {((selectPackagePopupType === 'base' && !!selectedBaseVersion.id) || (selectPackagePopupType === 'new' && !!selectedNewVersion.id)) && (
              <Button variant='contained' size='medium' color='info' onClick={handleResetSelectPackage}>
                선택해제
              </Button>
            )}
          </div>
          <div>
            <Button variant='contained' size='medium' color='secondary' onClick={() => setSelectPackagePopupToggle(false)}>
              취소
            </Button>
            <Button disabled={!selectedPackage || !selectedPackage.id} variant='contained' size='medium' onClick={handleSelectPackage}>
              확인
            </Button>
          </div>
        </PopupButtonWrapper>
      </Popup>
    </DiffPackageWrapper>
  );
};
const fotaTableHeader = [
  {
    title: '베이스 버전',
    value: 'from_package.version',
    width: '220px',
    sort: false,
    index: 0,
    render: (data: any) => {
      return !!data.from_package ? data.from_package.version : '-';
    },
  },
  {
    title: '새로운 버전',
    value: 'to_package.version',
    width: '220px',
    sort: false,
    index: 1,
    render: (data: any) => {
      return !!data.to_package ? data.to_package.version : '-';
    },
  },
  {
    title: '상태',
    value: 'status',
    width: '80px',
    sort: false,
    index: 2,
    render: (data: any) => {
      return data?.status ? packageStatusMap[data.status] : '-';
    },
  },

  {
    title: '설명',
    value: 'description',
    width: '440px',
    sort: false,
    index: 3,
  },
  {
    title: '등록일',
    value: 'created_at',
    sort: false,
    width: '168px',
    render: (data: any) => {
      return !!data.created_at ? format(new Date(data.created_at), 'yyyy.MM.dd HH:mm:ss') : '-';
    },
    index: 4,
  },
];

export default DiffPackage;
const DiffPackageWrapper = styled.div<{ $fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid ${grey200};
  row-gap: 8px;
  width: ${(props) => (!!props.$fullWidth ? 'calc(100% - 8px)' : '996px')};
`;

const SearchWrapper = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    column-gap: 8px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const DeletePopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    & > p {
      ${body2};
      &:last-of-type {
        padding: 8px 0 0;
        ${subtitle1};
      }
    }
  }
  & > div:nth-of-type(2) {
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
  }
`;

const AddPopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 254px;
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  & > div:nth-of-type(2) {
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
    width: 100%;
    justify-content: flex-end;
  }
`;

const SelectPackagePopupWrapper = styled.div``;

const PopupButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  box-sizing: border-box;
  & > div {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
  }
`;
