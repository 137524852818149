import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';
import { add, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import styled from 'styled-components';
import ChartTimeOptionBox, { ChartTimeOptionMap } from '../components/ChartTimeOptionBox';
import DatePicker from '../components/DatePicker';
import LineChartBox from '../components/LineChartBox';
import { axiosInstance } from '../global/axiosSetting';
import { blue400, caption3, elevation, green400, grey200, grey600, headline2, muiBorderColor, red400, subtitle1 } from '../global/css';
import { useNavigate } from 'react-router-dom';

const DashboardPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  row-gap: 16px;
`;

const DashboardPage = () => {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState<{ [props: string]: any }[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<[Range]>([
    {
      startDate: add(new Date(), { days: -7 }),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [selectedChartTimeOption, setSelectedChartTimeOption] = useState<string>('1h');
  const [filteredDataList, setFilteredDataList] = useState<{ [props: string]: any }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGraphLoading, setIsGraphLoading] = useState<boolean>(false);

  const handleDate = (item: Range) => {
    setSelectedDateRange(() => [item]);
  };

  const goToMonitoring = (type: string) => {
    sessionStorage.setItem('monitoringType', type);
    navigate('/dashboard/monitoring');
  };

  const handleSearch = () => {
    if (!!isLoading) return;
    setIsLoading(() => true);
    setIsGraphLoading(() => true);
    axiosInstance({
      method: 'get',
      url: '/monitoring/statistics',
      params: {
        from: format(new Date(`${selectedDateRange[0].startDate}`), 'yyyy-MM-dd'),
        to: format(new Date(`${selectedDateRange[0].endDate}`), 'yyyy-MM-dd'),
      },
    })
      .then((res) => {
        console.log('/monitoring/statistics => ', res);
        setDataList([...res.data.items]);
      })
      .catch((error) => {
        console.log('/monitoring/statistics error => ', error);
      })
      .finally(() => {
        setIsLoading(() => false);
      });
  };

  useEffect(() => {
    if (!selectedDateRange || !selectedDateRange[0] || !selectedDateRange[0].startDate || !selectedDateRange[0].endDate) return;
    handleSearch();
  }, [selectedDateRange]);

  useEffect(() => {
    console.log('dataList => ', dataList, selectedChartTimeOption);

    if (!dataList.length || !selectedChartTimeOption) {
      setFilteredDataList([]);
      setIsGraphLoading(() => false);
      return;
    }
    setIsGraphLoading(() => true);
    let isLast = false;
    const list = [...dataList].sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
    const arr = [];

    for (let i = 0; i < list.length; i++) {
      if (ChartTimeOptionMap[selectedChartTimeOption].byIndex(i)) {
        arr.push(list[i]);
        if (i === dataList.length - 1) isLast = true;
      }
    }

    if (!isLast) arr.push(list[list.length - 1]);

    setFilteredDataList(arr.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)));
    setIsGraphLoading(() => false);
  }, [dataList, selectedChartTimeOption]);

  return (
    <DashboardPageWrapper>
      <DateWrapper>
        <DatePicker color='secondary' onChange={handleDate} size='medium' backgroundColor='white' />
        <Button onClick={handleSearch} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
          <RefreshIcon />
        </Button>
      </DateWrapper>
      <InfoWrapper>
        <div>
          <div onClick={() => goToMonitoring('all')}>
            <span>전체 수량 (대)</span>
            <p>{!!isLoading ? '-' : Number(dataList[0]?.total_count || 0).toLocaleString('ko-kr')}</p>
            <div></div>
          </div>
          <div onClick={() => goToMonitoring('online')}>
            <span>온라인 (대)</span>
            <p>{!!isLoading ? '-' : Number(dataList[0]?.online_devices_count || 0).toLocaleString('ko-kr')}</p>
            <div></div>
          </div>
        </div>
        <div>
          <div onClick={() => goToMonitoring('offline')}>
            <span>오프라인 (대)</span>
            <p>{!!isLoading ? '-' : Number(dataList[0]?.offline_devices_count || 0).toLocaleString('ko-kr')}</p>
            <div>
              <p>🔹 접속이력 없음: {dataList[0]?.not_connected_devices_count}대</p>
              <p>🔹 관제주기 시간초과: {dataList[0]?.last_time_exceeded_devices_count}대</p>
            </div>
          </div>
          <div onClick={() => goToMonitoring('strong')}>
            <span>강전계 (대)</span>
            <p>{!!isLoading ? '-' : Number(dataList[0]?.excellent_signal_devices_count || 0).toLocaleString('ko-kr')}</p>
            <div>🔹 RSRP -89dBm 이상</div>
          </div>
        </div>
        <div>
          <div onClick={() => goToMonitoring('medium')}>
            <span>중전계 (대)</span>
            <p>{!!isLoading ? '-' : Number(dataList[0]?.good_signal_devices_count || 0).toLocaleString('ko-kr')}</p>
            <div>🔹 RSRP -90dBm ~ -110dBm</div>
          </div>
          <div onClick={() => goToMonitoring('weak')}>
            <span>약전계 (대)</span>
            <p>{!!isLoading ? '-' : Number((dataList[0]?.marginal_signal_devices_count || 0) + (dataList[0]?.ok_signal_devices_count || 0)).toLocaleString('ko-kr')}</p>
            <div>🔹 RSRP -110dBm 이하 </div>
          </div>
        </div>
      </InfoWrapper>
      <ChartWrapper>
        <ChartTopWrapper>
          <ChartTimeOptionBox selected={selectedChartTimeOption} setSelected={setSelectedChartTimeOption} />
        </ChartTopWrapper>
        <ChartContentWrapper>
          <LineChartBox
            beginAtZero
            yLabel=' 대'
            isLoading={isLoading || isGraphLoading}
            series={[
              {
                label: '전체 수량',
                data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.total_count)],
                borderColor: blue400,
                backgroundColor: blue400,
              },
              {
                label: '온라인',
                data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.online_devices_count)],
                borderColor: green400,
                backgroundColor: green400,
              },
              {
                label: '오프라인',
                data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.offline_devices_count)],
                borderColor: red400,
                backgroundColor: red400,
              },
            ]}
            title={'전체 수량 | 온/오프라인'}
            list={[...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.created_at)]}
          />
          <LineChartBox
            beginAtZero
            yLabel=' 대'
            isLoading={isLoading || isGraphLoading}
            series={[
              {
                label: '강전계',
                data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.excellent_signal_devices_count)],
                borderColor: blue400,
                backgroundColor: blue400,
              },
              {
                label: '중전계',
                data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.good_signal_devices_count)],
                borderColor: green400,
                backgroundColor: green400,
              },
              {
                label: '약전계',
                data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.marginal_signal_devices_count + d.ok_signal_devices_count)],
                borderColor: red400,
                backgroundColor: red400,
              },
            ]}
            title={'네트워크 수신 전력 수준'}
            list={[...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.created_at)]}
          />
        </ChartContentWrapper>
      </ChartWrapper>
    </DashboardPageWrapper>
  );
};

export default DashboardPage;

const InfoWrapper = styled.div`
  display: flex;
  gap: 16px;
  box-sizing: border-box;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    width: 100%;
    gap: 16px;
    & > div {
      cursor: pointer;
      flex: 1;
      background-color: white;
      border: 1px solid ${grey200};
      min-width: 240px;
      ${elevation};
      border-radius: 16px;
      padding: 12px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      & > span {
        ${subtitle1};
        color: ${grey600};
      }
      & > p {
        ${headline2};
      }
      & > div {
        ${caption3};
        color: ${grey600};
        height: 32px;
      }
    }
  }
`;

const DateWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 8px;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const ChartTopWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ChartContentWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 16px;
`;
