import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';

const ManagementPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const PopupButtonWrapper = styled.div`
  padding-top: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
`;

type ManagementPasswordProps = {
  setMessage: (message: { [props: string]: string }) => void;
  selectedIndex: number;
};

const ManagementPassword = (props: ManagementPasswordProps) => {
  const { setMessage = () => {}, selectedIndex } = props;

  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<{ [props: string]: any }>({});

  const handleMyPassword = (e: any) => {
    setChangePassword((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangePassword = () => {
    axiosInstance({
      method: 'patch',
      url: `/users/password`,
      data: {
        currentPassword: changePassword.currentPassword,
        newPassword: changePassword.newPassword,
      },
    })
      .then((res) => {
        console.log(`/users/password =>`, res);
        setMessage({ message: '비밀번호가 변경되었습니다.' });
        setChangePassword({});
      })
      .catch((error) => {
        console.log(`/users/password error =>`, error);
      });
  };

  useEffect(() => {
    setChangePassword({});
  }, [selectedIndex]);

  return (
    <>
      <ManagementPasswordWrapper>
        <FormControl fullWidth variant='outlined'>
          <InputLabel size='small' htmlFor='current-password'>
            현재 비밀번호
          </InputLabel>
          <OutlinedInput
            id='current-password'
            type={showCurrentPassword ? 'text' : 'password'}
            name='currentPassword'
            value={changePassword.currentPassword || ''}
            onChange={(e) => handleMyPassword(e)}
            size='small'
            endAdornment={
              <InputAdornment position='end'>
                <IconButton size='small' onClick={() => setShowCurrentPassword((show) => !show)} onMouseDown={(e) => e.preventDefault()} edge='end'>
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label='현재 비밀번호'
          />
        </FormControl>
        <FormControl fullWidth variant='outlined'>
          <InputLabel size='small' htmlFor='new-password'>
            새 비밀번호
          </InputLabel>
          <OutlinedInput
            id='new-password'
            type={showNewPassword ? 'text' : 'password'}
            name='newPassword'
            value={changePassword.newPassword || ''}
            onChange={(e) => handleMyPassword(e)}
            size='small'
            endAdornment={
              <InputAdornment position='end'>
                <IconButton size='small' onClick={() => setShowNewPassword((show) => !show)} onMouseDown={(e) => e.preventDefault()} edge='end'>
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label='새 비밀번호'
          />
        </FormControl>

        <PopupButtonWrapper>
          <Button disabled={!changePassword || !changePassword.currentPassword || !changePassword.newPassword} variant='contained' size='medium' onClick={handleChangePassword}>
            변경
          </Button>
        </PopupButtonWrapper>
      </ManagementPasswordWrapper>
    </>
  );
};

export default ManagementPassword;
