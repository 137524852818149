import CircleIcon from '@mui/icons-material/Circle';
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Popup from '../components/Popup';
import TagSelectBox from '../components/TagSelectBox';
import DataLimitBox from '../components/deviceCommandControls/DataLimitBox';
import DefaultDeviceCommandControl from '../components/deviceCommandControls/DefaultDeviceCommandControl';
import LTEBandBox from '../components/deviceCommandControls/LTEBandBox';
import Table from '../components/table/Table';
import TablePagination from '../components/table/TablePagination';
import { axiosInstance } from '../global/axiosSetting';
import { green600, grey600, grey900, red600, subtitle1, subtitle2 } from '../global/css';
import { getMonitoringTableHeader } from '../global/types';
import { checkIsNumber, checkKeyDown, checkValidate } from '../global/utils';

const ControlPageWrapper = styled.div`
  display: flex;
  padding: 0 16px 16px;
  box-sizing: border-box;
  background-color: white;
  column-gap: 20px;
  position: relative;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    position: sticky;
    top: 80px;
    margin-top: 20px;
    height: fit-content;
    box-sizing: border-box;
    z-index: 3;
    width: 240px;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  background-color: white;
  position: sticky;
  top: 64px;
  width: 100%;
  z-index: 4;
  padding: 16px 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    ${subtitle2};
    /* color: ${grey600}; */
    & > p {
      ${subtitle2};
      color: ${grey900};
      & > span {
        ${subtitle2};
        color: ${green600};
      }
    }
  }
`;

const PopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  & > p {
    ${subtitle1};
  }
`;

const ControlPopupButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  justify-content: center;
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  position: relative;
  box-sizing: border-box;
  gap: 16px;
`;

const ControlButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;

const ControlPage = () => {
  const [deviceList, setDeviceList] = useState<{ [props: string]: any }[]>([]);
  const [selectedTagList, setSelectedTagList] = useState<string[]>([]);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [deviceType, setDeviceType] = useState<string>('');

  const [param1, setParam1] = useState<string>('');
  const [param2, setParam2] = useState<string>('');
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [controlTypeList, setControlTypeList] = useState<{ [props: string]: any }[]>([]);
  const [selectedControlType, setSelectedControlType] = useState<{
    [props: string]: any;
  }>({});

  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  const [confirmToggle, setConfirmToggle] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [organizationList, setOrganizationList] = useState<{ [props: string]: any }[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<string>('');

  const handleWebUI = (type: boolean) => {
    setParam1(!!type ? '1' : '0');

    setConfirmToggle(() => true);
  };

  const handleControlSubmit = () => {
    let dataParams: { [props: string]: any } = {
      command_type: selectedControlType.type,
      tags: selectedTagList,
    };

    if (selectedControlType.param_count === 1) {
      dataParams.parameter1 = param1;
    } else if (selectedControlType.param_count === 2) {
      dataParams.parameter1 = param1;
      dataParams.parameter2 = param2;
    }

    console.log(dataParams);

    axiosInstance({
      method: 'post',
      url: `/control/commands`,
      data: {
        ...dataParams,
      },
    })
      .then((res) => {
        console.log(`/control/commands =>`, res);
        setParam1('');
        setParam2('');
      })
      .catch((error) => {
        console.log(`/control/commands error =>`, error);
      })
      .finally(() => {
        setPopupToggle(() => false);
        setConfirmToggle(() => false);
        setRefresh((prev: boolean) => !prev);
      });
  };

  useEffect(() => {
    if (selectedTagList.length === 0) {
      setDeviceList([]);
      setAllListCount(0);
      setOrganizationList([]);
      return;
    }
    setIsFetching(() => true);
    axiosInstance({
      method: 'get',
      url: '/devices/search',
      params: {
        tags: selectedTagList.toString(),
        page: pageNum,
        limit: pageSize,
        organization_id: !!selectedOrganization ? Number(selectedOrganization) : null,
      },
    })
      .then((res) => {
        console.log('/devices/search =>', res);
        setDeviceList([...res.data.items]);
        setAllListCount(res.data.totalCount);
        setOrganizationList(res.data.organizations);
      })
      .catch((error) => {
        console.log('/devices/search error =>', error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });

    axiosInstance({
      method: 'get',
      url: `/control/commands/types`,
      params: {
        tags: selectedTagList.toString(),
        organization_id: !!selectedOrganization ? Number(selectedOrganization) : null,
      },
    })
      .then((res) => {
        console.log(`/control/commands/types =>`, res);
        setControlTypeList([...res.data]);
      })
      .catch((error) => {
        console.log(`/control/commands/types error =>`, error);
      });
  }, [selectedTagList, refresh, pageNum, pageSize, selectedOrganization]);

  useEffect(() => {
    if (organizationList.length < 2) {
      setSelectedOrganization('');
    } else {
      if (!organizationList.find((o) => Number(o.id) === Number(selectedOrganization))) {
        setSelectedOrganization('');
      }
    }
  }, [organizationList]);

  const reset = () => {
    setParam1('');
    setParam2('');
    setCanSubmit(() => false);
  };

  useEffect(() => {
    reset();
  }, [selectedControlType]);

  useEffect(() => {
    if (!!popupToggle) return;

    reset();
  }, [popupToggle]);

  const deviceCommandControlMap: { [props: string]: any } = useMemo(() => {
    return {
      reboot: {
        type: null,
      },
      modem_at_command: {
        type: 'default',
        param1Title: '명령어 입력',
      },
      linux_command: {
        type: 'default',
        param1Title: '명령어 입력',
      },
      select_lte_band: {
        type: 'lteBand',
      },
      run_sh_file: {
        type: 'default',
        param1Title: 'sh 파일 다운로드 URL',
        param2Title: 'sh 파일 사이즈(바이트)',
      },
      connect_rcs: {
        type: 'default',
        param1Title: 'IP 주소',
        param2Title: 'Port 번호',
        onKeyDown2: checkIsNumber,
        onCheck1: (v: string) => checkValidate(v, /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/),
        onKeyDown1: (e: any) => checkKeyDown(e, /^[0-9.]*$/),
      },
      change_monitoring_periods: {
        type: 'default',
        param1Title: '보고 주기 분(1-1440)',
        onKeyDown1: checkIsNumber,
        onCheck1: (v: string) => checkValidate(v, /^([1-9][0-9]{0,2}|1[0-3][0-9]{2}|14[0-3][0-9]|1440)$/),
        valueType: 'number',
      },
      open_web_ui: {
        customButton: () => {
          return (
            <ControlButtonWrapper>
              <Button sx={{ width: '104px' }} variant='contained' onClick={() => handleWebUI(false)}>
                비활성화
              </Button>
              <Button sx={{ width: '104px' }} variant='contained' onClick={() => handleWebUI(true)}>
                활성화
              </Button>
            </ControlButtonWrapper>
          );
        },
      },
      change_monitoring_server: {
        type: 'default',
        param1Title: '관제 서버 URL',
      },
      change_data_limit: {
        type: 'dataLimit',
      },
      reset_data_usage: {
        type: null,
      },
    };
  }, []);

  return (
    <ControlPageWrapper>
      <LeftWrapper>
        <div>
          <TagSelectBox selectedTagList={selectedTagList} setSelectedTagList={setSelectedTagList} refresh={refresh} />
        </div>
      </LeftWrapper>

      <RightWrapper>
        <ButtonWrapper>
          <div>
            <p>
              <span>{selectedTagList.length}</span>
              개의 태그
            </p>
            ,&nbsp;
            <p>
              <span>{allListCount}</span>
              개의 장치
            </p>
            &nbsp;선택됨
          </div>
          <Button disabled={!selectedTagList.length || allListCount < 1} size='small' variant='contained' onClick={() => setPopupToggle(() => true)}>
            제어
          </Button>
        </ButtonWrapper>
        <SelectOrganizationWrapper>
          {organizationList.length > 1 && (
            <FormControl sx={{ minWidth: '150px' }} size='small'>
              <InputLabel id='control-organization-select'>조직 선택</InputLabel>
              <Select labelId='control-organization-select' value={selectedOrganization} label='조직 선택' onChange={(e) => setSelectedOrganization(e.target.value)}>
                <MenuItem value={''}>전체</MenuItem>
                {organizationList.map((organization: any, index: number) => {
                  return (
                    <MenuItem key={index} value={organization.id}>
                      {organization.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </SelectOrganizationWrapper>
        <TableWrapper>
          <Table
            tableId='group_control_table'
            fullWidth
            isFetching={isFetching}
            size='small'
            dataId='id'
            list={[...deviceList]}
            noDataText={!!selectedTagList.length ? '' : '태그 목록에서 태그를 먼저 선택해주세요.'}
            header={[
              ...getMonitoringTableHeader(
                [...getMonitoringTableHeader().filter((h) => !tableHeaderList.includes(h.value))].map((h) => h.value),
                true
              ),
              {
                title: '현재 상태',
                value: 'next_access_at',
                width: '96px',
                index: 0.11,
                render: (data: any) => {
                  let flag = false;
                  const now = new Date();
                  if (!!data['next_access_at']) {
                    if (new Date(data['next_access_at']) > now) {
                      flag = true;
                    }
                  }

                  return (
                    <div
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      {!data['last_access_at'] ? '-' : !!flag ? <CircleIcon sx={{ color: green600 }} /> : <CircleIcon sx={{ color: red600 }} />}
                    </div>
                  );
                },
              },
              {
                title: '조직',
                value: 'organization',
                width: '160px',
                index: 10,
              },
              {
                title: '등록일',
                value: 'created_at',
                width: '168px',
                index: 11,
                render: (data: any) => {
                  return !!data.created_at ? format(new Date(data.created_at), 'yyyy.MM.dd HH:mm:ss') : '-';
                },
              },
            ].sort((a, b) => (a.index < b.index ? -1 : 1))}
          />
          <div>
            {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
          </div>
        </TableWrapper>
      </RightWrapper>

      <Popup hasCloseButton toggle={popupToggle} setToggle={setPopupToggle}>
        <ControlWrapper>
          <Autocomplete
            onChange={(_, v: any) => setSelectedControlType(v ?? {})}
            sx={{ minWidth: '240px', width: '240px' }}
            size='small'
            options={controlTypeList}
            getOptionLabel={(option) => option.title}
            renderInput={(params: any) => <TextField {...params} label='제어 명령' />}
            isOptionEqualToValue={(option, value) => option.type === value.type}
          />

          {deviceCommandControlMap[selectedControlType.type]?.type === 'default' ? (
            <DefaultDeviceCommandControl
              setParam1={setParam1}
              setParam2={setParam2}
              setCanSubmit={setCanSubmit}
              param1Title={deviceCommandControlMap[selectedControlType.type].param1Title}
              param2Title={deviceCommandControlMap[selectedControlType.type].param2Title}
              param1={param1}
              param2={param2}
              onCheck1={deviceCommandControlMap[selectedControlType.type]?.onCheck1}
              onCheck2={deviceCommandControlMap[selectedControlType.type]?.onCheck2}
              onKeyDown1={deviceCommandControlMap[selectedControlType.type]?.onKeyDown1}
              onKeyDown2={deviceCommandControlMap[selectedControlType.type]?.onKeyDown2}
              valueType1={deviceCommandControlMap[selectedControlType.type]?.valueType || 'string'}
              valueType2={deviceCommandControlMap[selectedControlType.type]?.valueType || 'string'}
            />
          ) : deviceCommandControlMap[selectedControlType.type]?.type === 'dataLimit' ? (
            <DataLimitBox setCanSubmit={setCanSubmit} setParam1={setParam1} />
          ) : deviceCommandControlMap[selectedControlType.type]?.type === 'lteBand' ? (
            <LTEBandBox modelName={deviceType} setCanSubmit={setCanSubmit} setParam1={setParam1} />
          ) : (
            <React.Fragment />
          )}

          {!!selectedControlType && !!selectedControlType.type && (
            <>
              {!!deviceCommandControlMap[selectedControlType.type] && !!deviceCommandControlMap[selectedControlType.type].customButton ? (
                <>{deviceCommandControlMap[selectedControlType.type].customButton()}</>
              ) : (
                <Button
                  disabled={!(!!deviceCommandControlMap[selectedControlType.type]?.type ? !!canSubmit : true)}
                  sx={{ width: '104px' }}
                  variant='contained'
                  onClick={() => setConfirmToggle(() => true)}
                >
                  등록
                </Button>
              )}
            </>
          )}
        </ControlWrapper>
      </Popup>
      <Popup toggle={confirmToggle} setToggle={setConfirmToggle}>
        <PopupContentWrapper>
          <p>제어 명령을 등록하시겠습니까?</p>
          <ControlPopupButtonWrapper>
            <Button size='large' variant='contained' color='secondary' onClick={() => setConfirmToggle(() => false)}>
              취소
            </Button>
            <Button size='large' variant='contained' onClick={handleControlSubmit}>
              확인
            </Button>
          </ControlPopupButtonWrapper>
        </PopupContentWrapper>
      </Popup>
    </ControlPageWrapper>
  );
};

export default ControlPage;

const tableHeaderList = ['description1', 'description2', 'model_name', 'serial_number', 'organization', 'tags'];

const SelectOrganizationWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0;
  box-sizing: border-box;
`;
