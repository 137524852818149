import { isAfter, isBefore } from 'date-fns';

export const checkIsNumber = (e: any) => {
  const key = e.key;
  const acceptKeyList = ['Backspace', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Tab'];

  if (!(/[0-9]/.test(key) || acceptKeyList.includes(key))) {
    e.preventDefault();
  }
};

export const checkKeyDown = (e: any, regexp: RegExp) => {
  const key = e.key;
  const acceptKeyList = ['Backspace', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Tab'];
  if (!(regexp.test(key) || acceptKeyList.includes(key))) {
    e.preventDefault();
  }
};

export const checkValidate = (value: string, regexp: RegExp) => {
  const reg = new RegExp(regexp);
  const result = reg.test(value);

  return result;
};

export const getChidren = (data: { [props: string]: any }, arr: { [props: string]: any }[]) => {
  arr.push(data);
  if (!!data.children && !!data.children.length) {
    for (const child of data.children) {
      getChidren(child, arr);
    }
  }

  return arr;
};

export const getGroupPathName = (data: { [props: string]: any }, allGroupList: { [props: string]: any }[], getLast?: boolean) => {
  const arr: string[] = [];

  const findGroup = (group: { [props: string]: any }, arr: string[]) => {
    arr.push(group.name);
    const parent = allGroupList.find((item) => item.id === group.parent_id);
    if (!!parent) {
      findGroup(parent, arr);
    }

    return arr;
  };

  findGroup(data, arr);

  if (arr.length === 0) return null;
  else {
    if (getLast) return arr.reverse()[arr.length - 1];
    else return arr.reverse().join(' > ');
  }
};

export const getCampaignStatus = (data: any) => {
  let str = '';
  const now = new Date();

  if (!data['available']) {
    str = '비활성';
  } else if (isBefore(now, new Date(data['start_at']))) {
    str = '진행 대기';
  } else if (isAfter(now, new Date(data['end_at']))) {
    str = '진행 완료';
  } else if (isAfter(now, new Date(data['start_at'])) && isBefore(now, new Date(data['end_at']))) {
    str = '진행중';
  }

  return str;
};
