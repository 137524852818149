import {
  CategoryScale,
  Chart,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  Legend,
  LineControllerChartOptions,
  LineElement,
  LinearScale,
  PluginChartOptions,
  PointElement,
  ScaleChartOptions,
  Title,
  Tooltip,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { format } from 'date-fns';
import React from 'react';
import { Line } from 'react-chartjs-2';
import styled, { css } from 'styled-components';
import { elevation, grey200, subtitle2 } from '../global/css';
import Loading from './Loading';

const ChartBoxWrapper = styled.div<{ $height?: number; $border: string; $fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: ${(props) => (!!props.$border ? props.$border : `1px solid ${grey200}`)};
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  min-width: 400px;
  border-radius: 12px;
  height: fit-content;
  flex: 1;
  ${(props) =>
    !!props.$fullWidth &&
    css`
      min-width: 100%;
    `}
  ${elevation};
  & > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    box-sizing: border-box;
    & > span {
      ${subtitle2};
    }
    & > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

type ChartBoxProps = {
  list?: string[];
  series?: any;
  title: string;
  border?: string;
  topRightData?: React.ReactNode;
  fullWidth?: boolean;
  yLabel?: string;
  height?: number;
  yInterval?: number;
  isLoading?: boolean;
  beginAtZero?: boolean;
};

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChartBox = (props: ChartBoxProps) => {
  const { beginAtZero, isLoading, yInterval, height, yLabel, list = [], series = [], title, border = '', topRightData, fullWidth } = props;

  const options: _DeepPartialObject<
    CoreChartOptions<'line'> & ElementChartOptions<'line'> & PluginChartOptions<'line'> & DatasetChartOptions<'line'> & ScaleChartOptions<'line'> & LineControllerChartOptions
  > = {
    scales: {
      y: {
        beginAtZero: !!beginAtZero,
        ticks: {
          callback: function (value: any, index: any, values: any) {
            return value + (!!yLabel ? yLabel : '');
          },
          stepSize: !!yInterval ? yInterval : 1,
        },
      },
      x: {
        ticks: {
          callback(tickValue, index, ticks) {
            return format(new Date(list[index]), 'MM/dd HH:mm');
          },
          // autoSkip: false,
          // autoSkipPadding: 0,
          // maxTicksLimit: 10,
        },
      },
    },
    responsive: true,
    elements: {
      point: {
        pointStyle: false,
      },
    },
    plugins: {
      decimation: {
        enabled: true,
        algorithm: 'lttb',
        samples: 100,
      },
      tooltip: {
        enabled: true,
        intersect: false,
        mode: 'index',
        caretSize: 0,
        callbacks: {
          title: function (tooltipItem: any) {
            return format(new Date(tooltipItem[0].label), 'yyyy-MM-dd HH:mm:ss');
          },
          label: function (tooltipItem: any) {
            return ' ' + series[tooltipItem.datasetIndex].label + ': ' + tooltipItem.formattedValue + (!!yLabel ? yLabel : '');
          },
        },
      },
    },
  };

  const data = {
    labels: list,
    datasets: series,
  };

  return (
    <ChartBoxWrapper $height={height} $fullWidth={!!fullWidth} $border={border}>
      <div>
        <span>{title}</span>
        <div>{topRightData}</div>
      </div>
      {!!isLoading ? <Loading width='100%' height={!!height ? `${height}px` : '200px'} /> : <Line options={options} data={data} />}
    </ChartBoxWrapper>
  );
};

export default LineChartBox;
