import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Button, FormControlLabel, InputAdornment, Modal, Radio, RadioGroup, TextField } from '@mui/material';
import { format, isAfter, isBefore } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';
import { caption1, caption2, caption3, grey200, grey600, grey900, muiBorderColor, subtitle1 } from '../../global/css';
import { deviceStatusMap } from '../../global/types';
import Tag from '../Tag';
import Table from '../table/Table';
import TablePagination from '../table/TablePagination';
import { getCampaignStatus } from '../../global/utils';
const CampaignDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

type CampaignDetailProps = {
  campaignId: string;
  upDownStatus: number;
};

const CampaignDetail = ({ campaignId, upDownStatus }: CampaignDetailProps) => {
  const [campaign, setCampaign] = useState<{ [props: string]: any }>({});
  const [deviceList, setDeviceList] = useState<{ [props: string]: any }[]>([]);
  const [allListCount, setAllListCount] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [search, setSearch] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [statistics, setStatistics] = useState<{ [props: string]: any }>({});

  const [selectedResult, setSelectedResult] = useState<{
    [props: string]: any;
  }>({});
  const [resultModalToggle, setResultModalToggle] = useState<boolean>(false);

  useEffect(() => {
    console.log('selectedResult =>', selectedResult);
    if (!selectedResult || !selectedResult.id) return;
    setResultModalToggle(true);
  }, [selectedResult]);

  useEffect(() => {
    if (!!resultModalToggle) return;
    setSelectedResult({});
  }, [resultModalToggle]);

  const handleSearch = (reset?: boolean) => {
    if (isFetching) return;

    if (!!reset) {
      if (pageNum !== 1) {
        setPageNum(1);
        return;
      }
    }

    setIsFetching(true);
    axiosInstance({
      method: 'get',
      url: `/devices/campaigns/${campaignId}/devices`,
      params: {
        page: pageNum,
        limit: pageSize,
        search: search,
        status: status || null,
      },
    })
      .then((res) => {
        console.log('devices! =>', res);
        setDeviceList([...res.data.items]);
        setAllListCount(res.data.totalCount);
        setStatistics(res.data.statistics);
      })
      .catch((error) => {
        console.log('devices error =>', error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const checkEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch(true);
    }
  };

  const getCampaignNewVersion = (campaign: { [props: string]: any }) => {
    if (!!campaign.package_id) {
      return !!campaign.package ? campaign.package.version : '-';
    } else {
      return !!campaign.package_diff && !!campaign.package_diff.to_package ? campaign.package_diff.to_package.version : '-';
    }
  };

  useEffect(() => {
    axiosInstance({
      method: 'get',
      url: `/devices/campaigns/${campaignId}`,
    })
      .then((res) => {
        console.log('/campaigns/${campaignId} =>', res);
        setCampaign(res.data);
      })
      .catch((error) => {
        console.log('/campaigns/${campaignId} error =>', error);
      });
    if (!status) {
      handleSearch(true);
    } else {
      setStatus('');
    }
  }, [campaignId]);

  useEffect(() => {
    handleSearch();
  }, [pageNum, pageSize]);

  useEffect(() => {
    handleSearch(true);
  }, [status]);

  return (
    <CampaignDetailWrapper>
      <InfoWrapper>
        <p>
          캠페인명 : <span>{campaign?.name}</span>
        </p>
        <p>
          상태 : <span>{getCampaignStatus(campaign)}</span>
        </p>
      </InfoWrapper>
      <DescriptionWrapper>
        <div>
          <span>태그</span>

          {campaign?.target_tags?.map((tag: string, index: number) => (
            <Tag key={index} text={tag} />
          ))}
        </div>
        <div>
          <span>조직</span>

          {campaign?.organization || '-'}
        </div>
        <div>
          <span>설명</span>
          {campaign?.description || '-'}
        </div>
      </DescriptionWrapper>
      <DescriptionWrapper>
        <div>
          <span>베이스 버전</span>
          {campaign?.package_diff?.from_package?.version || '-'}
        </div>
        <div>
          <span>새로운 버전</span>
          {getCampaignNewVersion(campaign)}
        </div>
        <div>
          <span>기간</span>
          {!!campaign?.start_at ? format(new Date(campaign.start_at), 'yyyy.MM.dd') : ''} ~ {!!campaign?.end_at ? format(new Date(campaign.end_at), 'yyyy.MM.dd') : ''}
        </div>
      </DescriptionWrapper>
      <Wrapper>
        <RadioGroup row aria-label='status' name='status' value={status} onChange={(e: any) => setStatus(e.target.value)}>
          <FormControlLabel
            style={{ color: grey600 }}
            value={''}
            control={<Radio size='small' />}
            label={`전체 (${statistics.none + statistics.succeed + statistics.failed + statistics.proceeding})`}
          />

          {Object.keys(deviceStatusMap).map((key: string) => {
            return <FormControlLabel key={key} style={{ color: grey600 }} value={key} control={<Radio size='small' />} label={`${deviceStatusMap[key]} (${statistics[key]})`} />;
          })}
        </RadioGroup>
        <SearchWrapper>
          <TextField
            size='small'
            sx={{ minWidth: '555px' }}
            placeholder='검색 (모델명, 일련번호)'
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon sx={{ cursor: 'pointer' }} onClick={() => handleSearch(true)} />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={checkEnter}
          />
          <div>
            <Button title='새로고침' onClick={() => handleSearch()} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
              <RefreshIcon />
            </Button>
          </div>
        </SearchWrapper>
        <TableWrapper>
          <Table
            tableId='fota_campaign_detail_table'
            fullWidth
            isFetching={isFetching}
            maxHeight={`${upDownStatus - 264}px`}
            size='small'
            dataId='id'
            list={deviceList}
            header={[
              ...headerList,
              {
                title: '결과',
                value: 'result',
                width: '88px',
                render: (data: { [props: string]: any }) => {
                  return data['error_message'] ? (
                    <Button variant='outlined' color='secondary' size='small' onClick={() => setSelectedResult(data)}>
                      상세 보기
                    </Button>
                  ) : (
                    '-'
                  );
                },
              },
            ]}
          />
          <div>
            {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
          </div>
        </TableWrapper>
      </Wrapper>
      <Modal open={resultModalToggle} onClose={() => setResultModalToggle(() => false)}>
        <>
          <ResultModalContent>
            <ResultModalInnerWrapper>
              <pre>{selectedResult.error_message}</pre>
            </ResultModalInnerWrapper>
            <ResultModalBottomWrapper>
              <Button onClick={() => setResultModalToggle(() => false)} variant='outlined' color='secondary' size='medium'>
                닫기
              </Button>
            </ResultModalBottomWrapper>
          </ResultModalContent>
        </>
      </Modal>
    </CampaignDetailWrapper>
  );
};

const headerList = [
  {
    title: '모델명',
    value: 'model_name',
    width: '88px',
    render: (data: { [props: string]: any }) => {
      return data.device.model_name;
    },
  },
  {
    title: '일련번호',
    value: 'serial_number',
    width: '104px',

    render: (data: { [props: string]: any }) => {
      return data.device.serial_number;
    },
  },
  {
    title: '태그',
    value: 'tags',
    width: '216px',
    render: (data: any) => {
      const arr = data.device['tags'];

      if (!!arr && !!arr.length) {
        return (
          <div
            style={{
              display: 'flex',
              gap: '4px',
              flexWrap: 'wrap',
            }}
          >
            {arr.map((item: any) => {
              if (!!item && typeof item === 'string') {
                return <Tag text={item} key={item} />;
              } else {
                return <Tag text={item.name} key={item.id} />;
              }
            })}
          </div>
        );
      } else {
        return '-';
      }
    },
  },
  {
    title: '버전',
    value: 'sw_version',
    width: '152px',
    render: (data: { [props: string]: any }) => {
      return data.device.latest_device_state.sw_version;
    },
  },
  {
    title: '상태',
    value: 'status',
    width: '88px',
    render: (data: { [props: string]: any }) => {
      return !!data?.status ? deviceStatusMap[data.status] : '-';
    },
  },
  {
    title: '수정일',
    value: 'updated_at',
    width: '168px',
    render: (data: { [props: string]: any }) => {
      return format(new Date(data['updated_at']), 'yyyy.MM.dd HH:mm:ss');
    },
  },
];

export default CampaignDetail;

const InfoWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
  & > p {
    width: fit-content;
    min-width: fit-content;
    color: ${grey600};
    ${caption1};
    & > span {
      ${subtitle1};
      color: ${grey900};
    }
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  padding: 4px 0;
  & > div {
    ${caption2};
    display: flex;
    align-items: center;
    & > span {
      ${caption3};
      display: flex;
      font-feature-settings: 'tnum';
      color: ${grey600};
      padding: 0 9px 0 0;
      box-sizing: border-box;
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  row-gap: 8px;
`;
const SearchWrapper = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    column-gap: 8px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 8px 0 0;
`;

const ResultModalContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${grey200};
  border-radius: 12px;
  box-sizing: border-box;
  background-color: white;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 20px;
  min-width: 70%;
  max-height: 100vh;
  overflow-y: auto;
`;

const ResultModalInnerWrapper = styled.div`
  display: flex;
  & > pre {
    ${caption2};
    white-space: pre-wrap;
    word-break: break-all;
  }
`;

const ResultModalBottomWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 16px 0 0;
  box-sizing: border-box;
`;
