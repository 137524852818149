import axios from 'axios';

export const API_SERVER_URL = 'https://nms-api.teladin.net';

const setInterceptors = (instance: any) => {
  instance.interceptors.request.use(
    function (config: any) {
      config.headers.Authorization = `Bearer ${sessionStorage.getItem('accessToken')}`;
      return config;
    },
    function (error: any) {
      return Promise.reject(error);
    }
  );
};

const instance = axios.create({
  baseURL: API_SERVER_URL,
});

setInterceptors(instance);

export const axiosInstance = instance;
