import { createContext, useEffect, useState } from 'react';
import { axiosInstance } from '../global/axiosSetting';

type LoginUserType = {
  [props: string]: any;
};

export const LoginUserContext = createContext<LoginUserType>({
  loginUser: {},
});

const LoginUserBoundary = ({ children }: any) => {
  const [loginUser, setLoginUser] = useState({});

  useEffect(() => {
    axiosInstance({
      method: 'get',
      url: '/users/me',
    })
      .then((res) => {
        console.log('/users/me =>', res);
        setLoginUser(res.data);
      })
      .catch((error) => {
        console.log('/users/me error =>', error);
      });
  }, []);

  return <LoginUserContext.Provider value={{ loginUser }}>{children}</LoginUserContext.Provider>;
};

export default LoginUserBoundary;
