import CircleIcon from '@mui/icons-material/Circle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import { Autocomplete, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';
import { green600, muiBorderColor, red600 } from '../../global/css';
import { getMonitoringTableHeader } from '../../global/types';
import { getChidren, getGroupPathName } from '../../global/utils';
import CheckBox from '../CheckBox';
import Loading from '../Loading';
import Popup from '../Popup';
import Table from '../table/Table';
import TablePagination from '../table/TablePagination';
import ManagementGroupSelect from './ManagementGroupSelect';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ManagementDeviceWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: white;
`;

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeviceListWrapper = styled.div``;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  box-sizing: border-box;
  & > div:first-of-type {
    display: flex;
    column-gap: 8px;
  }
  & > div:last-of-type {
    display: flex;
    column-gap: 8px;
  }
`;

const PopupButtonWrapper = styled.div`
  padding-top: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
`;

const EditWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const PopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 300px;
`;

const FilterWrapper = styled.div`
  display: flex;
  padding-bottom: 8px;
  box-sizing: border-box;
  column-gap: 8px;
`;

const ImportPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-width: 480px;
`;

type ManagementDeviceProps = {
  loginUser: { [props: string]: any };
  setMessage: (message: { [props: string]: any }) => void;
  selectedIndex: number;
};

const ManagementDevice = (props: ManagementDeviceProps) => {
  const { loginUser, selectedIndex, setMessage } = props;
  const [deviceList, setDeviceList] = useState<{ [props: string]: any }[]>([]);
  const [editToggle, setEditToggle] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<string[]>([]);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [popupDevice, setPopupDevice] = useState<{ [props: string]: any }>({});
  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  const [groupSelectPopupToggle, setGroupSelectPopupToggle] = useState<boolean>(false);
  const [selectedPopupGroup, setSelectedPopupGroup] = useState<{ [props: string]: any }>({});
  const [tempPopupGroup, setTempPopupGroup] = useState<{ [props: string]: any }>({});

  const [selectedGroup, setSelectedGroup] = useState<{ [props: string]: any }>({});
  const [allGroupList, setAllGroupList] = useState<{ [props: string]: any }[]>([]);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
  const [connectionStatus, setConnectionStatus] = useState<string>('');

  const [tagList, setTagList] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  const [file, setFile] = useState<any>(null);
  const [importPopupToggle, setImportPopupToggle] = useState<boolean>(false);
  const [isTagFetching, setIsTagFetching] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const handleCancelPopupSelectGroup = () => {
    setTempPopupGroup({});
    setGroupSelectPopupToggle(() => false);
  };

  const handleConfirmPopupSelectGroup = () => {
    setSelectedPopupGroup(tempPopupGroup);
    handleCancelPopupSelectGroup();
  };

  const handleCheck = (e: any, name: string) => {
    console.log(e.target.checked);

    if (e.target.checked) {
      setPopupDevice((prev) => ({ ...prev, [name]: undefined, [`${name}Checked`]: true }));
    } else {
      setPopupDevice((prev) => ({ ...prev, [`${name}Checked`]: false }));
    }
  };

  const handlePopup = (type?: string) => {
    if (!!type && type === 'edit') {
      if (!!selectedDevice && !!selectedDevice.length) {
        if (selectedDevice.length > 1) {
          setPopupDevice({ idList: selectedDevice, popupType: 'edit-multi' });
          const selectedG = allGroupList.find((a) => a.id === selectedGroup.id);
          setSelectedPopupGroup({
            ...selectedG,
          });
        } else {
          const selected = deviceList.find((u) => u.id === selectedDevice[0]) || {};
          setPopupDevice({ ...selected, idList: selectedDevice, tags: selected.tags.toString(), popupType: 'edit-single' });
          const selectedG = allGroupList.find((a) => a.id === { ...deviceList.find((u) => u.id === selectedDevice[0]) }.organization_id);
          setSelectedPopupGroup({
            ...selectedG,
          });
        }
      }
    }
    setPopupToggle(() => true);
  };

  const handleDevice = (e: any) => {
    setPopupDevice((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDownloadTemplate = () => {
    axiosInstance({
      method: 'get',
      url: '/devices/excel/template',
    })
      .then((res) => {
        console.log('/devices/template =>', res);
        window.location.href = res.data.url;
      })
      .catch((error) => {
        console.log('/devices/template error =>', error);
      });
  };

  const getTagList = () => {
    setIsTagFetching(() => true);
    setTagList([]);
    axiosInstance({
      method: 'get',
      url: '/devices/tags',
      params: {
        organization_id: selectedGroup.id,
      },
    })
      .then((res) => {
        console.log('/monitoring/tags =>', res);
        setTagList([...res.data]);
      })
      .catch((error) => {
        console.log('/monitoring/tags error =>', error);
      })
      .finally(() => {
        setIsTagFetching(() => false);
      });
  };

  const handleEditDevice = () => {
    let data: { [props: string]: any } = {
      organization_id: selectedPopupGroup.id,
    };

    if (popupDevice.popupType === 'edit-single') {
      data.model_name = popupDevice.model_name;
      data.serial_number = popupDevice.serial_number;
    }

    if (!!popupDevice.tags) {
      data.tags = popupDevice.tags.split(',');
    } else if (!popupDevice.popupType.includes('edit') || !!popupDevice['tagsChecked']) {
      data.tags = [];
    }

    if (!!popupDevice.description1) {
      data.description1 = popupDevice.description1;
    } else if (!popupDevice.popupType.includes('edit') || !!popupDevice['description1Checked']) {
      data.description1 = '';
    }

    if (!!popupDevice.description2) {
      data.description2 = popupDevice.description2;
    } else if (!popupDevice.popupType.includes('edit') || !!popupDevice['description2Checked']) {
      data.description2 = '';
    }

    Promise.all(
      popupDevice.idList.map((id: string) =>
        axiosInstance({
          method: 'patch',
          url: `/devices/${id}`,
          data: data,
        })
      )
    )
      .then((res) => {
        console.log(`patch /devices/ =>`, res);
        setPopupToggle(() => false);
        setMessage({
          message: `${res.length}개의 장치가 수정되었습니다.`,
        });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log(`patch /devices/ error =>`, error);
      });
  };

  const handleDelete = () => {
    setMessage({ message: '장치를 삭제하시겠습니까?', type: 'confirm', confirm: handleDeleteDevice });
  };

  const handleDeleteDevice = () => {
    Promise.all(
      selectedDevice.map((id) => {
        return axiosInstance({
          method: 'delete',
          url: `/devices/${id}`,
        });
      })
    )
      .then((res) => {
        console.log(`delete /devices/ =>`, res);
        setPopupToggle(() => false);
        setMessage({ message: `${res.length}개의 장치가 삭제되었습니다.` });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log(`delete /devices/ error =>`, error);
      });
  };

  const handleSelectGroupPopupOpen = () => {
    if (!!selectedPopupGroup && !!selectedPopupGroup.id) {
      setTempPopupGroup({ ...selectedPopupGroup });
    }
    setGroupSelectPopupToggle(() => true);
  };

  const handleSearch = () => {
    setIsFetching(() => true);

    axiosInstance({
      method: 'get',
      url: '/devices',
      params: {
        organization_id: selectedGroup.id,
        page: pageNum,
        limit: pageSize,
        search: search,
        tag: selectedTag,
        connection_status: !!connectionStatus ? connectionStatus : null,
      },
    })
      .then((res) => {
        console.log('/devices =>', res);
        setAllListCount(res.data.totalCount);
        setDeviceList([...res.data.items]);
      })
      .catch((error) => {
        console.log('/devices error =>', error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });
  };

  const checkEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleFile = (e: any) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const handleExport = () => {
    setDownloadLoading(() => true);
    let params: { [props: string]: any } = {
      organization_id: selectedGroup.id,
      search: search || null,
      tag: selectedTag || null,
      connection_status: !!connectionStatus ? connectionStatus : null,
    };

    axiosInstance({
      method: 'get',
      url: '/devices/export',
      params: params,
      responseType: 'blob',
    })
      .then((res) => {
        console.log('export =>', res);

        const now = new Date();
        const strDate = format(now, 'yyyyMMddHHmmss');

        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        const fileObjectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileObjectUrl;
        link.style.display = 'none';

        link.download = `teladin-device-${!!selectedGroup?.name ? selectedGroup.name : ''}_${strDate}`;

        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(fileObjectUrl);
      })
      .catch((error) => {
        console.log('export error => ', error);
      })
      .finally(() => {
        setDownloadLoading(() => false);
      });
  };

  const handleImport = () => {
    setIsLoading(() => true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('organization_id', selectedPopupGroup.id);
    axiosInstance({
      method: 'post',
      url: '/devices/excel',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        console.log('/devices/excel =>', res);
        setImportPopupToggle(() => false);
        setMessage({ message: '장치가 추가되었습니다.' });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log('/devices/excel error =>', error);
      })
      .finally(() => {
        setIsLoading(() => false);
      });
  };

  const handleAddDevice = () => {
    let data: { [props: string]: any } = {
      model_name: popupDevice.model_name,
      serial_number: popupDevice.serial_number,
      organization_id: selectedPopupGroup.id,
    };

    if (!!popupDevice.tags) {
      data.tags = popupDevice.tags.split(',');
    } else {
      data.tags = [];
    }

    if (!!popupDevice.description1) {
      data.description1 = popupDevice.description1;
    }

    if (!!popupDevice.description2) {
      data.description2 = popupDevice.description2;
    }

    axiosInstance({
      method: 'post',
      url: '/devices',
      data: data,
    })
      .then((res) => {
        console.log('/devices =>', res);
        setPopupToggle(() => false);
        setMessage({ message: '장치가 추가되었습니다.' });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log('/devices error =>', error);
      });
  };

  useEffect(() => {
    if (!!importPopupToggle) return;
    setFile(null);
    setSelectedPopupGroup({});
  }, [importPopupToggle]);

  useEffect(() => {
    if (!popupToggle) {
      setPopupDevice({});
      setSelectedPopupGroup({});
    }
  }, [popupToggle]);

  useEffect(() => {
    if (!editToggle) {
      setSelectedDevice([]);
    }
  }, [editToggle]);

  useEffect(() => {
    setSelectedDevice([]);
  }, [pageNum, pageSize]);

  useEffect(() => {
    setEditToggle(() => false);

    if (!selectedGroup || (!selectedGroup.id && selectedGroup.id !== 0)) return;

    handleSearch();
  }, [refresh, selectedGroup, pageNum, pageSize, selectedTag, connectionStatus]);

  useEffect(() => {
    if (selectedIndex === 0) {
      setRefresh((prev) => !prev);
    } else if (selectedIndex === 1) {
      setDeviceList([]);
      setEditToggle(() => false);

      setSelectedDevice([]);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (!loginUser || (!loginUser.id && loginUser.id !== 0)) return;

    setSelectedGroup({ id: loginUser.organization_id });
  }, [loginUser]);

  useEffect(() => {
    axiosInstance({
      url: '/organizations',
      method: 'GET',
    })
      .then((res) => {
        console.log('/organizations =>', res);
        const arr: { [props: string]: any }[] = [];

        getChidren(res.data, arr);
        setAllGroupList(arr);
      })
      .catch((error) => {
        console.log('/organizations error =>', error);
      });

    //테이블 높이 계산
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
    });

    const savedSelectedGroup = sessionStorage.getItem('manage-device-selectedGroup');
    if (!!savedSelectedGroup) {
      setSelectedGroup(JSON.parse(savedSelectedGroup));
    }

    return () => {
      window.removeEventListener('resize', () => {
        setInnerHeight(window.innerHeight);
      });

      sessionStorage.removeItem('manage-device-selectedGroup');
    };
  }, []);

  useEffect(() => {
    setSearch('');
    setConnectionStatus('');
    setSelectedTag('');
    sessionStorage.setItem('manage-device-selectedGroup', JSON.stringify(selectedGroup));
  }, [selectedGroup]);

  return (
    <>
      <ManagementDeviceWrapper>
        <GroupWrapper>
          <ManagementGroupSelect selected={selectedGroup} setSelected={setSelectedGroup} />
        </GroupWrapper>
        <DeviceListWrapper>
          <TopWrapper>
            <div>
              <Button size='small' variant='contained' onClick={() => handlePopup('add')}>
                장치 추가
              </Button>
              <Button title='import' size='small' color='secondary' variant='contained' onClick={() => setImportPopupToggle(() => true)}>
                <UploadIcon />
              </Button>
              <Button title='export' onClick={handleExport} size='small' variant='contained' color='secondary'>
                <FileDownloadIcon />
              </Button>
            </div>
            <div>
              {!!editToggle ? (
                <React.Fragment>
                  <Button size='small' variant='contained' color='secondary' onClick={() => setEditToggle(() => false)}>
                    취소
                  </Button>
                  <Button onClick={() => handlePopup('edit')} size='small' variant='contained' color='warning' disabled={!selectedDevice || !selectedDevice.length}>
                    수정
                  </Button>
                  <Button onClick={handleDelete} size='small' variant='contained' color='error' disabled={!selectedDevice || !selectedDevice.length}>
                    삭제
                  </Button>
                </React.Fragment>
              ) : (
                <Button size='small' variant='contained' onClick={() => setEditToggle(() => true)}>
                  편집
                </Button>
              )}
            </div>
          </TopWrapper>
          <TableWrapper>
            <FilterWrapper>
              <TextField
                size='small'
                sx={{ minWidth: '560px' }}
                placeholder='검색 (모델명, 일련번호, 설명)'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon sx={{ cursor: 'pointer' }} onClick={handleSearch} />
                    </InputAdornment>
                  ),
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={checkEnter}
              />
              <Autocomplete
                key={selectedGroup.id}
                color='secondary'
                onChange={(_, v: any) => setSelectedTag(v ?? '')}
                sx={{ minWidth: '240px' }}
                size='small'
                options={tagList}
                renderInput={(params: any) => <TextField {...params} label='태그' />}
                loading={isTagFetching}
                onFocus={() => getTagList()}
              />
              <FormControl sx={{ minWidth: '180px' }} size='small'>
                <InputLabel id='connection_status-select'>연결 상태</InputLabel>
                <Select labelId='connection_status-select' value={connectionStatus} label='연결 상태' onChange={(e) => setConnectionStatus(e.target.value)}>
                  <MenuItem value={''}>전체</MenuItem>
                  <MenuItem value={'online'}>온라인</MenuItem>
                  <MenuItem value={'offline'}>오프라인</MenuItem>
                  <MenuItem value={'never'}>미접속</MenuItem>
                </Select>
              </FormControl>
            </FilterWrapper>
            <Table
              tableId='member_table'
              isFetching={isFetching}
              type={!!editToggle ? 'check' : ''}
              header={[
                ...getMonitoringTableHeader([...getMonitoringTableHeader(['model_name', 'serial_number', 'tags', 'description1', 'description2']).map((h) => h.value)], true),
                {
                  title: '등록일',
                  value: 'created_at',
                  index: 11,
                  width: '168px',
                  render: (data: any) => {
                    return !!data.created_at ? format(new Date(data.created_at), 'yyyy.MM.dd HH:mm:ss') : '-';
                  },
                },
                {
                  title: '현재 상태',
                  value: 'next_access_at',
                  width: '96px',
                  index: 0.11,
                  render: (data: any) => {
                    let flag = false;
                    const now = new Date();
                    if (!!data['next_access_at']) {
                      if (new Date(data['next_access_at']) > now) {
                        flag = true;
                      }
                    }

                    return (
                      <div
                        style={{
                          justifyContent: 'center',
                          display: 'flex',
                          width: '100%',
                        }}
                      >
                        {!data['last_access_at'] ? '-' : !!flag ? <CircleIcon sx={{ color: green600 }} /> : <CircleIcon sx={{ color: red600 }} />}
                      </div>
                    );
                  },
                },
              ].sort((a, b) => (a.index < b.index ? -1 : 1))}
              size='small'
              list={[...deviceList]}
              selectedItemList={selectedDevice}
              setSelectedItemList={setSelectedDevice}
              maxHeight={`${innerHeight - 244}px`}
              fullWidth
            />
            <div>
              {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
            </div>
          </TableWrapper>
        </DeviceListWrapper>
      </ManagementDeviceWrapper>
      <Popup toggle={popupToggle} setToggle={setPopupToggle}>
        <PopupContentWrapper>
          {popupDevice.popupType !== 'edit-multi' && (
            <>
              <TextField
                disabled={popupDevice.popupType === 'edit-single'}
                size='small'
                label='모델명'
                name='model_name'
                value={popupDevice.model_name || ''}
                onChange={(e) => handleDevice(e)}
              />
              <TextField
                disabled={popupDevice.popupType === 'edit-single'}
                size='small'
                label='일련번호'
                name='serial_number'
                value={popupDevice.serial_number || ''}
                onChange={(e) => handleDevice(e)}
              />
            </>
          )}
          <EditWrapper>
            <TextField
              fullWidth
              size='small'
              label='태그'
              placeholder=',로 구분하여 입력'
              name='tags'
              value={popupDevice.tags || ''}
              disabled={!!popupDevice.popupType && popupDevice.popupType.includes('edit') && !!popupDevice['tagsChecked']}
              onChange={(e) => {
                if (/^[^\s]*$/.test(e.target.value)) {
                  handleDevice(e);
                }
              }}
            />
            {!!popupDevice.popupType && popupDevice.popupType.includes('edit') && (
              <CheckBox onClick={(e) => handleCheck(e, 'tags')} selected={!!popupDevice['tagsChecked']} checkBoxStyle={{ paddingLeft: '8px' }} size='small' label='제거' />
            )}
          </EditWrapper>
          <EditWrapper>
            <TextField
              fullWidth
              size='small'
              label='장치 설명 1'
              disabled={!!popupDevice.popupType && popupDevice.popupType.includes('edit') && !!popupDevice['description1Checked']}
              name='description1'
              value={popupDevice.description1 || ''}
              onChange={(e) => handleDevice(e)}
            />

            {!!popupDevice.popupType && popupDevice.popupType.includes('edit') && (
              <CheckBox
                onClick={(e) => handleCheck(e, 'description1')}
                selected={!!popupDevice['description1Checked']}
                checkBoxStyle={{ paddingLeft: '8px' }}
                size='small'
                label='제거'
              />
            )}
          </EditWrapper>
          <EditWrapper>
            <TextField
              fullWidth
              size='small'
              label='장치 설명 2'
              disabled={!!popupDevice.popupType && popupDevice.popupType.includes('edit') && !!popupDevice['description2Checked']}
              name='description2'
              value={popupDevice.description2 || ''}
              onChange={(e) => handleDevice(e)}
            />
            {!!popupDevice.popupType && popupDevice.popupType.includes('edit') && (
              <CheckBox
                onClick={(e) => handleCheck(e, 'description2')}
                selected={!!popupDevice['description2Checked']}
                checkBoxStyle={{ paddingLeft: '8px' }}
                size='small'
                label='제거'
              />
            )}
          </EditWrapper>

          <Button onClick={handleSelectGroupPopupOpen} sx={{ borderColor: muiBorderColor }} variant='outlined' color='secondary'>
            {!!selectedPopupGroup && (!!selectedPopupGroup.id || selectedPopupGroup.id === 0) && !!getGroupPathName(selectedPopupGroup, allGroupList)
              ? getGroupPathName(selectedPopupGroup, allGroupList)
              : '조직 선택'}
          </Button>

          <PopupButtonWrapper>
            <Button variant='contained' size='medium' color='secondary' onClick={() => setPopupToggle(() => false)}>
              취소
            </Button>
            {!!popupDevice.popupType && popupDevice.popupType.includes('edit') ? (
              <Button
                onClick={handleEditDevice}
                disabled={!popupDevice || (!selectedPopupGroup.id && selectedPopupGroup.id !== 0)}
                color='warning'
                variant='contained'
                size='medium'
              >
                수정
              </Button>
            ) : (
              <Button
                disabled={!popupDevice || !popupDevice.model_name || !popupDevice.serial_number || (!selectedPopupGroup.id && selectedPopupGroup.id !== 0)}
                variant='contained'
                size='medium'
                onClick={handleAddDevice}
              >
                추가
              </Button>
            )}
          </PopupButtonWrapper>
        </PopupContentWrapper>
      </Popup>
      <Popup toggle={importPopupToggle} setToggle={setImportPopupToggle}>
        <ImportPopupWrapper>
          <Button onClick={handleSelectGroupPopupOpen} sx={{ borderColor: muiBorderColor }} variant='outlined' color='secondary'>
            {!!selectedPopupGroup && (!!selectedPopupGroup.id || selectedPopupGroup.id === 0) && !!getGroupPathName(selectedPopupGroup, allGroupList)
              ? getGroupPathName(selectedPopupGroup, allGroupList)
              : '조직 선택'}
          </Button>
          <Button component='label' variant='outlined' size='medium' startIcon={!!file ? undefined : <CloudUploadIcon />}>
            {!!file ? file.name : '파일 선택'}
            <VisuallyHiddenInput onChange={handleFile} accept='.xlsx' type='file' />
          </Button>
        </ImportPopupWrapper>
        <PopupButtonWrapper>
          <Button variant='outlined' size='medium' color='secondary' onClick={handleDownloadTemplate}>
            템플릿 다운로드
          </Button>
          <Button variant='contained' size='medium' color='secondary' onClick={() => setImportPopupToggle(() => false)}>
            취소
          </Button>
          <Button onClick={handleImport} variant='contained' size='medium' disabled={!file || !selectedPopupGroup || !selectedPopupGroup.id}>
            확인
          </Button>
        </PopupButtonWrapper>
      </Popup>
      <Popup toggle={groupSelectPopupToggle} setToggle={setGroupSelectPopupToggle}>
        <ManagementGroupSelect selected={tempPopupGroup} setSelected={setTempPopupGroup} />
        <PopupButtonWrapper>
          <Button variant='contained' size='medium' color='secondary' onClick={handleCancelPopupSelectGroup}>
            취소
          </Button>
          <Button variant='contained' size='medium' onClick={handleConfirmPopupSelectGroup}>
            확인
          </Button>
        </PopupButtonWrapper>
      </Popup>
      <Modal open={isLoading} onClose={() => setIsLoading(() => false)}>
        <>
          <Loading />
        </>
      </Modal>
      <Modal open={downloadLoading}>
        <>
          <Loading />
        </>
      </Modal>
    </>
  );
};

export default ManagementDevice;
