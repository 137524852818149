import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/images/Logo.svg";
import { green600, grey200, grey600, subtitle1 } from "../global/css";
import { LoginUserContext } from "../hooks/LoginUserHook";
import Popup from "./Popup";

const DashboardNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${grey200};
  background-color: white;
  height: 64px;
  padding: 0 16px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
  z-index: 100;
  & > img {
    width: 40px;
  }
  & > div {
    display: flex;
    column-gap: 40px;
    align-items: center;
    height: 100%;
  }
`;

const NavListWrapper = styled.div`
  display: flex;
  column-gap: 32px;
  align-items: center;
  height: 100%;
  padding: 12px 0;
  box-sizing: border-box;
`;

const NavItemWrapper = styled.div<{ $active: boolean }>`
  ${subtitle1};
  color: ${(props) => (!!props.$active ? green600 : grey600)};
  cursor: pointer;
  padding: 0 8px;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  align-items: center;
`;

const LogoutPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  & > p {
    ${subtitle1};
  }
  & > div {
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
    width: 100%;
  }
`;

const DashboardNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { loginUser } = useContext(LoginUserContext);

  const [logoutToggle, setLogoutToggle] = useState<boolean>(false);

  const goTo = (path: string) => {
    navigate(path);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  const clickLogout = () => {
    setLogoutToggle(() => true);
  };

  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (!accessToken) {
      handleLogout();
    }
  }, [pathname]);

  return (
    <React.Fragment>
      <DashboardNavigationWrapper>
        <img
          alt="logo"
          src={Logo}
          onClick={() => goTo("/dashboard")}
          style={{ cursor: "pointer" }}
        />

        <div>
          <NavListWrapper>
            {navList
              .filter((n) =>
                loginUser.permission === "admin" ? true : !n.isAdmin
              )
              .filter((n) =>
                loginUser.organization_id === 1 ? true : !n.isAdminGroup
              )
              .map((nav, index) => {
                return (
                  <NavItemWrapper
                    onClick={() => goTo(nav.path)}
                    $active={pathname === nav.path}
                    key={index}
                  >
                    {nav.name}
                  </NavItemWrapper>
                );
              })}
          </NavListWrapper>
          <Button
            onClick={clickLogout}
            variant="contained"
            size="small"
            color="secondary"
          >
            로그아웃
          </Button>
        </div>
      </DashboardNavigationWrapper>
      <Popup toggle={logoutToggle} setToggle={setLogoutToggle} canClose>
        <LogoutPopupContent>
          <p>로그아웃 하시겠습니까?</p>
          <div>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              sx={{ width: "100%" }}
              onClick={() => setLogoutToggle(() => false)}
            >
              취소
            </Button>
            <Button
              variant="contained"
              size="medium"
              sx={{ width: "100%" }}
              onClick={handleLogout}
            >
              확인
            </Button>
          </div>
        </LogoutPopupContent>
      </Popup>
    </React.Fragment>
  );
};

export default DashboardNavigation;

const navList = [
  {
    name: "대시보드",
    path: "/dashboard",
  },
  {
    name: "모니터링",
    path: "/dashboard/monitoring",
  },
  {
    name: "그룹 제어",
    path: "/dashboard/control",
    isAdmin: true,
  },
  {
    name: "FOTA",
    path: "/dashboard/fota",
    isAdminGroup: true,
  },
  // {
  //   name: '라우터 관리',
  //   path: '/dashboard/router',
  // },
  {
    name: "관리",
    path: "/dashboard/management",
  },
];
