import * as React from 'react';
import { TablePagination as MuiTablePagenation } from '@mui/material';

type TablePaginationProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  count: number;
  unit?: string;
  fixedRowPerPage?: number;
};

const TablePagination = (props: TablePaginationProps) => {
  const { page, setPage, rowsPerPage, setRowsPerPage, count, unit = '개', fixedRowPerPage } = props;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <MuiTablePagenation
      size='small'
      labelRowsPerPage='페이지 당 행'
      component='div'
      count={count}
      rowsPerPageOptions={!!fixedRowPerPage ? [fixedRowPerPage] : [10, 50, 100, 200, 500]}
      page={page - 1}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      showFirstButton
      showLastButton
      labelDisplayedRows={({ from, to, count }) => `총 ${count}${unit} 중 ${from}~${to}`}
    />
  );
};

export default TablePagination;
