import { ThemeProvider, createTheme } from '@mui/material';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { green600, grey050, grey700 } from './global/css';
import './reset.css';
import './font.css';
import './index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: green600,
      contrastText: grey050,
    },
    secondary: {
      main: grey700,
      contrastText: grey050,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
