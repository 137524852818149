import { Checkbox, FormControlLabel } from '@mui/material';

type CheckBoxProps = {
  selected?: boolean;
  onClick?: (e: any) => void;
  disabled?: boolean;
  label?: string;
  size?: 'small' | 'medium';
  labelStyle?: any;
  checkBoxStyle?: any;
};

const CheckBox = (props: CheckBoxProps) => {
  const { checkBoxStyle = {}, labelStyle = {}, selected, onClick, disabled, label = '', size = 'medium' } = props;

  const handleClick = (e: any) => {
    if (!!e) e.stopPropagation();
    if (!!onClick) onClick(e);
  };

  return (
    <FormControlLabel
      label={label}
      sx={{
        ...checkBoxStyle,
        '& .MuiFormControlLabel-label': {
          minWidth: 'fit-content',
          ...labelStyle,
        },
      }}
      control={<Checkbox size={size} disabled={!!disabled} checked={!!selected} onClick={!!disabled ? (e: any) => e.stopPropagation() : handleClick} />}
    />
  );
};

export default CheckBox;
