import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { add, format, set } from 'date-fns';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import styled from 'styled-components';
import Table from '../../components/table/Table';
import TablePagination from '../../components/table/TablePagination';
import { axiosInstance } from '../../global/axiosSetting';
import { body2, caption2, green600, grey200, grey600, grey700, grey900, muiBorderColor, subtitle1, subtitle2 } from '../../global/css';
import { getMonitoringTableHeader } from '../../global/types';
import { getCampaignStatus } from '../../global/utils';
import DatePicker from '../DatePicker';
import Popup from '../Popup';
import Slideup from '../Slideup';
import TabBox from '../TabBox';
import Tag from '../Tag';
import TagSelectBox from '../TagSelectBox';
import TimePicker from '../TimePicker';
import CampaignDetail from './CampaignDetail';
import CampaignSelectDevice from './CampaignSelectDevice';
import DiffPackage from './DiffPackage';
import FullPackage from './FullPackage';

const Campaign = () => {
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);

  const [search, setSearch] = useState<string>('');
  const [campaignList, setCampaignList] = useState<{ [props: string]: any }[]>([]);

  const [addPopupToggle, setAddPopupToggle] = useState<boolean>(false);

  const [campaignName, setCampaignName] = useState<string>('');
  const [selectedDiff, setSelectedDiff] = useState<{ [props: string]: any }>({});
  const [selectedFull, setSelectedFull] = useState<{ [props: string]: any }>({});
  const [selectedDateRange, setSelectedDateRange] = useState<[Range]>([{}]);
  const [status, setStatus] = useState<boolean>(false);
  const [campaignDescription, setCampaignDescription] = useState<string>('');

  const [selectPackagePopupToggle, setSelectPackagePopupToggle] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<{ [props: string]: any }>({});

  const [selectFullPopupToggle, setSelectFullPopupToggle] = useState<boolean>(false);
  const [selectedFullTemp, setSelectedFullTemp] = useState<{ [props: string]: any }>({});

  const [selectTagPopupToggle, setSelectTagPopupToggle] = useState<boolean>(false);
  const [temptagList, setTempTagList] = useState<string[]>([]);
  const [selectedTagList, setSelectedTagList] = useState<string[]>([]);

  const [selectedOrganization, setSelectedOrganization] = useState<string>('');

  const [allDeviceListCount, setAllDeviceListCount] = useState<number>(0);
  const [organizationList, setOrganizationList] = useState<{ [props: string]: any }[]>([]);
  const [deviceList, setDeviceList] = useState<{ [props: string]: any }[]>([]);
  const [devicePageNum, setDevicePageNum] = useState<number>(1);
  const [devicePageSize, setDevicePageSize] = useState<number>(10);

  const [selectedTableItem, setSelectedTableItem] = useState<string>('');
  const [slideToggle, setSlideToggle] = useState<boolean>(false);

  const [upDownStatus, setUpDownStatus] = useState<number>(0);

  const [editPopupToggle, setEditPopupToggle] = useState<boolean>(false);
  const [deletePopupToggle, setDeletePopupToggle] = useState<boolean>(false);
  const [editData, setEditData] = useState<{ [props: string]: any }>({});
  const [selectedEditDateRange, setSelectedEditDateRange] = useState<[Range]>([{}]);

  const [addType, setAddType] = useState<number>(0);
  const [selectDeviceToggle, setSelectDeviceToggle] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<{ [props: string]: any }[]>([]);
  const [selectedTempDevice, setSelectedTempDevice] = useState<{ [props: string]: any }[]>([]);

  const [modelNameList, setModelNameList] = useState<string[]>([]);
  const [selectedModelName, setSelectedModelName] = useState<string>('');
  const [startTime, setStartTime] = useState<{ hour?: number; minute?: number }>({});
  const [endTime, setEndTime] = useState<{ hour?: number; minute?: number }>({});
  const [editStartTime, setEditStartTime] = useState<{ hour?: number; minute?: number }>({});
  const [editEndTime, setEditEndTime] = useState<{ hour?: number; minute?: number }>({});

  const handleAddType = (index: number) => {
    setAddType(index);
  };

  const handleRowClick = (item: any) => {
    setSelectedTableItem(() => item.id);
    setSlideToggle(() => true);
  };

  const handleUpDownStatus = (status: number) => {
    setUpDownStatus(() => status);
  };

  const handleDelete = (id: string) => {
    axiosInstance({
      method: 'delete',
      url: `/devices/campaigns/${id}`,
    })
      .then((res) => {
        console.log('delete res =>', res);
        handleSearch(true);
      })
      .catch((error) => {
        console.log('delete error =>', error);
      })
      .finally(() => {
        setDeletePopupToggle(false);
      });
  };

  const handleEdit = () => {
    if (!editData || !editData.id) return;
    const data = {
      name: editData.name,
      description: editData.description || null,
      available: editData.available,
      start_at: !!selectedEditDateRange[0].startDate
        ? set(new Date(selectedEditDateRange[0].startDate), {
            hours: editStartTime.hour,
            minutes: editStartTime.minute,
            seconds: 0,
            milliseconds: 0,
          }).toISOString()
        : null,
      end_at: !!selectedEditDateRange[0].endDate
        ? set(new Date(selectedEditDateRange[0].endDate), {
            hours: editEndTime.hour,
            minutes: editEndTime.minute,
            seconds: 0,
            milliseconds: 0,
          }).toISOString()
        : null,
    };

    axiosInstance({
      method: 'patch',
      url: `/devices/campaigns/${editData.id}`,
      data: data,
    })
      .then((res) => {
        console.log('/devices/campaigns =>', res);
        handleSearch(true);
      })
      .catch((error) => {
        console.log('/devices/campaigns error =>', error);
      })
      .finally(() => {
        setEditPopupToggle(false);
      });
  };

  const handleEditDate = (item: Range) => {
    setSelectedEditDateRange(() => [item]);
  };

  const handleEditData = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === 'available') {
      value = e.target.value === 'true';
    }

    setEditData((prev) => ({ ...prev, [name]: value }));
  };

  const editClick = () => {
    const selected = campaignList.find((c) => c.id === Number(selectedTableItem));
    if (!selected) return;
    console.log('edit =>', selected);
    setEditData(selected);
    setSelectedEditDateRange(
      selected.start_at && selected.end_at
        ? [
            {
              startDate: new Date(selected.start_at),
              endDate: new Date(selected.end_at),
              key: 'selection',
            },
          ]
        : [{}]
    );
    setEditStartTime({
      hour: new Date(selected.start_at).getHours(),
      minute: new Date(selected.start_at).getMinutes(),
    });
    setEditEndTime({
      hour: new Date(selected.end_at).getHours(),
      minute: new Date(selected.end_at).getMinutes(),
    });
  };

  useEffect(() => {
    if (!editData || !editData.id) return;
    setEditPopupToggle(true);
  }, [editData]);

  useEffect(() => {
    if (!!editPopupToggle) return;
    setEditData({});
    setSelectedEditDateRange([{}]);
    setEditStartTime({});
    setEditEndTime({});
  }, [editPopupToggle]);

  useEffect(() => {
    if (!slideToggle) {
      setSelectedTableItem('');
    }
  }, [slideToggle]);

  useEffect(() => {
    if (!!addPopupToggle) return;
    setSelectedDiff({});
    setSelectedFull({});
    setSelectedModelName('');
    setSelectedDateRange([
      {
        startDate: new Date(),
        endDate: add(new Date(), { days: 7 }),
        key: 'selection',
      },
    ]);
    setStatus(false);
    setSelectedTagList([]);
    setCampaignDescription('');
    setDeviceList([]);
    setOrganizationList([]);
    setAllDeviceListCount(0);
    setSelectedOrganization('');
    setDevicePageSize(10);
    setDevicePageNum(1);
    setTempTagList([]);
    setCampaignName('');
    setAddType(0);
    setSelectedDevice([]);
    setSelectedTempDevice([]);
    setStartTime({});
    setEndTime({});
  }, [addPopupToggle]);

  useEffect(() => {
    setDeviceList([]);
    setAllDeviceListCount(0);
    setDevicePageNum(1);
  }, [selectedOrganization]);

  useEffect(() => {
    if (!selectedTagList.length || !selectedDiff || !selectedDiff.id) return;

    axiosInstance({
      method: 'get',
      url: `/devices/package-diffs/${selectedDiff.id}/devices`,
      params: {
        tags: selectedTagList.toString(),
        page: devicePageNum,
        limit: devicePageSize,
        organization_id: !!selectedOrganization ? Number(selectedOrganization) : null,
      },
    })
      .then((res) => {
        console.log('/devices/search Diff =>', res);
        setDeviceList([...res.data.items]);
        setAllDeviceListCount(res.data.totalCount);
        setOrganizationList(res.data.organizations);
      })
      .catch((error) => {
        console.log('/devices/search Diff error =>', error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });
  }, [selectedTagList, devicePageNum, devicePageSize, selectedOrganization, selectedDiff]);
  useEffect(() => {
    if (!selectedTagList.length || !selectedFull || !selectedFull.id) return;

    axiosInstance({
      method: 'get',
      url: `/devices/packages/${selectedFull.id}/devices`,
      params: {
        tags: selectedTagList.toString(),
        page: devicePageNum,
        limit: devicePageSize,
        organization_id: !!selectedOrganization ? Number(selectedOrganization) : null,
      },
    })
      .then((res) => {
        console.log('/devices/search Full =>', res);
        setDeviceList([...res.data.items]);
        setAllDeviceListCount(res.data.totalCount);
        setOrganizationList(res.data.organizations);
      })
      .catch((error) => {
        console.log('/devices/search Full error =>', error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });
  }, [selectedTagList, devicePageNum, devicePageSize, selectedOrganization, selectedFull]);

  useEffect(() => {
    if (!!selectPackagePopupToggle) {
    } else {
      setSelectedPackage({});
    }
  }, [selectPackagePopupToggle]);
  useEffect(() => {
    if (!!selectFullPopupToggle) {
    } else {
      setSelectedFullTemp({});
    }
  }, [selectFullPopupToggle]);

  const handleDate = (item: Range) => {
    setSelectedDateRange(() => [item]);
  };

  const handleResetSelectPackage = () => {
    setSelectedDiff({});

    setSelectPackagePopupToggle(false);
  };
  const handleResetSelectFull = () => {
    setSelectedFull({});

    setSelectFullPopupToggle(false);
  };

  const handleSelectPackage = () => {
    setSelectedDiff(selectedPackage);
    setSelectPackagePopupToggle(false);
  };
  const handleSelectFull = () => {
    setSelectedFull(selectedFullTemp);
    setSelectFullPopupToggle(false);
  };

  const handleSearch = (reset?: boolean) => {
    setSlideToggle(() => false);

    if (isFetching) return;

    if (!!reset) {
      if (pageNum !== 1) {
        setPageNum(1);
        return;
      }
    }

    setIsFetching(true);
    axiosInstance({
      method: 'get',
      url: 'devices/campaigns',
      params: {
        page: pageNum,
        limit: pageSize,
        search: search,
      },
    })
      .then((res) => {
        console.log('devices/campaigns =>', res);
        setCampaignList([...res.data.items]);
        setAllListCount(res.data.totalCount);
      })
      .catch((error) => {
        console.log('devices/campaigns error =>', error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const checkEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch(true);
    }
  };

  const handleAddCampaign = () => {
    const data: { [props: string]: any } = {
      name: campaignName,
      description: campaignDescription || null,
      available: status,
      start_at: !!selectedDateRange[0].startDate
        ? set(new Date(selectedDateRange[0].startDate), {
            hours: startTime.hour,
            minutes: startTime.minute,
            seconds: 0,
            milliseconds: 0,
          }).toISOString()
        : null,
      end_at: !!selectedDateRange[0].endDate
        ? set(new Date(selectedDateRange[0].endDate), {
            hours: endTime.hour,
            minutes: endTime.minute,
            seconds: 0,
            milliseconds: 0,
          }).toISOString()
        : null,
      organization_id: !!selectedOrganization ? Number(selectedOrganization) : null,
    };

    if (selectedDiff && selectedDiff.id) {
      data.package_diff_id = selectedDiff.id;
    } else if (selectedFull && selectedFull.id) {
      data.package_id = selectedFull.id;
    }

    if (addType === 0) {
      data.target_tags = selectedTagList;
    } else if (addType === 1) {
      data.target_device_ids = selectedDevice.map((d) => d.id);
    }

    console.log(data);

    axiosInstance({
      method: 'post',
      url: '/devices/campaigns',
      data: data,
    })
      .then((res) => {
        console.log('/devices/campaigns =>', res);
        handleSearch(true);
      })
      .catch((error) => {
        console.log('/devices/campaigns error =>', error);
      })
      .finally(() => {
        setAddPopupToggle(() => false);
      });
  };

  useEffect(() => {
    handleSearch();
  }, [pageNum, pageSize]);

  useEffect(() => {
    axiosInstance({
      method: 'get',
      url: '/monitoring/model-names',
    })
      .then((res) => {
        console.log('/monitoring/model-names =>', res);
        setModelNameList([...res.data]);
      })
      .catch((error) => {
        console.log('/monitoring/model-names error =>', error);
      });

    //테이블 높이 계산
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setInnerHeight(window.innerHeight);
      });
    };
  }, []);

  useEffect(() => {
    setSelectedDevice([]);
    setSelectedTempDevice([]);
    setAllDeviceListCount(0);
    setDevicePageNum(1);
    setDevicePageSize(10);
    setSelectedTagList([]);
    setOrganizationList([]);
    setSelectedOrganization('');
  }, [addType]);

  return (
    <CampaignWrapper>
      <SearchWrapper>
        <TextField
          size='small'
          sx={{ minWidth: '555px' }}
          placeholder='검색 (모델명, 버전, 설명)'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ cursor: 'pointer' }} onClick={() => handleSearch(true)} />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          onKeyDown={checkEnter}
        />
        <div>
          <Button title='새로고침' onClick={() => handleSearch()} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <RefreshIcon />
          </Button>
          {!!selectedTableItem && (
            <>
              <Button title='수정' onClick={editClick} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
                수정
              </Button>
              <Button title='삭제' onClick={() => setDeletePopupToggle(true)} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
                삭제
              </Button>
            </>
          )}
          <Button title='캠페인 추가' onClick={() => setAddPopupToggle(true)} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            캠페인 추가
          </Button>
        </div>
      </SearchWrapper>
      <TableWrapper>
        <Table
          tableId='fota_campaign_table'
          fullWidth
          isFetching={isFetching}
          maxHeight={`${innerHeight}px`}
          onRowClick={handleRowClick}
          selectedItemList={[selectedTableItem]}
          size='small'
          dataId='id'
          list={campaignList}
          header={[...campaignTableHeader]}
        />
        <div>
          {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
        </div>
      </TableWrapper>

      <Popup toggle={addPopupToggle} setToggle={setAddPopupToggle}>
        <AddPopupContentWrapper>
          <div>
            <TextField
              fullWidth
              size='small'
              label='캠페인명 *'
              name='campaignName'
              value={campaignName || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setCampaignName(e.target.value)}
            />
            <Autocomplete
              color='secondary'
              onChange={(_, v: any) => setSelectedModelName(v ?? '')}
              sx={{ minWidth: '220px' }}
              size='small'
              options={modelNameList}
              renderInput={(params: any) => <TextField {...params} label='모델명 *' />}
            />
            {!selectedModelName ? (
              <Button fullWidth disabled sx={{ borderColor: muiBorderColor }} variant='outlined' color='secondary'>
                패키지 선택
              </Button>
            ) : (
              <>
                {selectedModelName.toLocaleLowerCase() === 'tc720' ? (
                  <FullWrapper>
                    <Button fullWidth onClick={() => setSelectFullPopupToggle(true)} sx={{ borderColor: muiBorderColor }} variant='outlined' color='secondary'>
                      {!!selectedFull && !!selectedFull.id ? selectedFull.version : `패키지(Full) 선택 *`}
                    </Button>
                    {!!selectedFull && !!selectedFull.id && <span>{selectedFull.description || '-'}</span>}
                  </FullWrapper>
                ) : (
                  <DiffWrapper>
                    <Button fullWidth onClick={() => setSelectPackagePopupToggle(true)} sx={{ borderColor: muiBorderColor }} variant='outlined' color='secondary'>
                      {!!selectedDiff && !!selectedDiff.id ? selectedDiff.from_package.version + ' > ' + selectedDiff.to_package.version : `패키지(Diff) 선택 *`}
                    </Button>
                    {!!selectedDiff && !!selectedDiff.id && <span>{selectedDiff.description || '-'}</span>}
                  </DiffWrapper>
                )}
              </>
            )}
            <DatePicker onChange={handleDate} defaultValue={selectedDateRange[0]} placeholder='시작일 ~ 종료일 선택 *' color='secondary' noMaxDate />
            <TimeWrapper>
              <div>
                <TimePicker label='시작 시간 *' onChange={setStartTime} value={startTime} />
              </div>
              <div>
                <TimePicker label='종료 시간 *' onChange={setEndTime} value={endTime} />
              </div>
            </TimeWrapper>
            <TextField fullWidth size='small' label='설명' name='description' value={campaignDescription || ''} onChange={(e) => setCampaignDescription(e.target.value)} />
            <RadioGroup row aria-label='status' name='status' value={status} onChange={(e: any) => setStatus(e.target.value)}>
              <FormControlLabel style={{ color: grey600 }} value={true} control={<Radio size='small' />} label='활성' />
              <FormControlLabel style={{ color: grey600 }} value={false} control={<Radio size='small' />} label='비활성' />
            </RadioGroup>

            <AddTypeWrapper>
              <TabBox
                defaultValue={addType}
                onChange={handleAddType}
                dataId='add'
                size='small'
                dataList={[
                  {
                    index: 0,
                    title: '태그',
                  },
                  {
                    index: 1,
                    title: '장치',
                  },
                ]}
              >
                <div tabIndex={addType}>
                  <AddTypeButtonWrapper>
                    {addType === 0 && (
                      <Button
                        style={{ gap: '4px', display: 'flex', flexWrap: 'wrap' }}
                        fullWidth
                        onClick={() => setSelectTagPopupToggle(true)}
                        sx={{ borderColor: muiBorderColor }}
                        variant='outlined'
                        color='secondary'
                        disabled={(!selectedDiff || !selectedDiff.id) && (!selectedFull || !selectedFull.id)}
                      >
                        {!!selectedTagList && !!selectedTagList.length ? selectedTagList.map((t) => <Tag key={t} text={t} />) : '태그 선택 *'}
                      </Button>
                    )}
                    {addType === 1 && (
                      <Button
                        onClick={() => {
                          setSelectedTempDevice([...selectedDevice]);
                          setSelectDeviceToggle(true);
                        }}
                        fullWidth
                        disabled={(!selectedDiff || !selectedDiff.id) && (!selectedFull || !selectedFull.id)}
                        variant='outlined'
                        color='secondary'
                      >
                        {!selectedDevice || !selectedDevice.length ? '장치 선택 *' : '장치 선택'}
                      </Button>
                    )}
                  </AddTypeButtonWrapper>
                </div>
              </TabBox>
            </AddTypeWrapper>

            <OrganizationWrapper>
              <OrganizationSummaryWrapper>
                <div>
                  {addType === 0 && (
                    <React.Fragment>
                      <p>
                        <span>{selectedTagList.length}</span>
                        개의 태그
                      </p>
                      ,&nbsp;
                    </React.Fragment>
                  )}
                  <p>
                    <span>{addType === 0 ? allDeviceListCount : selectedDevice.length}</span>
                    개의 장치
                  </p>
                  &nbsp;선택됨
                </div>
              </OrganizationSummaryWrapper>

              {/* addType이 tag일때만 표시 */}
              {addType === 0 && (
                <SelectOrganizationWrapper>
                  <FormControl disabled={!selectedTagList.length} sx={{ minWidth: '150px' }} size='small'>
                    <InputLabel id='control-organization-select'>조직 선택</InputLabel>
                    <Select labelId='control-organization-select' value={selectedOrganization} label='조직 선택' onChange={(e) => setSelectedOrganization(e.target.value)}>
                      <MenuItem value={''}>전체</MenuItem>
                      {organizationList.map((organization: any, index: number) => {
                        return (
                          <MenuItem key={index} value={organization.id}>
                            {organization.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </SelectOrganizationWrapper>
              )}
              {/*  */}
              <DeviceTableWrapper>
                <Table
                  checkType='add'
                  tableId='campaign_group_control_table'
                  fullWidth
                  isFetching={isFetching}
                  size='small'
                  dataId='id'
                  list={
                    addType === 0
                      ? [...deviceList]
                      : addType === 1
                      ? [...selectedDevice.filter((_, i) => i + 1 <= devicePageNum * devicePageSize && i + 1 > (devicePageNum - 1) * devicePageSize)]
                      : []
                  }
                  noDataText={
                    addType === 0
                      ? !!selectedTagList.length
                        ? ''
                        : '태그를 먼저 선택해주세요.'
                      : addType === 1
                      ? !!selectedDevice.length
                        ? ''
                        : '장치를 먼저 선택해주세요.'
                      : ''
                  }
                  header={[
                    ...getMonitoringTableHeader(
                      [...getMonitoringTableHeader().filter((h) => !deviceTableHeaderList.includes(h.value))].map((h) => h.value),
                      true
                    ),
                    {
                      title: '조직',
                      value: 'organization',
                      width: '232px',
                      index: 10,
                    },
                  ].sort((a, b) => (a.index < b.index ? -1 : 1))}
                />
                <div>
                  {!!allListCount && (
                    <TablePagination
                      count={addType === 0 ? allDeviceListCount : addType === 1 ? selectedDevice.length : 0}
                      page={devicePageNum}
                      rowsPerPage={devicePageSize}
                      setPage={setDevicePageNum}
                      setRowsPerPage={setDevicePageSize}
                    />
                  )}
                </div>
              </DeviceTableWrapper>
            </OrganizationWrapper>
          </div>

          <div>
            <div></div>
            <Button color={'secondary'} variant='contained' size='medium' onClick={() => setAddPopupToggle(() => false)}>
              취소
            </Button>

            <Button
              disabled={
                ((!selectedDiff || !selectedDiff.id) && (!selectedFull || !selectedFull.id)) ||
                !campaignName ||
                !selectedDateRange ||
                !selectedDateRange.length ||
                !selectedDateRange[0].startDate ||
                !selectedDateRange[0].endDate ||
                (addType === 0 ? !selectedTagList.length : false) ||
                (addType === 1 ? !selectedDevice.length : false) ||
                (!endTime?.hour && endTime.hour !== 0) ||
                (!startTime?.hour && startTime.hour !== 0)
              }
              variant='contained'
              size='medium'
              onClick={handleAddCampaign}
            >
              확인
            </Button>
          </div>
        </AddPopupContentWrapper>
      </Popup>
      <Popup toggle={editPopupToggle} setToggle={setEditPopupToggle}>
        {!!editData && !!editData.id && (
          <EditPopupWrapper>
            <div>
              <TextField fullWidth size='small' label='캠페인명 *' name='name' value={editData.name || ''} onChange={handleEditData} />
              <TextField fullWidth size='small' label='설명' name='description' value={editData.description || ''} onChange={handleEditData} />
              <DatePicker onChange={handleEditDate} defaultValue={selectedEditDateRange[0]} placeholder='시작일 ~ 종료일 선택 *' color='secondary' noMaxDate />
              <TimeWrapper>
                <div>
                  <TimePicker label='시작 시간 *' onChange={setEditStartTime} value={editStartTime} />
                </div>
                <div>
                  <TimePicker label='종료 시간 *' onChange={setEditEndTime} value={editEndTime} />
                </div>
              </TimeWrapper>
              <RadioGroup row aria-label='available' name='available' value={!!editData?.available} onChange={handleEditData}>
                <FormControlLabel style={{ color: grey600 }} value={true} control={<Radio size='small' />} label='활성' />
                <FormControlLabel style={{ color: grey600 }} value={false} control={<Radio size='small' />} label='비활성' />
              </RadioGroup>
            </div>
            <div>
              <Button color={'secondary'} variant='contained' size='medium' onClick={() => setEditPopupToggle(() => false)}>
                취소
              </Button>

              <Button
                disabled={
                  editData.name === '' ||
                  !selectedEditDateRange ||
                  !selectedEditDateRange.length ||
                  !selectedEditDateRange[0].startDate ||
                  !selectedEditDateRange[0].endDate ||
                  (!editEndTime?.hour && editEndTime.hour !== 0) ||
                  (!editStartTime?.hour && editStartTime.hour !== 0)
                }
                variant='contained'
                size='medium'
                onClick={handleEdit}
              >
                확인
              </Button>
            </div>
          </EditPopupWrapper>
        )}
      </Popup>
      <Popup toggle={!!selectPackagePopupToggle} setToggle={setSelectPackagePopupToggle}>
        <SelectPackagePopupWrapper>
          <DiffPackage isPopup itemList={[selectedDiff.id]} setItemList={setSelectedPackage} />
        </SelectPackagePopupWrapper>
        <PopupButtonWrapper>
          <div>
            {!!selectedDiff?.id && (
              <Button variant='contained' size='medium' color='info' onClick={handleResetSelectPackage}>
                선택해제
              </Button>
            )}
          </div>
          <div>
            <Button variant='contained' size='medium' color='secondary' onClick={() => setSelectPackagePopupToggle(false)}>
              취소
            </Button>
            <Button disabled={!selectedPackage || !selectedPackage.id} variant='contained' size='medium' onClick={handleSelectPackage}>
              확인
            </Button>
          </div>
        </PopupButtonWrapper>
      </Popup>
      <Popup toggle={!!selectFullPopupToggle} setToggle={setSelectFullPopupToggle}>
        <SelectPackagePopupWrapper>
          <FullPackage isPopup search='TC720' itemList={[selectedFull.id]} setItemList={setSelectedFullTemp} />
        </SelectPackagePopupWrapper>
        <PopupButtonWrapper>
          <div>
            {!!selectedFull?.id && (
              <Button variant='contained' size='medium' color='info' onClick={handleResetSelectFull}>
                선택해제
              </Button>
            )}
          </div>
          <div>
            <Button variant='contained' size='medium' color='secondary' onClick={() => setSelectFullPopupToggle(false)}>
              취소
            </Button>
            <Button disabled={!selectedFullTemp || !selectedFullTemp.id} variant='contained' size='medium' onClick={handleSelectFull}>
              확인
            </Button>
          </div>
        </PopupButtonWrapper>
      </Popup>
      <Popup toggle={selectTagPopupToggle} setToggle={setSelectTagPopupToggle}>
        <SelectTagPopupWrapper>
          <TagSelectBox selectedTagList={temptagList} setSelectedTagList={setTempTagList} />
        </SelectTagPopupWrapper>
        <PopupButtonWrapper>
          <div>
            {!!selectedTagList.length && (
              <Button
                variant='contained'
                size='medium'
                color='info'
                onClick={() => {
                  setSelectedTagList([]);
                  setTempTagList([]);
                  setSelectTagPopupToggle(false);
                  setDeviceList([]);
                  setAllDeviceListCount(0);
                }}
              >
                선택해제
              </Button>
            )}
          </div>
          <div>
            <Button
              variant='contained'
              size='medium'
              color='secondary'
              onClick={() => {
                setTempTagList([...selectedTagList]);
                setSelectTagPopupToggle(false);
              }}
            >
              취소
            </Button>
            <Button
              variant='contained'
              size='medium'
              onClick={() => {
                setSelectedTagList([...temptagList]);
                setSelectTagPopupToggle(false);
              }}
            >
              확인
            </Button>
          </div>
        </PopupButtonWrapper>
      </Popup>
      <Popup toggle={selectDeviceToggle} setToggle={setSelectDeviceToggle}>
        <CampaignSelectDevice modelName={selectedDiff?.from_package?.model_name || selectedFull?.model_name} selected={selectedTempDevice} setSelected={setSelectedTempDevice} />
        <PopupButtonWrapper>
          <div>
            {!!selectedDevice && !!selectedDevice.length && (
              <Button
                variant='contained'
                size='medium'
                color='info'
                onClick={() => {
                  setDevicePageNum(() => 1);
                  setSelectedDevice([]);
                  setSelectDeviceToggle(() => false);
                  setSelectedTempDevice([]);
                }}
              >
                선택해제
              </Button>
            )}
          </div>
          <div>
            <Button
              variant='contained'
              size='medium'
              color='secondary'
              onClick={() => {
                setSelectedTempDevice([]);
                setSelectDeviceToggle(false);
              }}
            >
              취소
            </Button>
            <Button
              variant='contained'
              size='medium'
              onClick={() => {
                setSelectedDevice([...selectedTempDevice]);
                setSelectedTempDevice([]);
                setSelectDeviceToggle(false);
              }}
            >
              확인
            </Button>
          </div>
        </PopupButtonWrapper>
      </Popup>
      <Popup toggle={deletePopupToggle} setToggle={setDeletePopupToggle}>
        {!!campaignList && !!campaignList.find((c) => c.id === Number(selectedTableItem))?.id && (
          <DeletePopupContentWrapper>
            <div>
              <p>캠페인명: {!!campaignList.find((c) => c.id === Number(selectedTableItem))?.id ? campaignList.find((c) => c.id === Number(selectedTableItem))?.name : '-'}</p>
              <p>캠페인을 삭제하시겠습니까?</p>
            </div>
            <div>
              <Button color={'secondary'} variant='contained' size='medium' onClick={() => setDeletePopupToggle(() => false)}>
                취소
              </Button>

              <Button variant='contained' size='medium' onClick={() => handleDelete(selectedTableItem)} color='error'>
                확인
              </Button>
            </div>
          </DeletePopupContentWrapper>
        )}
      </Popup>

      <Slideup onChangeStatus={handleUpDownStatus} toggle={slideToggle} setToggle={setSlideToggle} hasBackWrapper={false}>
        {selectedTableItem && <CampaignDetail upDownStatus={upDownStatus} campaignId={selectedTableItem} />}
      </Slideup>
    </CampaignWrapper>
  );
};
const campaignTableHeader = [
  {
    title: '캠페인명',
    value: 'name',
    width: '160px',
    sort: false,
    index: 0,
  },
  {
    title: '태그',
    value: 'target_tags',
    width: '220px',
    sort: false,
    index: 1,
    render: (data: any) => {
      return data['target_tags']?.join(', ') || '-';
    },
  },
  {
    title: '베이스 버전',
    value: 'from_package.version',
    width: '220px',
    sort: false,
    index: 2,
    render: (data: any) => {
      return !!data.package_diff && !!data.package_diff.from_package ? data.package_diff.from_package.version : '-';
    },
  },
  {
    title: '새로운 버전',
    value: 'to_package.version',
    width: '220px',
    sort: false,
    index: 3,
    render: (data: any) => {
      if (!!data.package_id) {
        return !!data.package ? data.package.version : '-';
      } else {
        return !!data.package_diff && !!data.package_diff.to_package ? data.package_diff.to_package.version : '-';
      }
    },
  },
  {
    title: '상태',
    value: 'available',
    width: '80px',
    sort: false,
    index: 4,
    render: (data: any) => {
      return getCampaignStatus(data);
    },
  },

  {
    title: '설명',
    value: 'description',
    width: '440px',
    sort: false,
    index: 5,
  },
  {
    title: '기간',
    value: 'period',
    sort: false,
    width: '200px',
    render: (data: any) => {
      let str = '';
      if (!!data['start_at']) {
        str += format(new Date(data['start_at']), 'yyyy.MM.dd');
      }
      str += ' ~ ';
      if (!!data['end_at']) {
        str += format(new Date(data['end_at']), 'yyyy.MM.dd');
      }
      return str;
    },
    index: 6,
  },
];

const deviceTableHeaderList = ['model_name', 'serial_number', 'organization', 'tags'];

export default Campaign;

const CampaignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid ${grey200};
  row-gap: 8px;
  width: calc(100% - 8px);
`;

const SearchWrapper = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    column-gap: 8px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddPopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 640px;
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  & > div:nth-of-type(2) {
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
    width: 100%;
    justify-content: flex-end;
  }
`;

const SelectPackagePopupWrapper = styled.div``;

const PopupButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  box-sizing: border-box;
  & > div {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
  }
`;

const SelectTagPopupWrapper = styled.div`
  width: 520px;
`;

const OrganizationWrapper = styled.div``;

const OrganizationSummaryWrapper = styled.div`
  background-color: white;
  position: sticky;
  top: 64px;
  width: 100%;
  z-index: 4;
  padding: 16px 0 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    ${subtitle2};
    /* color: ${grey600}; */
    & > p {
      ${subtitle2};
      color: ${grey900};
      & > span {
        ${subtitle2};
        color: ${green600};
      }
    }
  }
`;

const SelectOrganizationWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 8px;
  box-sizing: border-box;
`;

const DeviceTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
`;

const DeletePopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    & > p {
      ${body2};
      &:last-of-type {
        padding: 8px 0 0;
        ${subtitle1};
      }
    }
  }
  & > div:nth-of-type(2) {
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
  }
`;

const EditPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 640px;
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  & > div:nth-of-type(2) {
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
    width: 100%;
    justify-content: flex-end;
  }
`;

const DiffWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > span {
    display: flex;
    padding: 2px 0 0 8px;
    box-sizing: border-box;
    ${caption2};
    color: ${grey700};
  }
`;
const FullWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > span {
    display: flex;
    padding: 2px 0 0 8px;
    box-sizing: border-box;
    ${caption2};
    color: ${grey700};
  }
`;

const AddTypeWrapper = styled.div``;

const AddTypeButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 0 0;
  box-sizing: border-box;
`;

const TimeWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: space-between;
  & > div {
    justify-content: center;
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
  }
`;
