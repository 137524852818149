import MainRouter from './routers/MainRouter';

function App() {
  if (process.env.NODE_ENV === 'production') {
    console = window.console || {};
    console.log = function no_console() {};
    console.warn = function no_console() {};
    console.error = function () {};
  }

  return <MainRouter />;
}

export default App;
