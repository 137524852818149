import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, InputAdornment, Modal, TextField } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from '../../components/table/Table';
import TablePagination from '../../components/table/TablePagination';
import { axiosInstance } from '../../global/axiosSetting';
import { body2, grey200, muiBorderColor, subtitle1 } from '../../global/css';
import Popup from '../Popup';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Loading from '../Loading';

type FullPackageProps = {
  isPopup?: boolean;
  itemList?: string[];
  setItemList?: React.Dispatch<React.SetStateAction<any>>;
  modelName?: string;
  search?: string;
};

const FullPackage = ({ isPopup, setItemList, modelName, itemList = [], search: searchProp }: FullPackageProps) => {
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);

  const [search, setSearch] = useState<string>(searchProp || '');
  const [fotaList, setFotaList] = useState<{ [props: string]: any }[]>([]);

  const [selectedPackage, setSelectedPackage] = useState<any>();
  const [deletePopupToggle, setDeletePopupToggle] = useState<boolean>(false);

  const [modelNameList, setModelNameList] = useState<string[]>([]);
  const [addPopupToggle, setAddPopupToggle] = useState<boolean>(false);

  const [addPackage, setAddPackage] = useState<{ [props: string]: any }>({});
  const [selectedModelName, setSelectedModelName] = useState<string>('');
  const [file, setFile] = useState<any>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  //
  const [itemIdList, setItemIdList] = useState<string[]>([...itemList]);

  useEffect(() => {
    if (!!setItemList) {
      setItemList(fotaList.find((item: any) => itemIdList.includes(item.id)));
    }
  }, [itemIdList]);

  //

  const handleFile = (e: any) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const handleSearch = (reset?: boolean) => {
    if (isFetching) return;
    if (!!reset) {
      if (pageNum !== 1) {
        setPageNum(1);
        return;
      }
    }
    setIsFetching(true);
    axiosInstance({
      method: 'get',
      url: 'devices/packages',
      params: {
        page: pageNum,
        limit: pageSize,
        search: search,
        model_name: !!modelName ? modelName : null,
      },
    })
      .then((res) => {
        console.log('devices/packaged =>', res);
        setFotaList([...res.data.items]);
        setAllListCount(res.data.totalCount);
      })
      .catch((error) => {
        console.log('devices/packaged error =>', error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handlePackage = (e: any) => {
    setAddPackage((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDelete = (id: number) => {
    axiosInstance({
      method: 'delete',
      url: `/devices/packages/${id}`,
    })
      .then((res) => {
        console.log(`/devices/packages/${id} =>`, res);
        handleSearch();
      })
      .catch((error) => {
        console.log(`/devices/packages/${id} error =>`, error);
      })
      .finally(() => {
        setDeletePopupToggle(() => false);
      });
  };

  const checkEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch(true);
    }
  };

  const handleAddPackage = () => {
    if (isUploading) return;
    const formData = new FormData();
    formData.append('model_name', selectedModelName);
    formData.append('version', addPackage.version);
    formData.append('description', addPackage.description);
    formData.append('file', file);

    setIsUploading(true);

    axiosInstance({
      method: 'post',
      url: '/devices/packages',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        console.log('devices/packages =>', res);
        handleSearch(true);
      })
      .catch((error) => {
        console.log('devices/packages error =>', error);
      })
      .finally(() => {
        setAddPopupToggle(() => false);
        setIsUploading(false);
      });
  };

  useEffect(() => {
    if (!selectedPackage || !selectedPackage.id) return;
    setDeletePopupToggle(() => true);
  }, [selectedPackage]);

  useEffect(() => {
    if (!!deletePopupToggle) return;
    setSelectedPackage({});
  }, [deletePopupToggle]);

  useEffect(() => {
    if (!!addPopupToggle) {
      axiosInstance({
        method: 'get',
        url: '/monitoring/model-names',
      })
        .then((res) => {
          console.log('/monitoring/model-names =>', res);
          setModelNameList([...res.data]);
        })
        .catch((error) => {
          console.log('/monitoring/model-names error =>', error);
        });
    } else {
      setAddPackage({});
      setSelectedModelName('');
      setFile(null);
    }
  }, [addPopupToggle]);

  useEffect(() => {
    handleSearch();
  }, [pageNum, pageSize]);

  useEffect(() => {
    //테이블 높이 계산
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setInnerHeight(window.innerHeight);
      });
    };
  }, []);

  return (
    <FullPackageWrapper $fullWidth={!isPopup}>
      <SearchWrapper>
        <TextField
          size='small'
          sx={{ minWidth: '555px' }}
          placeholder='검색 (모델명, 버전, 설명)'
          disabled={!!searchProp}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={!!searchProp ? {} : { cursor: 'pointer' }} onClick={!!searchProp ? () => {} : () => handleSearch(true)} />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={checkEnter}
        />
        <div>
          <Button title='새로고침' onClick={() => handleSearch()} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <RefreshIcon />
          </Button>
          <Button title='패키지 추가' onClick={() => setAddPopupToggle(true)} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            패키지 추가
          </Button>
        </div>
      </SearchWrapper>
      <TableWrapper>
        <Table
          type={isPopup ? 'radio' : ''}
          tableId='fota_full_table'
          fullWidth
          isFetching={isFetching}
          maxHeight={`${innerHeight}px`}
          setSelectedItemList={setItemIdList}
          selectedItemList={itemIdList}
          size='small'
          dataId='id'
          list={fotaList}
          header={
            !isPopup
              ? [
                  ...fotaTableHeader,
                  {
                    title: '',
                    value: 'edit',
                    width: '200px',
                    sort: false,
                    index: 4,
                    render: (data: any) => {
                      return (
                        <TableButtonWrapper>
                          <Button
                            size='small'
                            variant='outlined'
                            color='secondary'
                            onClick={() => {
                              window.open(data.url, '_blank');
                            }}
                          >
                            다운로드
                          </Button>
                          <Button size='small' variant='outlined' color='secondary' onClick={() => setSelectedPackage(data)}>
                            삭제
                          </Button>
                        </TableButtonWrapper>
                      );
                    },
                  },
                ]
              : fotaTableHeader
          }
        />
        <div>
          {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
        </div>
      </TableWrapper>

      <Popup toggle={deletePopupToggle} setToggle={setDeletePopupToggle}>
        <DeletePopupContentWrapper>
          <div>
            <p>모델명: {selectedPackage?.model_name}</p>
            <p>버전: {selectedPackage?.version}</p>
            <p>패키지(Full)를 삭제하시겠습니까?</p>
          </div>
          <div>
            <Button color={'secondary'} variant='contained' size='medium' onClick={() => setDeletePopupToggle(() => false)}>
              취소
            </Button>

            <Button variant='contained' size='medium' onClick={() => handleDelete(selectedPackage?.id)} color='error'>
              확인
            </Button>
          </div>
        </DeletePopupContentWrapper>
      </Popup>
      <Popup toggle={addPopupToggle} setToggle={setAddPopupToggle}>
        <AddPopupContentWrapper>
          <div>
            <Autocomplete
              onChange={(_, v: any) => setSelectedModelName(v ?? '')}
              sx={{ minWidth: '140px' }}
              size='small'
              options={modelNameList}
              renderInput={(params: any) => <TextField {...params} label='모델명' />}
            />
            <TextField fullWidth size='small' label='버전' name='version' value={addPackage.version || ''} onChange={(e) => handlePackage(e)} />
            <TextField fullWidth size='small' label='설명' name='description' value={addPackage.description || ''} onChange={(e) => handlePackage(e)} />
            <Button fullWidth component='label' variant='outlined' size='medium' startIcon={!!file ? undefined : <CloudUploadIcon />}>
              {!!file ? file.name : '파일 선택'}
              <VisuallyHiddenInput onChange={handleFile} accept='.zip,.trx' type='file' key={file?.name} />
            </Button>
          </div>
          <div>
            <Button color={'secondary'} variant='contained' size='medium' onClick={() => setAddPopupToggle(() => false)}>
              취소
            </Button>

            <Button
              disabled={!selectedModelName || !addPackage?.version || !addPackage?.description || !file || !file.name}
              variant='contained'
              size='medium'
              onClick={handleAddPackage}
            >
              확인
            </Button>
          </div>
        </AddPopupContentWrapper>
      </Popup>
      <Modal open={isUploading}>
        <>
          <Loading />
        </>
      </Modal>
    </FullPackageWrapper>
  );
};

const fotaTableHeader = [
  {
    title: '모델명',
    value: 'model_name',
    width: '104px',
    sort: false,
    index: 0,
  },
  {
    title: '버전',
    value: 'version',
    width: '220px',
    sort: false,
    index: 1,
  },
  {
    title: '설명',
    value: 'description',
    width: '440px',
    sort: false,
    index: 2,
  },
  {
    title: '등록일',
    value: 'created_at',
    sort: false,
    width: '168px',
    render: (data: any) => {
      return !!data.created_at ? format(new Date(data.created_at), 'yyyy.MM.dd HH:mm:ss') : '-';
    },
    index: 3,
  },
];

export default FullPackage;

const FullPackageWrapper = styled.div<{ $fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid ${grey200};
  row-gap: 8px;
  width: ${(props) => (!!props.$fullWidth ? 'calc(100% - 8px)' : '996px')};
`;

const SearchWrapper = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    column-gap: 8px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const DeletePopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    & > p {
      ${body2};
      &:last-of-type {
        padding: 8px 0 0;
        ${subtitle1};
      }
    }
  }
  & > div:nth-of-type(2) {
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
  }
`;

const AddPopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 254px;
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  & > div:nth-of-type(2) {
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
    width: 100%;
    justify-content: flex-end;
  }
`;
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
