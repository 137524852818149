import { Autocomplete, Button, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';
import { subtitle1 } from '../../global/css';
import Popup from '../Popup';
import DataLimitBox from '../deviceCommandControls/DataLimitBox';
import DefaultDeviceCommandControl from '../deviceCommandControls/DefaultDeviceCommandControl';
import LTEBandBox from '../deviceCommandControls/LTEBandBox';
import { checkIsNumber, checkKeyDown, checkValidate } from '../../global/utils';

const MonitoringDetailControlWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px;
  box-sizing: border-box;
  flex-direction: column;
`;

const PopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  & > p {
    ${subtitle1};
  }
`;

const PopupButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  justify-content: center;
`;

type MonitoringDetailControlProps = {
  data: { [props: string]: any };
  setRefresh: Function;
};

const MonitoringDetailControl = (props: MonitoringDetailControlProps) => {
  const { data, setRefresh } = props;

  const [controlTypeList, setControlTypeList] = useState<{ [props: string]: any }[]>([]);
  const [selectedControlType, setSelectedControlType] = useState<{ [props: string]: any }>({});

  const [param1, setParam1] = useState<string>('');
  const [param2, setParam2] = useState<string>('');
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const [popupToggle, setPopupToggle] = useState<boolean>(false);

  const handleControlSubmit = () => {
    let dataParams: { [props: string]: any } = {
      command_type: selectedControlType.type,
    };

    if (selectedControlType.param_count === 1) {
      dataParams.parameter1 = param1;
    } else if (selectedControlType.param_count === 2) {
      dataParams.parameter1 = param1;
      dataParams.parameter2 = param2;
    }

    console.log(dataParams);

    axiosInstance({
      method: 'post',
      url: `/control/${data.device_id}/commands`,
      data: {
        ...dataParams,
      },
    })
      .then((res) => {
        console.log(`/control/commands =>`, res);
        setParam1('');
        setParam2('');
      })
      .catch((error) => {
        console.log(`/control/commands error =>`, error);
      })
      .finally(() => {
        setPopupToggle(() => false);
        setRefresh((prev: boolean) => !prev);
      });
  };

  const handleWebUI = (type: boolean) => {
    setParam1(!!type ? '1' : '0');

    setPopupToggle(() => true);
  };

  useEffect(() => {
    if (!data || !data.model_name) return;
    axiosInstance({
      method: 'get',
      url: `/control/commands/types`,
      params: {
        model_name: data.model_name,
      },
    })
      .then((res) => {
        console.log(`/control/commands/types =>`, res);
        setControlTypeList([...res.data]);
      })
      .catch((error) => {
        console.log(`/control/commands/types error =>`, error);
      });
  }, [data]);

  useEffect(() => {
    setParam1('');
    setParam2('');
    setCanSubmit(() => false);
  }, [selectedControlType]);

  const deviceCommandControlMap: { [props: string]: any } = useMemo(() => {
    return {
      reboot: {
        type: null,
      },
      modem_at_command: {
        type: 'default',
        param1Title: '명령어 입력',
      },
      linux_command: {
        type: 'default',
        param1Title: '명령어 입력',
      },
      select_lte_band: {
        type: 'lteBand',
      },
      run_sh_file: {
        type: 'default',
        param1Title: 'sh 파일 다운로드 URL',
        param2Title: 'sh 파일 사이즈(바이트)',
      },
      connect_rcs: {
        type: 'default',
        param1Title: 'IP 주소',
        param2Title: 'Port 번호',
        onKeyDown2: checkIsNumber,
        onCheck1: (v: string) => checkValidate(v, /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/),
        onKeyDown1: (e: any) => checkKeyDown(e, /^[0-9.]*$/),
      },
      change_monitoring_periods: {
        type: 'default',
        param1Title: '보고 주기 분(1-1440)',
        onKeyDown1: checkIsNumber,
        onCheck1: (v: string) => checkValidate(v, /^([1-9][0-9]{0,2}|1[0-3][0-9]{2}|14[0-3][0-9]|1440)$/),
        valueType: 'number',
      },
      open_web_ui: {
        customButton: () => {
          return (
            <Button sx={{ width: '104px' }} variant='contained' onClick={() => handleWebUI(!data.web_ui_enabled)}>
              {!!data.web_ui_enabled ? '비활성화' : '활성화'}
            </Button>
          );
        },
      },
      change_monitoring_server: {
        type: 'default',
        param1Title: '관제 서버 URL',
      },
      change_data_limit: {
        type: 'dataLimit',
      },
      reset_data_usage: {
        type: null,
      },
    };
  }, [data.web_ui_enabled]);

  return (
    <MonitoringDetailControlWrapper>
      <Autocomplete
        onChange={(_, v: any) => setSelectedControlType(v ?? {})}
        sx={{ minWidth: '240px', width: '240px' }}
        size='small'
        options={controlTypeList.filter((c) => (data.web_ui_enabled === null ? c.type !== 'open_web_ui' : c))}
        getOptionLabel={(option) => option.title}
        renderInput={(params: any) => <TextField {...params} label='제어 명령' />}
        isOptionEqualToValue={(option, value) => option.type === value.type}
      />

      {deviceCommandControlMap[selectedControlType.type]?.type === 'default' ? (
        <DefaultDeviceCommandControl
          setParam1={setParam1}
          setParam2={setParam2}
          setCanSubmit={setCanSubmit}
          param1Title={deviceCommandControlMap[selectedControlType.type].param1Title}
          param2Title={deviceCommandControlMap[selectedControlType.type].param2Title}
          param1={param1}
          param2={param2}
          onCheck1={deviceCommandControlMap[selectedControlType.type]?.onCheck1}
          onCheck2={deviceCommandControlMap[selectedControlType.type]?.onCheck2}
          onKeyDown1={deviceCommandControlMap[selectedControlType.type]?.onKeyDown1}
          onKeyDown2={deviceCommandControlMap[selectedControlType.type]?.onKeyDown2}
          valueType1={deviceCommandControlMap[selectedControlType.type]?.valueType || 'string'}
          valueType2={deviceCommandControlMap[selectedControlType.type]?.valueType || 'string'}
        />
      ) : deviceCommandControlMap[selectedControlType.type]?.type === 'dataLimit' ? (
        <DataLimitBox setCanSubmit={setCanSubmit} setParam1={setParam1} />
      ) : deviceCommandControlMap[selectedControlType.type]?.type === 'lteBand' ? (
        <LTEBandBox modelName={data.model_name} setCanSubmit={setCanSubmit} setParam1={setParam1} />
      ) : (
        <React.Fragment />
      )}

      {!!selectedControlType && !!selectedControlType.type && (
        <>
          {!!deviceCommandControlMap[selectedControlType.type] && !!deviceCommandControlMap[selectedControlType.type].customButton ? (
            <>{deviceCommandControlMap[selectedControlType.type].customButton()}</>
          ) : (
            <Button
              disabled={!(!!deviceCommandControlMap[selectedControlType.type]?.type ? !!canSubmit : true)}
              sx={{ width: '104px' }}
              variant='contained'
              onClick={() => setPopupToggle(() => true)}
            >
              등록
            </Button>
          )}
        </>
      )}
      <Popup toggle={popupToggle} setToggle={setPopupToggle}>
        <PopupContentWrapper>
          <p>제어 명령을 등록하시겠습니까?</p>
          <PopupButtonWrapper>
            <Button size='large' variant='contained' color='secondary' onClick={() => setPopupToggle(() => false)}>
              취소
            </Button>
            <Button size='large' variant='contained' onClick={handleControlSubmit}>
              확인
            </Button>
          </PopupButtonWrapper>
        </PopupContentWrapper>
      </Popup>
    </MonitoringDetailControlWrapper>
  );
};

export default MonitoringDetailControl;
