export const red100 = '#FFF9F9';
export const red200 = '#FFE8E8';
export const red400 = '#FF6E6E';
export const red600 = '#EB1010';

export const green100 = '#F3FFF9';
export const green200 = '#DBFFED';
export const green400 = '#3FF19C';
export const green600 = '#0CAF74';

export const blue100 = '#EDF7FF';
export const blue200 = '#BFE0FF';
export const blue400 = '#52A6F5';
export const blue600 = '#0063BF';

export const grey050 = '#FAFCFF';
export const grey100 = '#F6F9FF';
export const grey200 = '#E8EEF5';
export const grey300 = '#D3DDE6';
export const grey400 = '#AEBAC6';
export const grey500 = '#8F99A8';
export const grey600 = '#78828E';
export const grey700 = '#5D616F';
export const grey800 = '#363E4F';
export const grey900 = '#191E39';

export const muiBorderColor = 'rgba(0, 0, 0, 0.23)';

// font
export const headline1 = `
    font-weight: 600;
    font-size: 28px;
    letter-spacing: 0px;
    line-height: 32px;
`;
export const headline2 = `
    font-weight: 600;
    font-size : 20px;
    letter-spacing: 0px;
    line-height : 24px;
`;
export const subtitle1 = `
    font-weight: 600;
    font-size : 17px;
    letter-spacing: 0px;
    line-height : 24px;
`;
export const subtitle2 = `
    font-weight: 600;
    font-size : 15px;
    letter-spacing: 0px;
    line-height : 20px;
`;
export const body1 = `
    font-weight: 400;
    font-size : 17px;
    letter-spacing: -0.2px;
    line-height : 24px;
`;
export const body2 = `
    font-weight: 400;
    font-size : 16px;
    letter-spacing: -0.2px;
    line-height : 24px;
`;
export const body3 = `
    font-weight: 400;
    font-size : 14px;
    letter-spacing: -0.2px;
    line-height : 24px;
`;
export const muiBody3 = {
  fontWeight: 400,
  fontSize: '14px',
  letterSpacing: '-0.2px',
  lineHeight: '24px',
};

export const caption1 = `
    font-weight: 500;
    font-size : 14px;
    letter-spacing: 0px;
    line-height : 18px;
`;
export const caption2 = `
    font-weight: 400;
    font-size : 13px;
    letter-spacing: 0px;
    line-height : 18px;
`;
export const caption3 = `
    font-weight: 400;
    font-size : 12px;
    letter-spacing: 0px;
    line-height : 18px;
`;
export const tableHeadline = `
    font-weight: 600;
    font-size : 13px;
    letter-spacing: 0px;
    line-height : 16px;
`;
export const tableBody = `
    font-weight: 500;
    font-size : 15px;
    letter-spacing: -0.2px;
    line-height : 20px;
`;

export const elevation = `
    box-shadow: 0px 57px 23px rgba(143, 153, 168, 0.01), 0px 32px 19px rgba(143, 153, 168, 0.04), 0px 14px 14px rgba(143, 153, 168, 0.07), 0px 4px 8px rgba(143, 153, 168, 0.08), 0px 0px 0px rgba(143, 153, 168, 0.08);
`;
