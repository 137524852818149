import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from '../DatePicker';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Range } from 'react-date-range';
import { add, format } from 'date-fns';
import { axiosInstance } from '../../global/axiosSetting';
import Table from '../table/Table';
import TablePagination from '../table/TablePagination';
import { muiBorderColor } from '../../global/css';

const MonitoringEventHistoryWrapper = styled.div`
  display: flex;
  & > div {
    display: flex;
    flex-direction: column;
    width: fit-content;
    row-gap: 8px;
    box-sizing: border-box;
    padding: 8px;
  }
`;

type MonitoringEventHistoryProps = {
  data: any;
  upDownStatus: string;
};

const MonitoringEventHistory = (props: MonitoringEventHistoryProps) => {
  const { data, upDownStatus } = props;
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);
  const [selectedDateRange, setSelectedDateRange] = useState<[Range]>([
    {
      startDate: add(new Date(), { months: -1 }),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [historyList, setHistoryList] = useState<{ [props: string]: any }[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const handleDate = (item: Range) => {
    setSelectedDateRange(() => [item]);
  };

  const handleSearch = (newPage?: boolean) => {
    if (isFetching) return;
    setIsFetching(() => true);

    let params: { [props: string]: any } = {
      page: pageNum,
      limit: pageSize,
    };

    if (newPage) {
      params.page = 1;
      setPageNum(1);
    }

    if (!!selectedDateRange[0].startDate) {
      params.from = format(new Date(selectedDateRange[0].startDate), 'yyyy-MM-dd');
    }

    if (!!selectedDateRange[0].endDate) {
      params.to = format(new Date(selectedDateRange[0].endDate), 'yyyy-MM-dd');
    }

    axiosInstance({
      method: 'get',
      url: `/monitoring/devices/${data.device_id}/error-events`,
      params: params,
    })
      .then((res) => {
        console.log(`/monitoring/devices/${data.device_id}/error-events =>`, res);
        setHistoryList([...res.data.items]);
        setAllListCount(res.data.totalCount);
      })
      .catch((error) => {
        console.log(`/monitoring/devices/${data.device_id}/error-events error =>`, error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });
  };

  useEffect(() => {
    if (!data || !data.device_id) return;
    handleSearch();
  }, [data, pageNum, pageSize]);

  useEffect(() => {
    handleSearch(true);
  }, [selectedDateRange]);

  return (
    <MonitoringEventHistoryWrapper>
      <div>
        <TopWrapper>
          <div />
          <div>
            <DatePicker defaultValue={selectedDateRange[0]} onChange={handleDate} size='medium' color='secondary' />

            <Button onClick={() => handleSearch()} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
              <RefreshIcon />
            </Button>
          </div>
        </TopWrapper>
        <BottomWrapper>
          <Table isFetching={isFetching} tableId='monitoring_detail_event_history_table' maxHeight='' size='small' dataId='id' list={historyList} header={errorEventTableHeader} />
          <div>
            {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
          </div>
        </BottomWrapper>
      </div>
    </MonitoringEventHistoryWrapper>
  );
};

export default MonitoringEventHistory;

const errorEventTableHeader = [
  {
    title: '이벤트 타입',
    value: 'event_type',
    width: '480px',
  },
  {
    title: '발생 시점',
    value: 'created_at',
    width: '200px',
    render: (data: any) => {
      return format(new Date(data['created_at']), 'yyyy.MM.dd HH:mm:ss');
    },
  },
];

///

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:last-of-type {
    display: flex;
    column-gap: 8px;
  }
`;
const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;
