import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { grey050, grey100, grey400, grey600, muiBorderColor } from '../global/css';

const SlideupWrapper = styled.div<{ $hasBackWrapper: boolean; $toggle: boolean; $height: string; $upToggle: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  height: ${(props) => (!!props.$hasBackWrapper || !!props.$upToggle ? '100vh' : 'fit-content')};
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transform: ${(props) => (!!props.$toggle ? 'translateY(0)' : 'translateY(100%)')};
  transition: transform 0.3s;
  z-index: 1001;
  & > div {
    border: 1px solid ${muiBorderColor};
    border-bottom: none;
    transition: all 0.3s;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: ${(props) => props.$height};
    background-color: white;
    padding: 8px 20px 20px;
    box-sizing: border-box;
    border-radius: 16px 16px 0 0;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid ${muiBorderColor};
  & > svg {
    color: ${muiBorderColor};
  }
  &:hover {
    border-color: ${grey400};
    background-color: ${grey050};
    & > svg {
      color: ${grey400};
    }
  }
  &:active {
    border-color: ${grey600};
    background-color: ${grey100};
    & > svg {
      color: ${grey600};
    }
  }
`;

const CloseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
  right: -12px;
  & > svg {
    color: ${muiBorderColor};
    width: 32px;
    height: 32px;
  }
  &:hover {
    border-color: ${grey400};
    background-color: ${grey050};
    & > svg {
      color: ${grey400};
    }
  }
  &:active {
    border-color: ${grey600};
    background-color: ${grey100};
    & > svg {
      color: ${grey600};
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
`;

type SlideupProps = {
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  hasBackWrapper?: boolean;
  onChangeStatus?: (status: number) => void;
};

const Slideup = (props: SlideupProps) => {
  const { onChangeStatus, toggle, setToggle, children, hasBackWrapper = true } = props;

  const [upToggle, setUpToggle] = useState<boolean>(false);

  const [resizeToggle, _setResizeToggle] = useState<boolean>(false);

  const setResizeToggle = useRef(_setResizeToggle);

  useEffect(() => {
    if (!!toggle) return;

    setUpToggle(() => false);
  }, [toggle]);

  useEffect(() => {
    if (!!onChangeStatus) onChangeStatus(!!upToggle ? window.innerHeight - 64 : window.innerHeight / 2);
  }, [upToggle, resizeToggle]);

  useEffect(() => {
    const handleResize = () => {
      setResizeToggle.current((prev) => !prev);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <SlideupWrapper
      $hasBackWrapper={hasBackWrapper}
      $upToggle={!!upToggle}
      onClick={!!hasBackWrapper ? () => setToggle(() => false) : () => setUpToggle(() => false)}
      $toggle={toggle}
      $height={!!upToggle ? `${window.innerHeight - 64}px` : `${window.innerHeight / 2}px`}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <TopWrapper>
          <ArrowWrapper onClick={() => setUpToggle((prev) => !prev)}>{!!upToggle ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</ArrowWrapper>
          <CloseWrapper onClick={() => setToggle(() => false)}>
            <CancelIcon />
          </CloseWrapper>
        </TopWrapper>
        <ContentWrapper>{children}</ContentWrapper>
      </div>
    </SlideupWrapper>
  );
};

export default Slideup;
