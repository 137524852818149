import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { body2, green600, grey200, grey400, grey600, subtitle2 } from '../global/css';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { axiosInstance } from '../global/axiosSetting';

const TagSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
  border: 1px solid ${grey200};
  padding: 12px 4px 12px 12px;
  box-sizing: border-box;
  border-radius: 16px;
  height: fit-content;
  & > span {
    display: flex;
    ${subtitle2};
  }
`;

type TagSelectBoxProps = {
  selectedTagList: string[];
  setSelectedTagList: Function;
  refresh?: boolean;
};

const TagSelectBox = ({ selectedTagList, setSelectedTagList, refresh }: TagSelectBoxProps) => {
  const [search, setSearch] = useState<string>('');
  const [tagList, setTagList] = useState<string[]>([]);

  const handleTag = (tag: string) => {
    if (selectedTagList.includes(tag)) {
      setSelectedTagList([...selectedTagList.filter((t) => t !== tag)]);
    } else {
      setSelectedTagList([...selectedTagList, tag]);
    }
  };

  useEffect(() => {
    axiosInstance({
      method: 'get',
      url: '/monitoring/tags',
    })
      .then((res) => {
        console.log('/monitoring/tags =>', res);
        setTagList([...res.data]);
      })
      .catch((error) => {
        console.log('/monitoring/tags error =>', error);
      });
  }, [refresh]);

  return (
    <TagSelectBoxWrapper>
      <LeftTopWrapper>
        <span>태그 목록</span>
        <TextField
          size='small'
          placeholder='태그 검색'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ cursor: 'pointer' }} />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </LeftTopWrapper>

      <TagListWrapper>
        {tagList
          .filter((t) => t.includes(search))
          .map((tag, i) => {
            return (
              <TagWrapper $selected={selectedTagList.includes(tag)} onClick={() => handleTag(tag)} key={i}>
                <span>{tag}</span>
              </TagWrapper>
            );
          })}
      </TagListWrapper>
    </TagSelectBoxWrapper>
  );
};

export default TagSelectBox;

const LeftTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 8px;
  row-gap: 8px;
`;

const TagListWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-height: 440px;
  overflow-y: auto;
`;

const TagWrapper = styled.div<{ $selected?: boolean }>`
  display: flex;
  padding: 4px 8px;
  box-sizing: border-box;
  border: 1px solid ${grey400};
  border-radius: 12px;
  cursor: pointer;
  align-items: center;
  ${body2};
  color: ${grey600};

  &:hover {
    background-color: ${grey200};
  }

  ${(props) =>
    !!props.$selected &&
    css`
      background-color: ${green600} !important;
      border-color: ${green600} !important;

      color: white !important;
    `}
`;
