import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import TableArrowDown from '../../assets/images/TableArrowDown.svg';
import TableArrowUp from '../../assets/images/TableArrowUp.svg';
import TableSortIcon from '../../assets/images/TableSortIcon.svg';
import { caption1, green600, grey200, grey300, grey600, grey800, muiBorderColor, tableBody, tableHeadline } from '../../global/css';
import CheckBox from '../CheckBox';
import BodyRow from './BodyRow';

const TableWrapper = styled.div<{ $noScroll: boolean; $fullWidth: boolean; $hasBorder?: boolean; $height?: string; $maxHeight?: string; $minHeight?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
  box-sizing: content-box;
  padding-right: 8px;
  border-bottom: 1px solid ${muiBorderColor};
  width: fit-content;
  ${(props) =>
    !!props.$noScroll &&
    css`
      overflow: visible;
      padding-right: 0;
    `}
  ${(props) =>
    !!props.$fullWidth &&
    css`
      width: 100%;
    `}
  ${(props) =>
    !!props.$hasBorder &&
    css`
      border: 1px solid ${grey200};
      padding: 8px;
      box-sizing: border-box;
      border-radius: 6px;
    `}
  ${(props) =>
    !!props.$height &&
    css`
      height: ${`${props.$height}`};
      max-height: ${props.$height};
    `}
  ${(props) =>
    !!props.$maxHeight &&
    css`
      height: fit-content;
      max-height: ${props.$maxHeight};
    `};

  ${(props) =>
    !!props.$minHeight &&
    css`
      min-height: ${props.$minHeight};
    `}
`;

const HeaderWrapper = styled.div<{ $hasTopBorder: boolean; $autoWidth: number; $type: string }>`
  border-bottom: 1px solid ${muiBorderColor};
  box-sizing: border-box;
  display: flex;
  width: fit-content;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 3;
  ${(props) =>
    !!props.$autoWidth &&
    css`
      display: grid;
      grid-template-columns: ${!!props.$autoWidth && `${props.$type === 'check' || props.$type === 'radio' ? '72px' : ''} repeat(${props.$autoWidth}, 1fr)`};
      width: max-content;
      & > * {
        width: 100% !important;
      }
    `}
  ${(props) =>
    !!props.$hasTopBorder &&
    css`
      border-top: 1px solid ${muiBorderColor};
    `};
`;

const HeaderItem = styled.div<{ $width: string; $hasSort?: boolean }>`
  display: flex;
  width: 100%;
  white-space: nowrap;
  justify-content: center;
  column-gap: 8px;
  padding: ${(props) => (!!props.$hasSort ? '0 12px 0 32px' : '0 12px')};
  box-sizing: border-box;

  ${(props) =>
    !!props.$hasSort &&
    css`
      cursor: pointer;
    `}

  ${tableHeadline};
  color: ${grey600};
  height: 32px;
  align-items: center;
  border-right: 1px solid ${muiBorderColor};

  &:last-of-type {
    border-right: none;
  }
  & * {
    ${tableHeadline};
  }
  & > div {
    display: flex;
    align-items: center;
  }
  &:hover {
    & > div {
      opacity: 1;
    }
  }

  ${(props) =>
    !!props.$width &&
    css`
      width: ${props.$width};
      min-width: ${props.$width};
    `}
`;

const BodyWrapper = styled.div<{ $autoWidth: number }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  width: fit-content;
  overflow-x: hidden;
  overflow: visible;
  font-feature-settings: 'tnum';
  position: relative;
  ${(props) =>
    !!props.$autoWidth &&
    css`
      width: 100%;
    `}
`;

const BodyRowWrapper = styled.div<{ $selected?: boolean; $fullWidth: boolean; $autoWidth: number }>`
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid ${grey300};

  ${(props) =>
    !!props.$autoWidth &&
    css`
      width: max-content !important;
    `}
  &:last-of-type {
    border-bottom: none;
  }
  ${(props) =>
    !!props.$selected &&
    css`
      background-color: ${green600}15;
    `}
  ${(props) =>
    !!props.$fullWidth &&
    css`
      width: 100%;
    `}
`;

const BodyItem = styled.div<{ $padding?: string; $width?: string }>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: ${(props) => (!!props.$padding ? props.$padding : '20px 12px')};
  box-sizing: border-box;
  align-items: center;
  ${tableBody};
  color: ${grey800};
  word-break: break-all;
  & * {
    ${tableBody};
  }

  ${(props) =>
    !!props.$width &&
    css`
      width: ${props.$width};
      min-width: ${props.$width};
    `}
`;

const BodyRowText = styled.p`
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  ${caption1};
`;

const SortWrapper = styled.div`
  display: flex;

  & > img {
    width: 16px;
    cursor: pointer;
  }
`;
const SortDefaultWrapper = styled.div`
  display: flex;
  opacity: 0;
  transition: all 0.2s;

  & > img {
    width: 16px;
    cursor: pointer;
  }
`;

const MoreLoadingBox = styled.div`
  height: 2600px;
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

type TableProps = {
  allItemCount?: number;
  fullWidth?: boolean;
  maxHeight?: string;
  height?: string;
  hasBorder?: boolean;
  header?: {
    title: string;
    value: string;
    width?: string;
    sort?: boolean;
    render?: (item: any) => React.ReactNode;
    index?: number;
  }[];
  list?: { [props: string]: any }[];
  onRowClick?: (item: any) => void;
  dataId?: string;
  type?: 'check' | 'radio' | '';
  selectedItemList?: string[];
  setSelectedItemList?: React.Dispatch<React.SetStateAction<string[]>>;
  size?: 'small';
  checkDisabled?: boolean;
  disabledList?: string[];
  selectedSort?: string;
  setSelectedSort?: React.Dispatch<React.SetStateAction<string>>;
  minHeight?: string;
  isFetching?: boolean;
  autoWidth?: number;
  noScroll?: boolean;
  dataCoverList?: { [props: string]: any }[];
  tableId?: string;
  noDataText?: string;
  checkType?: 'add' | 'default';
};

const Table = (props: TableProps) => {
  const {
    checkType = 'default',
    isFetching,
    allItemCount,
    fullWidth,
    maxHeight,
    height,
    hasBorder,
    header = [],
    list = [],
    onRowClick,
    dataId = 'id',
    type = '',
    selectedItemList = [],
    setSelectedItemList = () => {},
    size,
    checkDisabled,
    disabledList = [],
    selectedSort = '',
    setSelectedSort = () => {},
    minHeight,
    autoWidth = 0,
    noScroll = false,
    dataCoverList = [],
    tableId = '',
    noDataText = '데이터가 없습니다.',
  } = props;

  const { ref, inView } = useInView({
    threshold: 0.01,
  });

  const tableRef = useRef<HTMLDivElement>(null);
  const [tableWidth, setTableWidth] = useState<number>(0);

  useEffect(() => {
    const t = document.getElementById(`table_header_${tableId ?? ''}`);
    if (!!t) {
      setTableWidth(t.scrollWidth);
    }
  }, [tableRef.current, tableId, header]);

  const [dataList, setDataList] = useState<{ [props: string]: any }[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isCombining, setIsCombining] = useState<boolean>(false);

  const handleSelectItem = (e: any, id: any) => {
    if (!!e) {
      e.stopPropagation();
    }

    if (id === 'all') {
      if (checkType === 'default') {
        if (!!allItemCount || allItemCount === 0) {
          if (allItemCount === selectedItemList.length) {
            setSelectedItemList([]);
          } else {
            const currentUnSelectedList = [
              ...list.map((l) => {
                if (!selectedItemList.includes(l[dataId])) {
                  return l[dataId];
                }
              }),
            ].filter((l) => !!l);

            setSelectedItemList((prev) => [...prev, ...currentUnSelectedList]);
          }
        } else {
          if (selectedItemList.length === list.filter((l) => !disabledList.includes(l[dataId])).length) setSelectedItemList([]);
          else setSelectedItemList([...list.filter((l) => !disabledList.includes(l[dataId])).map((l) => l[dataId])]);
        }
      } else if (checkType === 'add') {
        if (getAddTypeIsAllChecked()) {
          setSelectedItemList((prev) => [
            ...prev.filter(
              (p) =>
                !list
                  .filter((l) => !disabledList.includes(l[dataId]))
                  .map((l) => l[dataId])
                  .includes(p)
            ),
          ]);
        } else {
          setSelectedItemList((prev) => [
            ...prev,
            ...list
              .filter((l) => !prev.includes(l[dataId]))
              .filter((l) => !disabledList.includes(l[dataId]))
              .map((l) => l[dataId]),
          ]);
        }
      }
    } else {
      if (type === 'check') {
        if (selectedItemList.includes(id)) {
          setSelectedItemList((prev) => [...prev.filter((p) => p !== id)]);
        } else {
          setSelectedItemList((prev) => [...prev, id]);
        }
      } else if (type === 'radio') {
        setSelectedItemList([id]);
      }
    }
  };

  const handleRowClick = (data: any) => {
    if (!!onRowClick) onRowClick(data);
  };

  const handleSort = (e: any, id: any) => {
    if (!!e) {
      e.stopPropagation();
    }

    if (selectedSort.includes(id)) {
      const [, current] = selectedSort.split('-sort-');
      if (current === 'asc') {
        setSelectedSort('');
      } else {
        setSelectedSort(id + '-sort-asc');
      }
    } else {
      setSelectedSort(id + '-sort-desc');
    }
  };

  useEffect(() => {
    let timeout: any;
    if (!!isFetching) {
      timeout = setTimeout(() => {
        setIsLoading(() => true);
      }, 1000);
    } else {
      setIsLoading(() => false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFetching]);

  useEffect(() => {
    if (!!isCombining) return;

    if (!inView) return;
    if (list.length <= dataList.length) return;
    if (dataList.length < 1) return;

    setIsCombining(() => true);
    setDataList((prev) => [...prev, ...list.slice(prev.length, prev.length + 50)]);
    setTimeout(() => {
      setIsCombining(() => false);
    }, 500);
  }, [inView, list, dataList, isCombining]);

  useEffect(() => {
    setDataList([...list.slice(0, 10)]);
  }, [list]);

  const getAddTypeIsAllChecked = () => {
    let flag = true;
    for (const item of dataList) {
      if (!selectedItemList.includes(item[dataId]) && !disabledList.includes(item[dataId])) {
        flag = false;
        break;
      }
    }

    return flag;
  };

  return (
    <TableWrapper ref={tableRef} $noScroll={!!noScroll} $height={height} $minHeight={minHeight} $maxHeight={maxHeight} $fullWidth={!!fullWidth} $hasBorder={!!hasBorder}>
      <HeaderWrapper id={`table_header_${tableId ?? ''}`} $type={type} $autoWidth={autoWidth} $hasTopBorder={!hasBorder}>
        {(type === 'check' || type === 'radio') && (
          <HeaderItem $width={'fit-content'}>
            {type === 'check' ? (
              <CheckBox
                disabled={!!checkDisabled}
                selected={
                  checkType === 'default'
                    ? !!allItemCount || allItemCount === 0
                      ? selectedItemList.length === allItemCount
                      : selectedItemList.length !== 0 && selectedItemList.length === list.filter((l) => !disabledList.includes(l[dataId])).length
                    : checkType === 'add'
                    ? getAddTypeIsAllChecked()
                    : false
                }
                onClick={(e) => handleSelectItem(e, 'all')}
              />
            ) : (
              <div style={{ width: '47px' }} />
            )}
          </HeaderItem>
        )}
        {header.map((h, i) => {
          return (
            <HeaderItem $hasSort={!!h.sort} onClick={!!h.sort ? (e) => handleSort(e, h.value) : () => {}} key={i} $width={!!h.width ? h.width : ''}>
              <p>{h.title}</p>

              {!!h.sort && selectedSort.includes(h.value) ? (
                <SortWrapper>
                  <img src={selectedSort.includes('asc') ? TableArrowUp : TableArrowDown} alt='sort' />
                </SortWrapper>
              ) : (
                !!h.sort &&
                !selectedSort.includes(h.value) && (
                  <SortDefaultWrapper>
                    <img src={TableSortIcon} alt='sort' />
                  </SortDefaultWrapper>
                )
              )}
            </HeaderItem>
          );
        })}
      </HeaderWrapper>
      <BodyWrapper $autoWidth={autoWidth}>
        {!isFetching && !!dataList.length ? (
          dataList.map((l, i) => {
            return (
              <BodyRowWrapper $autoWidth={autoWidth} $fullWidth={!!fullWidth} key={`${l[dataId]}${i}`}>
                <BodyRow
                  tableWidth={!!tableWidth ? tableWidth : 0}
                  type={type}
                  autoWidth={autoWidth}
                  selected={!!onRowClick && selectedItemList.includes(l[dataId])}
                  fullWidth={!!fullWidth}
                  hasClick={!!onRowClick}
                  onClick={() => handleRowClick(l)}
                >
                  {(type === 'check' || type === 'radio') && (
                    <BodyItem $width='fit-content' $padding='0 12px'>
                      <CheckBox
                        disabled={!!checkDisabled || disabledList.includes(l[dataId])}
                        selected={selectedItemList.includes(l[dataId])}
                        onClick={(e) => handleSelectItem(e, l[dataId])}
                      />
                    </BodyItem>
                  )}
                  {header.map((h, j) => {
                    return (
                      <BodyItem $width={!!h.width ? h.width : ''} key={j} $padding={!!size && size === 'small' ? '4px 8px' : ''}>
                        {!!h.render
                          ? h.render(l)
                          : !!dataCoverList.length && !!dataCoverList.filter((d) => d.value === l[h.value] && d.header === h.value).length
                          ? dataCoverList.filter((d) => d.value === l[h.value] && d.header === h.value)[0].title
                          : !!l[h.value]
                          ? l[h.value]
                          : '-'}
                      </BodyItem>
                    );
                  })}
                </BodyRow>
              </BodyRowWrapper>
            );
          })
        ) : !isFetching && !list.length && !dataList.length ? (
          //데이터 없음
          <BodyRowText>{noDataText}</BodyRowText>
        ) : (
          !!isLoading && (
            //로딩중
            <BodyRowText>로딩중</BodyRowText>
          )
        )}
        {!isFetching && !!dataList.length && dataList.length < list.length && <MoreLoadingBox ref={ref} />}
      </BodyWrapper>
    </TableWrapper>
  );
};

export default Table;
