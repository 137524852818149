import { TextField } from '@mui/material';
import React, { KeyboardEventHandler, useEffect } from 'react';
import styled from 'styled-components';

const DefaultDeviceCommandControlWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

type DefaultDeviceCommandControlProps = {
  setParam1: Function;
  setParam2?: Function;
  setCanSubmit: Function;
  param1Title: string;
  param2Title?: string;
  param1: string;
  param2?: string;
  onKeyDown1?: KeyboardEventHandler<HTMLDivElement>;
  onKeyDown2?: KeyboardEventHandler<HTMLDivElement>;
  onCheck1: Function;
  onCheck2: Function;
  valueType1: 'string' | 'number';
  valueType2: 'string' | 'number';
};

const DefaultDeviceCommandControl = (props: DefaultDeviceCommandControlProps) => {
  const {
    valueType1,
    valueType2,
    onCheck1,
    onCheck2,
    onKeyDown1 = () => {},
    onKeyDown2 = () => {},
    setParam1,
    setParam2,
    setCanSubmit,
    param1Title,
    param2Title,
    param1,
    param2,
  } = props;

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>, type: number) => {
    const typeMap: { [props: number]: KeyboardEventHandler<HTMLDivElement> } = {
      1: onKeyDown1,
      2: onKeyDown2,
    };

    if (!!typeMap[type]) {
      return typeMap[type](e);
    }
  };

  useEffect(() => {
    if (!!onCheck1) {
      if (!onCheck1(param1)) {
        setCanSubmit(() => false);
        return;
      }
    }

    if (!!onCheck2) {
      if (!onCheck2(param2)) {
        setCanSubmit(() => false);
        return;
      }
    }

    if (!!param2Title && !!setParam2) {
      if (!!param1 && !!param2) {
        setCanSubmit(() => true);
      } else {
        setCanSubmit(() => false);
      }
    } else {
      if (!!param1) {
        setCanSubmit(() => true);
      } else {
        setCanSubmit(() => false);
      }
    }
  }, [param1, param2, param1Title, param2Title, setParam2, setCanSubmit, onCheck1, onCheck2]);

  return (
    <DefaultDeviceCommandControlWrapper>
      <TextField
        onKeyDown={(e) => handleKeydown(e, 1)}
        sx={{ minWidth: '480px' }}
        size='small'
        label={param1Title}
        onChange={(e) => setParam1(valueType1 === 'number' ? `${Number(e.target.value) || ''}` : e.target.value)}
        value={param1}
      />
      {!!param2Title && !!setParam2 && (
        <TextField
          onKeyDown={(e) => handleKeydown(e, 2)}
          sx={{ minWidth: '480px' }}
          size='small'
          label={param2Title}
          onChange={(e) => setParam2(valueType2 === 'number' ? `${Number(e.target.value) || ''}` : e.target.value)}
          value={param2}
        />
      )}
    </DefaultDeviceCommandControlWrapper>
  );
};

export default DefaultDeviceCommandControl;
