import styled from 'styled-components';
import { caption3, green200, green600 } from '../global/css';

const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  box-sizing: border-box;
  background-color: ${green200};
  color: ${green600};
  border-radius: 16px;
  white-space: nowrap;
  ${caption3};
`;

type TagProps = {
  text: string;
};

const Tag = (props: TagProps) => {
  const { text } = props;
  return <TagWrapper>{text}</TagWrapper>;
};

export default Tag;
