import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../assets/images/Logo.svg';
import { axiosInstance } from '../global/axiosSetting';
import { elevation, grey050, grey200, headline2 } from '../global/css';

const LoginPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: ${grey050};
  & > div {
    width: 400px;
    ${elevation};
    display: flex;
    flex-direction: column;
    padding: 40px 40px 32px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 16px;
    border: 1px solid ${grey200};
    align-items: center;
    row-gap: 20px;
    & > img {
      width: 80px;
    }
    & > p {
      ${headline2};
    }
    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      row-gap: 12px;
    }
  }
`;

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleLogin = () => {
    axiosInstance({
      method: 'post',
      url: '/auth/sign-in',
      data: {
        email: email,
        password: password,
      },
    })
      .then((res) => {
        console.log('auth/sign-in => ', res);
        if (!!res.data.accessToken) {
          sessionStorage.setItem('accessToken', res.data.accessToken);
          navigate('/dashboard');
        }
      })
      .catch((error) => {
        console.log('auth/sign-in error => ', error);
      });
  };

  const checkEnter = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      handleLogin();
    }
  };

  useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (!!accessToken) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <LoginPageWrapper>
      <div>
        <img src={Logo} alt='logo' />
        <p>Teladin Monitoring Service</p>
        <div>
          <TextField fullWidth label='이메일' value={email} onChange={(e) => setEmail(e.target.value)} />
          <FormControl fullWidth variant='outlined'>
            <InputLabel htmlFor='login-password'>비밀번호</InputLabel>
            <OutlinedInput
              id='login-password'
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={checkEnter}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword((show) => !show)} onMouseDown={(e) => e.preventDefault()} edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='비밀번호'
            />
          </FormControl>
        </div>
        <Button onClick={handleLogin} size='large' variant='contained' fullWidth>
          로그인
        </Button>
      </div>
    </LoginPageWrapper>
  );
};

export default LoginPage;
