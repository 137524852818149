import styled from 'styled-components';
import { caption1, caption3, grey600 } from '../../global/css';
import { getMonitoringInfoTitle } from '../../global/types';
import { useContext } from 'react';
import { LoginUserContext } from '../../hooks/LoginUserHook';

const MonitoringDetailInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  & > div {
    display: flex;
    flex-direction: column;
    & > span {
      ${caption3};
      color: ${grey600};
    }
    & > div {
      display: flex;
      ${caption1};
      padding-left: 8px;
      justify-content: flex-start;
      & > div {
        width: fit-content !important;
      }
    }
  }
`;

type MonitoringDetailInfoProps = {
  data?: { [props: string]: any };
  disabledList?: string[];
};

const MonitoringDetailInfo = (props: MonitoringDetailInfoProps) => {
  const { data, disabledList = [] } = props;

  const { loginUser } = useContext(LoginUserContext);

  return (
    <MonitoringDetailInfoWrapper>
      {!!data &&
        getMonitoringInfoTitle([...disabledList, 'tags', 'description1', 'description2', ...(loginUser.organization_id !== 1 ? ['created_at'] : [])])
          .sort((a, b) => (a.index < b.index ? -1 : 1))
          .map((item, index) => {
            return (
              <div key={index}>
                <span>{item?.title}</span>
                <div>{!!item.render ? item.render(data) : data[item?.value] ?? '-'}</div>
              </div>
            );
          })}
    </MonitoringDetailInfoWrapper>
  );
};

export default MonitoringDetailInfo;
