import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';
import { grey200 } from '../../global/css';
import RecursiveGroup from './RecursiveGroup';

const ManagementGroupSelectWrapper = styled.div`
  overflow-y: auto;
  max-height: 640px;
  display: flex;
  flex-direction: column;
  max-width: 480px;
  min-width: 320px;
  width: 100%;
  border: 1px solid ${grey200};
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  row-gap: 12px;
`;

const GroupListWrapper = styled.div``;

type ManagementGroupSelectProps = {
  selected: { [props: string]: any };
  setSelected: Function;
  type?: string;
  originalSelected?: { [props: string]: any };
};

const ManagementGroupSelect = (props: ManagementGroupSelectProps) => {
  const { selected, setSelected, type, originalSelected } = props;
  const [groupList, setGroupList] = useState<{ [props: string]: any }>({});
  const [search, setSearch] = useState<string>('');
  const [closeList, setCloseList] = useState<number[]>([]);

  useEffect(() => {
    axiosInstance({
      url: '/organizations',
      method: 'GET',
    })
      .then((res) => {
        console.log('/organizations =>', res);
        setGroupList(res.data);
      })
      .catch((error) => {
        console.log('/organizations error =>', error);
      });
  }, []);

  return (
    <ManagementGroupSelectWrapper>
      <TextField
        size='small'
        fullWidth
        placeholder='조직 단위 검색'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <GroupListWrapper>
        <RecursiveGroup
          type={type}
          setSearch={setSearch}
          search={search}
          closeList={closeList}
          setCloseList={setCloseList}
          selected={selected}
          originalSelected={originalSelected}
          setSelected={setSelected}
          data={groupList}
        />
      </GroupListWrapper>
    </ManagementGroupSelectWrapper>
  );
};

export default ManagementGroupSelect;
