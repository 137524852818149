import { Autocomplete, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CheckBox from '../CheckBox';

const LTEBandBoxWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;

const SelectListWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

type LTEBandBoxProps = {
  modelName: string;
  setParam1: Function;
  setCanSubmit: Function;
};

const LTEBandBox = (props: LTEBandBoxProps) => {
  const { modelName, setParam1, setCanSubmit } = props;
  console.log(modelName);

  const [selectedBandType, setSelectedBandType] = useState<string>('');
  const [selectedBandList, setSelectedBandList] = useState<number[]>([]);

  const bandListMap: { [props: string]: number[] } = {
    KT: [1, 3, 8],
    'LGU+': [1, 5, 7],
    SKT: [1, 3, 5],
  };

  const handleCheck = (value: number) => {
    if (selectedBandList.includes(value)) {
      setSelectedBandList((prev: number[]) => prev.filter((band) => band !== value));
    } else {
      setSelectedBandList((prev: number[]) => [...prev, value]);
    }
  };

  useEffect(() => {
    setParam1(() => selectedBandList.join(','));
    if (!!selectedBandList.length) {
      setCanSubmit(() => true);
    } else {
      setCanSubmit(() => false);
    }
  }, [selectedBandList]);

  useEffect(() => {
    setSelectedBandList([]);
  }, [selectedBandType]);

  return (
    <LTEBandBoxWrapper>
      <FormControl size='small'>
        <InputLabel id='LTE-Band-Select'>통신사 선택</InputLabel>
        <Select
          labelId='LTE-Band-Select'
          sx={{ width: '160px' }}
          size='small'
          value={selectedBandType || ''}
          label='통신사 선택'
          onChange={(e) => setSelectedBandType(() => e.target.value)}
        >
          {Object.keys(bandListMap)
            .filter((t) => (modelName === 'TR700' ? t === 'LGU+' : true))
            .map((type, index) => {
              return (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <SelectListWrapper>
        {!!selectedBandType &&
          bandListMap[selectedBandType].map((value, index) => {
            return <CheckBox key={index} label={`${value}`} selected={selectedBandList.includes(value)} onClick={() => handleCheck(value)} />;
          })}
      </SelectListWrapper>
    </LTEBandBoxWrapper>
  );
};

export default LTEBandBox;
