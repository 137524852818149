import React from 'react';
import styled, { css } from 'styled-components';
import { caption1, grey050, grey100, grey200, grey400, grey600, grey900, muiBorderColor } from '../global/css';

const ChartTimeOptionBoxWrapper = styled.div`
  display: flex;
  padding: 8px 12px;
  box-sizing: border-box;
  background-color: white;
  column-gap: 8px;
  border-radius: 8px;
  border: 1px solid ${muiBorderColor};
`;

const TimeOptionItemWrapper = styled.div<{ $selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0 12px;
  cursor: pointer;
  box-sizing: border-box;
  & > span {
    ${caption1};
    color: ${grey600};
  }
  &:hover {
    border-color: ${grey400};
    background-color: ${grey050};
    & > span {
      color: ${grey400};
    }
  }
  &:active {
    border-color: ${grey600};
    background-color: ${grey100};
    & > span {
      color: ${grey600};
    }
  }

  ${(props) =>
    !!props.$selected &&
    css`
      border-color: ${grey900} !important;
      background-color: ${grey900} !important;
      & > span {
        color: ${grey050} !important;
      }
    `}
`;

type ChartTimeOptionBoxProps = {
  selected: string;
  setSelected: (value: string) => void;
};

const ChartTimeOptionBox = (props: ChartTimeOptionBoxProps) => {
  const { selected, setSelected } = props;

  return (
    <ChartTimeOptionBoxWrapper>
      {timeOptionList.map((item, index) => {
        return (
          <TimeOptionItemWrapper onClick={() => setSelected(item.value)} $selected={selected === item.value} key={index}>
            <span>{item.title}</span>
          </TimeOptionItemWrapper>
        );
      })}
    </ChartTimeOptionBoxWrapper>
  );
};

export default ChartTimeOptionBox;

const timeOptionList = [
  {
    title: '1h',
    value: '1h',
  },
  {
    title: '3h',
    value: '3h',
  },
  {
    title: '12h',
    value: '12h',
  },
  {
    title: '1d',
    value: '1d',
  },
  {
    title: '3d',
    value: '3d',
  },
  {
    title: '1w',
    value: '1w',
  },
];

export const ChartTimeOptionMap: { [props: string]: any } = {
  '1h': {
    byFns: {
      hours: 1,
    },
    byIndex: (index: number) => {
      return index === 0 || index % 2 === 0;
    },
  },
  '3h': {
    byFns: {
      hours: 3,
    },
    byIndex: (index: number) => {
      return index === 0 || index % 6 === 0;
    },
  },
  '12h': {
    byFns: {
      hours: 12,
    },
    byIndex: (index: number) => {
      return index === 0 || index % 24 === 0;
    },
  },
  '1d': {
    byFns: {
      days: 1,
    },
    byIndex: (index: number) => {
      return index === 0 || index % 48 === 0;
    },
  },
  '3d': {
    byFns: {
      days: 3,
    },
    byIndex: (index: number) => {
      return index === 0 || index % 144 === 0;
    },
  },
  '1w': {
    byFns: {
      weeks: 1,
    },
    byIndex: (index: number) => {
      return index === 0 || index % 1008 === 0;
    },
  },
};
