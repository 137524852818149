import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingAnimation = keyframes`
  0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoadingWrapper = styled.div<{ $height?: string; $width?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (!!props.$height ? props.$height : '100vh')};
  width: ${(props) => (!!props.$width ? props.$width : '100vw')};
  & > span {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: #ff3d00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: ${LoadingAnimation} 1s linear infinite;
  }
`;

type LoadingProps = {
  height?: string;
  width?: string;
};

const Loading = ({ height, width }: LoadingProps) => {
  return (
    <LoadingWrapper $height={height} $width={width}>
      <span />
    </LoadingWrapper>
  );
};

export default Loading;
