import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';
import LineChartBox from '../LineChartBox';
import { Button } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import DatePicker from '../DatePicker';
import ChartTimeOptionBox, { ChartTimeOptionMap } from '../ChartTimeOptionBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Range } from 'react-date-range';
import { add, format, sub } from 'date-fns';
import { blue400, muiBorderColor } from '../../global/css';
import { getMonitoringTableHeader } from '../../global/types';

const MonitoringDetailSignalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
const TopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  column-gap: 8px;
`;

const ChartWrapper = styled.div<{ $fullChart: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (!!props.$fullChart ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)')};
  gap: 12px;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 12px;
`;

type MonitoringDetailSignalProps = {
  data?: { [props: string]: any };
};

const MonitoringDetailSignal = (props: MonitoringDetailSignalProps) => {
  const { data } = props;
  const [dataList, setDataList] = useState<{ [props: string]: any }[]>([]);

  const [fullChart, setFullChart] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<[Range]>([
    {
      startDate: add(new Date(), { days: -7 }),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [selectedChartTimeOption, setSelectedChartTimeOption] = useState<string>('1h');
  const [filteredDataList, setFilteredDataList] = useState<{ [props: string]: any }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const chartList = [
    {
      name: 'radio_signal_strength',
      title: 'Radio Signal Length',
      data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.radio_signal_strength)],
    },
    {
      name: 'link_quality',
      title: 'Link Quality',
      data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.link_quality)],
    },
    {
      name: 'sinr',
      title: 'SINR',
      data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => Number(d.sinr) / 10)],
    },
    {
      name: 'rssi',
      title: 'RSSI',
      data: [...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.rssi)],
    },
  ];

  const getChartTitle = (value: string) => {
    const t = getMonitoringTableHeader().find((d) => d.value === value)?.title;

    return t + ' ' || '';
  };

  const handleDate = (item: Range) => {
    setSelectedDateRange(() => [item]);
  };

  const handleSearch = () => {
    if (!data || !data['device_id'] || !!isLoading) return;
    setIsLoading(() => true);
    axiosInstance({
      method: 'get',
      url: `/monitoring/devices/${data['device_id']}`,
      params: {
        from: format(new Date(`${selectedDateRange[0].startDate}`), 'yyyy-MM-dd'),
        to: format(new Date(`${selectedDateRange[0].endDate}`), 'yyyy-MM-dd'),
        limit: 10000,
      },
    })
      .then((res) => {
        console.log(`/monitoring/devices/${data['device_id']} =>`, res);
        setDataList([...res.data.items]);
      })
      .catch((error) => {
        console.log(`/monitoring/devices/${data['device_id']} error =>`, error);
      });
  };

  useEffect(() => {
    handleSearch();
  }, [data, selectedDateRange]);

  useEffect(() => {
    if (!dataList.length || !selectedChartTimeOption) return;
    setIsLoading(() => true);
    let prevTime: Date | null = null;
    const arr = [];
    for (const data of dataList.sort((a, b) => (a.created_at > b.created_at ? -1 : 1))) {
      if (!prevTime || new Date(prevTime) >= new Date(data.created_at)) {
        prevTime = sub(new Date(data.created_at), ChartTimeOptionMap[selectedChartTimeOption].byFns);
        arr.push(data);
      }
    }

    console.log('?!?!@', arr);

    setFilteredDataList(arr.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)));
    setIsLoading(() => false);
  }, [dataList, selectedChartTimeOption]);

  return (
    <MonitoringDetailSignalWrapper>
      <TopWrapper>
        <Button
          onClick={() => setFullChart((prev) => !prev)}
          size='small'
          variant='outlined'
          color='secondary'
          sx={{ borderColor: muiBorderColor, width: 'fit-content', minWidth: 'fit-content' }}
        >
          {!!fullChart ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </Button>
        <DatePicker color='secondary' onChange={handleDate} size='medium' backgroundColor='white' />
        <ChartTimeOptionBox selected={selectedChartTimeOption} setSelected={setSelectedChartTimeOption} />

        <Button onClick={handleSearch} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
          <RefreshIcon />
        </Button>
      </TopWrapper>
      <ChartWrapper $fullChart={!!fullChart}>
        {chartList.map((chart) => {
          return (
            <LineChartBox
              isLoading={isLoading}
              key={chart.name}
              series={[
                {
                  label: chart.title,
                  data: chart.data,
                  borderColor: blue400,
                  backgroundColor: blue400,
                },
              ]}
              title={getChartTitle(chart.name) + `(${chart.title})`}
              list={[...filteredDataList.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).map((d) => d.created_at)]}
            />
          );
        })}
      </ChartWrapper>
    </MonitoringDetailSignalWrapper>
  );
};

export default MonitoringDetailSignal;
