import { useEffect, useState } from 'react';
import styled from 'styled-components';
import TabBox from '../components/TabBox';
import Campaign from '../components/fota/Campaign';
import DiffPackage from '../components/fota/DiffPackage';
import FullPackage from '../components/fota/FullPackage';
import { grey200 } from '../global/css';

const FOTAPage = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const handleTabIndex = (index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (selectedIndex >= 0) {
      sessionStorage.setItem('fotaSelectedIndex', selectedIndex.toString());
    }
  }, [selectedIndex]);

  useEffect(() => {
    const savedIndex = sessionStorage.getItem('fotaSelectedIndex');

    if (!!savedIndex && (!!Number(savedIndex) || Number(savedIndex) === 0)) {
      setSelectedIndex(Number(savedIndex));
    } else {
      setSelectedIndex(0);
    }
    return () => {
      sessionStorage.removeItem('fotaSelectedIndex');
    };
  }, []);

  return (
    <FOTAPageWrapper>
      <TabBox
        onChange={handleTabIndex}
        contentPadding='12px 0'
        defaultValue={selectedIndex}
        size='small'
        dataList={tabList.map((t) => {
          return {
            index: t.index,
            title: t.title,
          };
        })}
      >
        <div tabIndex={selectedIndex}>{tabList.find((t) => t.index === selectedIndex)?.render({ selectedIndex })}</div>
      </TabBox>
    </FOTAPageWrapper>
  );
};

const tabList = [
  {
    index: 0,
    title: '패키지(Full)',

    render: (data: any) => <FullPackage {...data} />,
  },
  {
    index: 1,
    title: '패키지(Diff)',

    render: (data: any) => <DiffPackage {...data} />,
  },
  {
    index: 2,
    title: '캠페인',

    render: (data: any) => <Campaign {...data} />,
  },
];

export default FOTAPage;

const FOTAPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid ${grey200};
  padding: 16px;
  row-gap: 8px;
`;
