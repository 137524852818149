import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import styled from 'styled-components';
import { checkIsNumber } from '../../global/utils';
import DatePicker from '../DatePicker';

const DataLimitBoxWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

type DataLimitBoxProps = {
  setParam1: Function;
  setCanSubmit: Function;
};

const DataLimitBox = (props: DataLimitBoxProps) => {
  const { setParam1, setCanSubmit } = props;
  const [dataLimitToggle, setDataLimitToggle] = useState<{ [props: string]: string }>({});
  const [selectedDataLimit, setSelectedDataLimit] = useState<{ [props: string]: any }>({});
  const [selectedDataLimitUnit, setSelectedDataLimitUnit] = useState<string>('');
  const [dataUsage, setDataUsage] = useState<string>('');
  const [dataLimitDailySelectedDate, setDataLimitDailySelectedDate] = useState<[Range]>([
    {
      key: 'selection',
    },
  ]);

  const dataLimitDescriptionMap: { [props: string]: string } = {
    daily: '매일 0~24시까지 사용, 다음날 초기화됩니다.',
    weekly: '일요일~토요일까지 사용, 매주 일요일 초기화됩니다.',
    monthly: '매달 1일 초기화됩니다.',
  };

  const handleDate = (item: Range) => {
    setDataLimitDailySelectedDate(() => [item]);
  };

  useEffect(() => {
    let param1 = '';

    const dataToggleMap: { [props: string]: string } = {
      off: '0',
      on: '1',
    };

    const dataLimitMap: { [props: string]: number } = {
      daily: 0,
      weekly: 1,
      monthly: 2,
      date: 3,
    };

    param1 += dataToggleMap[dataLimitToggle.value] + ',';
    if (!!dataLimitMap[selectedDataLimit.value] || dataLimitMap[selectedDataLimit.value] === 0) {
      param1 += dataLimitMap[selectedDataLimit.value] + ',';
    } else {
      param1 += ',';
    }
    if (selectedDataLimit.value === 'date' && !!dataLimitDailySelectedDate[0] && !!dataLimitDailySelectedDate[0].startDate && !!dataLimitDailySelectedDate[0].endDate) {
      param1 += dataLimitDailySelectedDate[0].startDate.getFullYear() + ',';
      param1 += dataLimitDailySelectedDate[0].startDate.getMonth() + 1 + ',';
      param1 += dataLimitDailySelectedDate[0].startDate.getDate() + ',';
      param1 += dataLimitDailySelectedDate[0].endDate.getFullYear() + ',';
      param1 += dataLimitDailySelectedDate[0].endDate.getMonth() + 1 + ',';
      param1 += dataLimitDailySelectedDate[0].endDate.getDate() + ',';
    } else {
      param1 += ',,,,,,';
    }
    if (selectedDataLimit.value !== 'none') {
      param1 += dataUsage + ',' + selectedDataLimitUnit;
    } else {
      param1 += ',';
    }

    console.log(param1);

    setParam1(param1);
  }, [dataLimitDailySelectedDate, dataUsage, selectedDataLimit, selectedDataLimitUnit, dataLimitToggle, setParam1]);

  useEffect(() => {
    if (!!dataLimitToggle && !!dataLimitToggle.value) {
      setCanSubmit(() => true);
    } else {
      setCanSubmit(() => false);
    }
  }, [dataLimitToggle]);

  return (
    <DataLimitBoxWrapper>
      <Autocomplete
        onChange={(_, v: any) => setDataLimitToggle(v ?? {})}
        sx={{ minWidth: '144px' }}
        size='small'
        options={[
          {
            value: 'off',
            title: 'Off',
          },
          {
            value: 'on',
            title: 'On',
          },
        ]}
        renderInput={(params: any) => <TextField {...params} label='기능 동작' />}
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
      {!!dataLimitToggle && dataLimitToggle.value === 'on' && (
        <React.Fragment>
          <Autocomplete
            onChange={(_, v: any) => setSelectedDataLimit(v ?? {})}
            sx={{ minWidth: '144px' }}
            size='small'
            options={[
              {
                value: 'daily',
                title: '일별 제한',
              },
              {
                value: 'weekly',
                title: '주별 제한',
              },
              {
                value: 'monthly',
                title: '월별 제한',
              },
              {
                value: 'date',
                title: '날짜 지정',
              },
            ]}
            getOptionLabel={(option) => option.title}
            renderInput={(params: any) => (
              <TextField
                sx={{
                  '.MuiFormHelperText-root': {
                    marginLeft: 0,
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: 'max-content',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    transform: 'translateY(calc(100% + 2px))',
                  },
                }}
                helperText={dataLimitDescriptionMap[selectedDataLimit.value]}
                {...params}
                label='제한 설정'
              />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />

          {!!selectedDataLimit && !!selectedDataLimit.value && selectedDataLimit.value !== 'none' && (
            <React.Fragment>
              {selectedDataLimit.value === 'date' && (
                <>
                  <DatePicker minDate={new Date()} noDefaultValue onChange={handleDate} color='secondary' noMaxDate size='small' />
                </>
              )}
              <TextField
                onKeyDown={checkIsNumber}
                sx={{ minWidth: '120px' }}
                size='small'
                label={`데이터 사용량`}
                name={`데이터 사용량`}
                onChange={(e) => setDataUsage(e.target.value)}
                value={dataUsage}
              />
              <FormControl size='small'>
                <InputLabel id='data-limit-unit'>단위</InputLabel>
                <Select
                  labelId='data-limit-unit'
                  sx={{ width: '104px' }}
                  size='small'
                  value={selectedDataLimitUnit}
                  label='단위'
                  onChange={(e) => setSelectedDataLimitUnit(e.target.value)}
                >
                  <MenuItem value={'M'}>메가</MenuItem>
                  <MenuItem value={'G'}>기가</MenuItem>
                </Select>
              </FormControl>
            </React.Fragment>
          )}
          {/* <DescriptionWrapper>{dataLimitDescriptionMap[selectedDataLimit.value]}</DescriptionWrapper> */}
        </React.Fragment>
      )}
    </DataLimitBoxWrapper>
  );
};

export default DataLimitBox;
