import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import DashboardNavigation from '../components/DashboardNavigation';
import { grey050 } from '../global/css';
import DashboardPage from '../pages/DashboardPage';
import MonitoringPage from '../pages/MonitoringPage';

import { useContext } from 'react';
import LoginUserBoundary, { LoginUserContext } from '../hooks/LoginUserHook';
import ControlPage from '../pages/ControlPage';
import ManagementPage from '../pages/ManagementPage';
import FOTAPage from '../pages/FOTAPage';

const DashboardRouterWrapper = styled.div``;

const DashboardContentWrapper = styled.div`
  background-color: ${grey050};
  display: flex;
  flex-direction: column;
  padding: 60px 0px 16px;
  min-height: 100vh;
  box-sizing: border-box;
`;

const DashboardRouterList = () => {
  const { loginUser } = useContext(LoginUserContext);

  return (
    <DashboardContentWrapper>
      <Routes>
        <Route path='/' element={<DashboardPage />} />
        <Route path='/monitoring' element={<MonitoringPage />} />
        {loginUser.permission === 'admin' && (
          <>
            <Route path='/control' element={<ControlPage />} />
            {loginUser.organization_id === 1 && <Route path='/fota' element={<FOTAPage />} />}
          </>
        )}
        <Route path='/management' element={<ManagementPage />} />
      </Routes>
    </DashboardContentWrapper>
  );
};

const DashboardRouter = () => {
  return (
    <LoginUserBoundary>
      <DashboardRouterWrapper>
        <DashboardNavigation />
        <DashboardRouterList />
      </DashboardRouterWrapper>
    </LoginUserBoundary>
  );
};

export default DashboardRouter;
