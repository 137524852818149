import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';
import { Autocomplete, Button, Modal, TextField } from '@mui/material';
import DatePicker from '../DatePicker';
import { add, format } from 'date-fns';
import { Range } from 'react-date-range';
import { caption2, grey200, muiBorderColor } from '../../global/css';
import RefreshIcon from '@mui/icons-material/Refresh';
import Table from '../table/Table';
import TablePagination from '../table/TablePagination';
import { getControlTableHeader } from '../../global/types';

const MonitoringDetailHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:last-of-type {
    display: flex;
    column-gap: 8px;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const ModalContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${grey200};
  border-radius: 12px;
  box-sizing: border-box;
  background-color: white;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 20px;
  min-width: 70%;
  max-height: 100vh;
  overflow-y: auto;
`;

const ModalBottomWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 16px 0 0;
  box-sizing: border-box;
`;

type MonitoringDetailHistoryProps = {
  data: { [props: string]: any };
};

const MonitoringDetailHistory = (props: MonitoringDetailHistoryProps) => {
  const { data } = props;
  const [historyList, setHistoryList] = useState<{ [props: string]: any }[]>([]);
  const [controlTypeList, setControlTypeList] = useState<{ [props: string]: any }[]>([]);
  const [selectedControlType, setSelectedControlType] = useState<{ [props: string]: any }>({});
  const [selectedDateRange, setSelectedDateRange] = useState<[Range]>([
    {
      startDate: add(new Date(), { months: -1 }),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);

  const [selectedData, setSelectedData] = useState<string>('');
  const [modalToggle, setModalToggle] = useState<boolean>(false);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const handleModal = (data: any) => {
    setSelectedData(data);
  };

  const handleDate = (item: Range) => {
    setSelectedDateRange(() => [item]);
  };

  const handleSearch = (newPage?: boolean) => {
    if (isFetching) return;

    setIsFetching(() => true);

    let params: { [props: string]: any } = {
      page: pageNum,
      limit: pageSize,
    };

    if (newPage) {
      params.page = 1;
      setPageNum(1);
    }

    if (!!selectedDateRange[0].startDate) {
      params.from = format(new Date(selectedDateRange[0].startDate), 'yyyy-MM-dd');
    }

    if (!!selectedDateRange[0].endDate) {
      params.to = format(new Date(selectedDateRange[0].endDate), 'yyyy-MM-dd');
    }

    if (!!selectedControlType && !!selectedControlType.type) {
      params.device_command_type = selectedControlType.type;
    }

    axiosInstance({
      method: 'get',
      url: `/control/${data.device_id}/commands`,
      params: params,
    })
      .then((res) => {
        console.log(`/control/${data.device_id}/commands =>`, res);
        setHistoryList([...res.data.items]);
        setAllListCount(res.data.totalCount);
      })
      .catch((error) => {
        console.log(`/control/${data.device_id}/commands error =>`, error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });
  };

  useEffect(() => {
    if (!data || !data.device_id) return;
    handleSearch();
  }, [data, selectedControlType, pageNum, pageSize]);

  useEffect(() => {
    handleSearch(true);
  }, [selectedDateRange]);

  useEffect(() => {
    axiosInstance({
      method: 'get',
      url: `/control/commands/types`,
      params: {
        model_name: data.model_name,
      },
    })
      .then((res) => {
        console.log(`/control/commands/types =>`, res);
        setControlTypeList([...res.data]);
      })
      .catch((error) => {
        console.log(`/control/commands/types error =>`, error);
      });
  }, [data]);

  useEffect(() => {
    if (!selectedData) return;
    setModalToggle(() => true);
  }, [selectedData]);

  useEffect(() => {
    if (!!modalToggle) return;
    setSelectedData('');
  }, [modalToggle]);

  const tableDataCoverList = [
    ...controlTypeList.map((item) => ({
      header: 'command_type',
      value: item.type,
      title: item.title,
    })),
  ];

  return (
    <MonitoringDetailHistoryWrapper>
      <TopWrapper>
        <Autocomplete
          onChange={(_, v: any) => setSelectedControlType(v ?? {})}
          sx={{ minWidth: '240px' }}
          size='small'
          options={[{ type: '', title: '전체' }, ...controlTypeList]}
          getOptionLabel={(option) => option.title}
          renderInput={(params: any) => <TextField {...params} label='제어 명령' />}
        />
        <div>
          <DatePicker defaultValue={selectedDateRange[0]} onChange={handleDate} size='medium' color='secondary' />

          <Button onClick={() => handleSearch()} size='small' variant='outlined' color='secondary' sx={{ borderColor: muiBorderColor }}>
            <RefreshIcon />
          </Button>
        </div>
      </TopWrapper>
      <BottomWrapper>
        <Table
          tableId='monitoring_detail_history_table'
          dataCoverList={tableDataCoverList}
          maxHeight=''
          size='small'
          dataId='id'
          isFetching={isFetching}
          list={historyList}
          header={[
            ...getControlTableHeader(),
            {
              title: '결과',
              value: 'result',
              width: '172px',
              render: (data: any) => {
                const printList = ['success', 'fail', 'Success', 'Fail', 'SUCCESS', 'FAIL'];
                if (!data['result'] || data['result'] === '') return '-';
                else if (!!data['result'] && printList.includes(data['result'])) return data['result'];
                else {
                  return (
                    <Button onClick={() => handleModal(data['result'])} variant='outlined' color='secondary' size='small'>
                      상세 보기
                    </Button>
                  );
                }
              },
            },
          ]}
        />
        <div>
          {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
        </div>
      </BottomWrapper>
      <Modal open={modalToggle} onClose={() => setModalToggle(() => false)}>
        <>
          <ModalContent>
            <ModalInnerWrapper>
              {selectedData.split(',').map((item: string, index: number) => {
                return (
                  <div key={index}>
                    <pre>{item}</pre>
                  </div>
                );
              })}
            </ModalInnerWrapper>
            <ModalBottomWrapper>
              <Button onClick={() => setModalToggle(() => false)} variant='outlined' color='secondary' size='medium'>
                닫기
              </Button>
            </ModalBottomWrapper>
          </ModalContent>
        </>
      </Modal>
    </MonitoringDetailHistoryWrapper>
  );
};

export default MonitoringDetailHistory;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;
  & > div {
    display: flex;
    & > pre {
      ${caption2};
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
`;
