import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { set } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';

type Props = {
  onChange: Dispatch<SetStateAction<{ hour?: number; minute?: number }>>;
  value: { hour?: number; minute?: number };
  label?: string;
};

const TimePicker = ({ onChange, value, label }: Props) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const handleChange = (e: Date | null) => {
    if (!!e) {
      console.log(e);
      try {
        const h = e.getHours();
        const m = e.getMinutes();
        onChange({ hour: h, minute: m });
      } catch (e) {
        onChange({ hour: undefined, minute: undefined });
      }
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePickerWrapper>
          <DesktopTimePicker
            value={
              (!!value?.hour || value.hour === 0) && (!!value?.minute || value.minute === 0)
                ? set(new Date(), {
                    hours: value?.hour,
                    minutes: value?.minute,
                  })
                : undefined
            }
            sx={{ width: '100%' }}
            ampm={false}
            label={label}
            onChange={handleChange}
            slotProps={{ textField: { size: 'small' } }}
            open={typeof toggle === 'boolean' ? toggle : undefined}
            onOpen={() => setToggle && setToggle(true)}
            onClose={() => setToggle && setToggle(false)}
          />
        </TimePickerWrapper>
      </LocalizationProvider>
      <BackWrapper $toggle={toggle} onClick={() => setToggle(false)} />
    </>
  );
};

export default TimePicker;

const TimePickerWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const BackWrapper = styled.div<{
  $toggle: boolean;
}>`
  display: ${(props) => (!!props.$toggle ? 'flex' : 'none')};
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
