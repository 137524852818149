import SearchIcon from '@mui/icons-material/Search';
import { Button, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { axiosInstance } from '../../global/axiosSetting';
import { muiBorderColor } from '../../global/css';
import { getChidren, getGroupPathName } from '../../global/utils';
import Popup from '../Popup';
import ManagementGroupSelect from './ManagementGroupSelect';
import RecursiveGroup from './RecursiveGroup';

const ManagementGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 20px;
  box-sizing: border-box;
  row-gap: 12px;
`;
const GroupListWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    column-gap: 8px;
  }
`;

const PopupButtonWrapper = styled.div`
  padding-top: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
`;

const PopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 300px;
`;

type ManagementGroupProps = {
  loginUser: { [props: string]: any };
  setMessage: (message: { [props: string]: any }) => void;
  selectedIndex: number;
};

const ManagementGroup = (props: ManagementGroupProps) => {
  const { loginUser, setMessage, selectedIndex } = props;
  const [refresh, setRefresh] = useState<boolean>(false);

  const [groupList, setGroupList] = useState<{ [props: string]: any }>({});
  const [search, setSearch] = useState<string>('');
  const [closeList, setCloseList] = useState<number[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<{ [props: string]: any }>({});
  const [allGroupList, setAllGroupList] = useState<{ [props: string]: any }[]>([]);

  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  const [popupGroup, setPopupGroup] = useState<{ [props: string]: any }>({});
  const [selectedPopupGroup, setSelectedPopupGroup] = useState<{ [props: string]: any }>({});
  const [tempPopupGroup, setTempPopupGroup] = useState<{ [props: string]: any }>({});
  const [groupSelectPopupToggle, setGroupSelectPopupToggle] = useState<boolean>(false);

  const handleCancelPopupSelectGroup = () => {
    setTempPopupGroup({});
    setGroupSelectPopupToggle(() => false);
  };

  const handleConfirmPopupSelectGroup = () => {
    setSelectedPopupGroup(tempPopupGroup);
    handleCancelPopupSelectGroup();
  };

  const handleSelectGroupPopupOpen = () => {
    if (!!selectedPopupGroup && !!selectedPopupGroup.id) {
      setTempPopupGroup({ ...selectedPopupGroup });
    }
    setGroupSelectPopupToggle(() => true);
  };

  const handlePopup = (type?: string) => {
    if (!!selectedGroup && (!!selectedGroup.id || selectedGroup.id === 0)) {
      setPopupGroup({ ...(type === 'edit' ? selectedGroup : {}), popupType: type });

      setSelectedPopupGroup({
        ...(type === 'edit' ? allGroupList.find((item) => item.id === selectedGroup.parent_id) : selectedGroup),
      });
    }

    setPopupToggle(() => true);
  };

  const handleGroup = (e: any) => {
    setPopupGroup((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDelete = () => {
    setMessage({ message: '조직 단위를 삭제하시겠습니까?', type: 'confirm', confirm: handleDeleteGroup });
  };

  const handleAddGroup = () => {
    let data: { [props: string]: any } = {
      name: popupGroup.name,
      description: popupGroup.description,
    };
    if (!!selectedPopupGroup && !!selectedPopupGroup.id) {
      data['parentId'] = selectedPopupGroup.id;
    }
    axiosInstance({
      method: 'post',
      url: '/organizations',
      data: data,
    })
      .then((res) => {
        console.log('/organizations =>', res);
        setPopupToggle(() => false);
        setMessage({ message: '조직 단위가 추가되었습니다.' });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log('/organizations error =>', error);
      });
  };

  const handleEditGroup = () => {
    let data: { [props: string]: any } = {
      name: popupGroup.name,
      description: popupGroup.description,
    };
    if (!!selectedPopupGroup && !!selectedPopupGroup.id) {
      data['parentId'] = selectedPopupGroup.id;
    }

    axiosInstance({
      method: 'patch',
      url: `/organizations/${popupGroup.id}`,
      data: data,
    })
      .then((res) => {
        console.log(`patch /organizations/${popupGroup.id} =>`, res);
        setPopupToggle(() => false);
        setMessage({
          message: '조직 단위가 수정되었습니다.',
        });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log(`patch /organizations/${popupGroup.id} error =>`, error);
      });
  };

  const handleDeleteGroup = () => {
    axiosInstance({
      method: 'delete',
      url: `/organizations/${selectedGroup.id}`,
    })
      .then((res) => {
        console.log(`delete /organizations/${selectedGroup.id} =>`, res);
        setPopupToggle(() => false);
        setMessage({ message: '조직 단위가 삭제되었습니다.' });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log(`delete /organizations/${selectedGroup.id} error =>`, error);
      });
  };

  // useEffect(() => {
  //   axiosInstance({
  //     method: 'get',
  //     url: '/organizations/search',
  //     params: {
  //       q: search,
  //     },
  //   })
  //     .then((res) => {
  //       console.log('/organizations/search =>', res);
  //     })
  //     .catch((error) => {
  //       console.log('/organizations/search error =>', error);
  //     });
  // }, [search]);

  useEffect(() => {
    axiosInstance({
      url: '/organizations',
      method: 'GET',
    })
      .then((res) => {
        console.log('/organizations =>', res);
        setGroupList(res.data);

        const arr: { [props: string]: any }[] = [];
        getChidren(res.data, arr);
        setAllGroupList(arr);
      })
      .catch((error) => {
        console.log('/organizations error =>', error);
      });
  }, [refresh]);

  useEffect(() => {
    if (!popupToggle) {
      setPopupGroup({});
      setSelectedPopupGroup({});
    }
  }, [popupToggle]);

  return (
    <>
      <ManagementGroupWrapper>
        <ButtonWrapper>
          <Button size='small' variant='contained' onClick={() => handlePopup('add')}>
            조직 추가
          </Button>
          <div>
            <Button onClick={() => handlePopup('edit')} disabled={!selectedGroup || (!selectedGroup.id && selectedGroup.id !== 0)} size='small' variant='contained' color='warning'>
              수정
            </Button>
            <Button
              onClick={handleDelete}
              disabled={!selectedGroup || (!selectedGroup.id && selectedGroup.id !== 0) || !selectedGroup.parent_id}
              size='small'
              variant='contained'
              color='error'
            >
              삭제
            </Button>
          </div>
        </ButtonWrapper>
        <TextField
          size='small'
          fullWidth
          placeholder='조직 단위 검색'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <GroupListWrapper>
          <RecursiveGroup
            type='page'
            setSearch={setSearch}
            search={search}
            closeList={closeList}
            setCloseList={setCloseList}
            selected={selectedGroup}
            setSelected={setSelectedGroup}
            data={groupList}
          />
        </GroupListWrapper>
      </ManagementGroupWrapper>
      <Popup toggle={popupToggle} setToggle={setPopupToggle}>
        <PopupContentWrapper>
          <TextField size='small' label='조직 단위의 이름' name='name' value={popupGroup.name || ''} onChange={(e) => handleGroup(e)} />
          <TextField size='small' label='설명' name='description' value={popupGroup.description || ''} onChange={(e) => handleGroup(e)} />

          {!!popupGroup && ((popupGroup.popupType === 'edit' && !!popupGroup.parent_id) || popupGroup.popupType !== 'edit') && (
            <Button onClick={handleSelectGroupPopupOpen} sx={{ borderColor: muiBorderColor }} variant='outlined' color='secondary'>
              {!!selectedPopupGroup && (!!selectedPopupGroup.id || selectedPopupGroup.id === 0) && !!getGroupPathName(selectedPopupGroup, allGroupList)
                ? getGroupPathName(selectedPopupGroup, allGroupList)
                : '조직 선택'}
            </Button>
          )}

          <PopupButtonWrapper>
            <Button variant='contained' size='medium' color='secondary' onClick={() => setPopupToggle(() => false)}>
              취소
            </Button>
            {popupGroup.popupType === 'edit' ? (
              <Button onClick={handleEditGroup} disabled={!popupGroup || !popupGroup.name} color='warning' variant='contained' size='medium'>
                수정
              </Button>
            ) : (
              <Button
                disabled={!popupGroup || !popupGroup.name || (!selectedPopupGroup.id && selectedPopupGroup.id !== 0)}
                variant='contained'
                size='medium'
                onClick={handleAddGroup}
              >
                추가
              </Button>
            )}
          </PopupButtonWrapper>
        </PopupContentWrapper>
      </Popup>
      <Popup toggle={groupSelectPopupToggle} setToggle={setGroupSelectPopupToggle}>
        <ManagementGroupSelect type={popupGroup.popupType} originalSelected={selectedPopupGroup} selected={tempPopupGroup} setSelected={setTempPopupGroup} />
        <PopupButtonWrapper>
          <Button variant='contained' size='medium' color='secondary' onClick={handleCancelPopupSelectGroup}>
            취소
          </Button>
          <Button variant='contained' size='medium' onClick={handleConfirmPopupSelectGroup}>
            확인
          </Button>
        </PopupButtonWrapper>
      </Popup>
    </>
  );
};

export default ManagementGroup;
