import { Button, Modal } from '@mui/material';
import { add, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import styled from 'styled-components';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { green600, grey200, muiBorderColor } from '../global/css';

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
`;

const DateRangebuttonWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  width: 100%;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 8px;
`;

const DateContentWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${grey200};
  border-radius: 12px;
  box-sizing: border-box;
  background-color: white;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

type DatePickerProps = {
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  onChange?: (item: Range) => void;
  defaultValue?: Range;
  noDefaultValue?: boolean;
  noMaxDate?: boolean;
  backgroundColor?: string;
  title?: string;
  placeholder?: string;
  minDate?: Date;
};

const DatePicker = (props: DatePickerProps) => {
  const { minDate, placeholder, title, backgroundColor, noDefaultValue, size, variant = 'outlined', color = 'primary', onChange, defaultValue, noMaxDate = false } = props;
  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  const [checkDateRange, setCheckDateRange] = useState<[Range]>(
    !!noDefaultValue
      ? [
          {
            key: 'selection',
          },
        ]
      : [
          !!defaultValue
            ? defaultValue
            : {
                startDate: add(new Date(), { days: -7 }),
                endDate: new Date(),
                key: 'selection',
              },
        ]
  );

  const [selectedDateRange, setSelectedDateRange] = useState<[Range]>(
    !!noDefaultValue
      ? [{}]
      : [
          !!defaultValue
            ? defaultValue
            : {
                startDate: add(new Date(), { days: -7 }),
                endDate: new Date(),
                key: 'selection',
              },
        ]
  );

  const handleDate = (e: any) => {
    setCheckDateRange([e.selection]);
  };

  const handleCancel = () => {
    setCheckDateRange(() => selectedDateRange);
    setPopupToggle(() => false);
  };

  const handleApply = () => {
    setSelectedDateRange(() => checkDateRange);
    setPopupToggle(() => false);
  };

  useEffect(() => {
    if (!!onChange) {
      onChange(selectedDateRange[0]);
    }
  }, [selectedDateRange]);

  return (
    <DatePickerWrapper>
      <Button
        title={!!title ? title : '날짜 필터 적용'}
        sx={{ minWidth: '160px', height: '100%', borderColor: muiBorderColor, backgroundColor: !!backgroundColor ? backgroundColor : 'initial' }}
        onClick={() => setPopupToggle(() => true)}
        size={size}
        variant={variant}
        color={color}
      >
        {!!noDefaultValue && (!selectedDateRange || !selectedDateRange[0] || !selectedDateRange[0].startDate) ? (
          !!placeholder ? (
            placeholder
          ) : (
            '날짜를 선택해주세요.'
          )
        ) : (
          <>
            {!!selectedDateRange && !!selectedDateRange[0] && !!selectedDateRange[0].startDate && format(new Date(selectedDateRange[0].startDate), 'yyyy. MM. dd')}&nbsp;~&nbsp;
            {!!selectedDateRange && !!selectedDateRange[0] && !!selectedDateRange[0].endDate && format(new Date(selectedDateRange[0].endDate), 'yyyy. MM. dd')}
          </>
        )}
      </Button>

      <Modal open={popupToggle} onClose={() => setPopupToggle(() => false)}>
        <>
          <DateContentWrapper>
            <DateRange
              editableDateInputs={false}
              onChange={handleDate}
              moveRangeOnFirstSelection={true}
              maxDate={!!noMaxDate ? undefined : new Date()}
              minDate={!!minDate ? minDate : undefined}
              ranges={checkDateRange}
              rangeColors={[green600]}
            />
            <DateRangebuttonWrapper>
              <Button onClick={handleCancel} variant='contained' color='secondary' size='small'>
                취소
              </Button>
              <Button onClick={handleApply} variant='contained' size='small'>
                적용
              </Button>
            </DateRangebuttonWrapper>
          </DateContentWrapper>
        </>
      </Modal>
    </DatePickerWrapper>
  );
};

export default DatePicker;
