import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from '../table/Table';
import { axiosInstance } from '../../global/axiosSetting';
import { getMemberTableHeader } from '../../global/types';
import Popup from '../Popup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ManagementGroupSelect from './ManagementGroupSelect';
import { muiBorderColor } from '../../global/css';
import TablePagination from '../table/TablePagination';
import { getGroupPathName } from '../../global/utils';

const ManagementUserWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: white;
`;

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserListWrapper = styled.div``;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  box-sizing: border-box;
  & > div:last-of-type {
    display: flex;
    column-gap: 8px;
  }
`;

const PopupButtonWrapper = styled.div`
  padding-top: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
`;

const PopupContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

type ManagementUserProps = {
  loginUser: { [props: string]: any };
  setMessage: (message: { [props: string]: any }) => void;
  selectedIndex: number;
};

const ManagementUser = (props: ManagementUserProps) => {
  const { loginUser, selectedIndex, setMessage } = props;
  const [userList, setUserList] = useState<{ [props: string]: any }[]>([]);
  const [editToggle, setEditToggle] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [popupUser, setPopupUser] = useState<{ [props: string]: any }>({});
  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  const [groupSelectPopupToggle, setGroupSelectPopupToggle] = useState<boolean>(false);
  const [selectedPopupGroup, setSelectedPopupGroup] = useState<{ [props: string]: any }>({});
  const [tempPopupGroup, setTempPopupGroup] = useState<{ [props: string]: any }>({});

  const [selectedGroup, setSelectedGroup] = useState<{ [props: string]: any }>({});
  const [allGroupList, setAllGroupList] = useState<{ [props: string]: any }[]>([]);

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [allListCount, setAllListCount] = useState<number>(0);
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);

  const handleCancelPopupSelectGroup = () => {
    setTempPopupGroup({});
    setGroupSelectPopupToggle(() => false);
  };

  const handleConfirmPopupSelectGroup = () => {
    setSelectedPopupGroup(tempPopupGroup);
    handleCancelPopupSelectGroup();
  };
  const handlePopup = (type?: string) => {
    if (!!type && type === 'edit') {
      if (!!selectedUser && !!selectedUser.length) {
        setPopupUser({ ...userList.find((u) => u.id === selectedUser[0]), popupType: 'edit' });
        const selectedG = allGroupList.find((a) => a.id === { ...userList.find((u) => u.id === selectedUser[0]) }.organization_id);
        setSelectedPopupGroup({
          ...selectedG,
        });
      }
    }
    setPopupToggle(() => true);
  };

  const handleUser = (e: any) => {
    setPopupUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleEditUser = () => {
    let data: { [props: string]: any } = {
      password: popupUser.password,
      permission: popupUser.permission,
    };
    if (!popupUser.password) {
      delete data.password;
    }
    if ((!!selectedPopupGroup && !!selectedPopupGroup.id) || selectedPopupGroup.id === 0) {
      data['organization_id'] = selectedPopupGroup.id;
    }

    axiosInstance({
      method: 'patch',
      url: `/users/${popupUser.id}`,
      data: data,
    })
      .then((res) => {
        console.log(`patch /users/${popupUser.id} =>`, res);
        setPopupToggle(() => false);
        setMessage({
          message: '사용자가 수정되었습니다.',
        });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log(`patch /users/${popupUser.id} error =>`, error);
      });
  };

  const handleDelete = () => {
    setMessage({ message: '사용자를 삭제하시겠습니까?', type: 'confirm', confirm: handleDeleteUser });
  };

  const handleDeleteUser = () => {
    axiosInstance({
      method: 'delete',
      url: `/users/${selectedUser[0]}`,
    })
      .then((res) => {
        console.log(`delete /users/${popupUser.id} =>`, res);
        setPopupToggle(() => false);
        setMessage({ message: '사용자가 삭제되었습니다.' });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log(`delete /users/${popupUser.id} error =>`, error);
      });
  };

  const handleSelectGroupPopupOpen = () => {
    if (!!selectedPopupGroup && !!selectedPopupGroup.id) {
      setTempPopupGroup({ ...selectedPopupGroup });
    }
    setGroupSelectPopupToggle(() => true);
  };

  const handleAddUser = () => {
    axiosInstance({
      method: 'post',
      url: '/users',
      data: {
        email: popupUser.email,
        name: popupUser.name,
        password: popupUser.password,
        permission: popupUser.permission,
        organization_id: selectedPopupGroup.id,
      },
    })
      .then((res) => {
        console.log('/users =>', res);
        setPopupToggle(() => false);
        setMessage({ message: '사용자가 추가되었습니다.' });
        setRefresh((prev) => !prev);
      })
      .catch((error) => {
        console.log('/users error =>', error);
      });
  };

  useEffect(() => {
    if (!popupToggle) {
      setPopupUser({});
      setSelectedPopupGroup({});
    }
  }, [popupToggle]);

  useEffect(() => {
    if (!editToggle) {
      setSelectedUser([]);
    }
  }, [editToggle]);

  useEffect(() => {
    setSelectedUser([]);
  }, [pageNum, pageSize]);

  useEffect(() => {
    setEditToggle(() => false);

    if (!selectedGroup || (!selectedGroup.id && selectedGroup.id !== 0)) return;

    setIsFetching(() => true);

    axiosInstance({
      method: 'get',
      url: '/users',
      params: {
        organization_id: selectedGroup.id,
        page: pageNum,
        limit: pageSize,
      },
    })
      .then((res) => {
        console.log('/users =>', res);
        setAllListCount(res.data.totalCount);
        setUserList([...res.data.items]);
      })
      .catch((error) => {
        console.log('/users error =>', error);
      })
      .finally(() => {
        setIsFetching(() => false);
      });
  }, [refresh, selectedGroup, pageNum, pageSize]);

  useEffect(() => {
    if (selectedIndex === 0) {
      setRefresh((prev) => !prev);
    } else if (selectedIndex === 1) {
      setUserList([]);
      setEditToggle(() => false);

      setSelectedUser([]);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (!loginUser || (!loginUser.id && loginUser.id !== 0)) return;

    setSelectedGroup({ id: loginUser.organization_id });
  }, [loginUser]);

  useEffect(() => {
    const getChidren = (data: { [props: string]: any }, arr: { [props: string]: any }[]) => {
      arr.push(data);
      if (!!data.children && !!data.children.length) {
        for (const child of data.children) {
          getChidren(child, arr);
        }
      }

      return arr;
    };

    axiosInstance({
      url: '/organizations',
      method: 'GET',
    })
      .then((res) => {
        console.log('/organizations =>', res);
        const arr: { [props: string]: any }[] = [];

        getChidren(res.data, arr);
        setAllGroupList(arr);
      })
      .catch((error) => {
        console.log('/organizations error =>', error);
      });

    const savedSelectedGroup = sessionStorage.getItem('manage-user-selectedGroup');
    if (!!savedSelectedGroup) {
      setSelectedGroup(JSON.parse(savedSelectedGroup));
    }

    //테이블 높이 계산
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setInnerHeight(window.innerHeight);
      });

      sessionStorage.setItem('manage-user-selectedGroup', JSON.stringify(selectedGroup));
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem('manage-user-selectedGroup', JSON.stringify(selectedGroup));
  }, [selectedGroup]);

  return (
    <>
      <ManagementUserWrapper>
        <GroupWrapper>
          <ManagementGroupSelect selected={selectedGroup} setSelected={setSelectedGroup} />
        </GroupWrapper>
        <UserListWrapper>
          <TopWrapper>
            <Button size='small' variant='contained' onClick={() => handlePopup('add')}>
              사용자 추가
            </Button>
            <div>
              {!!editToggle ? (
                <React.Fragment>
                  <Button size='small' variant='contained' color='secondary' onClick={() => setEditToggle(() => false)}>
                    취소
                  </Button>
                  <Button onClick={() => handlePopup('edit')} size='small' variant='contained' color='warning' disabled={!selectedUser || !selectedUser.length}>
                    수정
                  </Button>
                  <Button onClick={handleDelete} size='small' variant='contained' color='error' disabled={!selectedUser || !selectedUser.length}>
                    삭제
                  </Button>
                </React.Fragment>
              ) : (
                <Button size='small' variant='contained' onClick={() => setEditToggle(() => true)}>
                  편집
                </Button>
              )}
            </div>
          </TopWrapper>
          <TableWrapper>
            <Table
              tableId='member_table'
              isFetching={isFetching}
              type={!!editToggle ? 'radio' : ''}
              header={getMemberTableHeader()}
              size='small'
              list={[...userList]}
              selectedItemList={selectedUser}
              setSelectedItemList={setSelectedUser}
              maxHeight={`${innerHeight - 244}px`}
              fullWidth
            />
            <div>
              {!!allListCount && !isFetching && <TablePagination count={allListCount} page={pageNum} rowsPerPage={pageSize} setPage={setPageNum} setRowsPerPage={setPageSize} />}
            </div>
          </TableWrapper>
        </UserListWrapper>
      </ManagementUserWrapper>
      <Popup toggle={popupToggle} setToggle={setPopupToggle}>
        <PopupContentWrapper>
          <FormControl size='small' fullWidth>
            <InputLabel size='small' id='demo-simple-select-label'>
              역할
            </InputLabel>
            <Select size='small' value={popupUser.permission || ''} name='permission' label='역할' onChange={(e) => handleUser(e)}>
              <MenuItem value={'admin'}>관리자</MenuItem>
              <MenuItem value={'viewer'}>뷰어</MenuItem>
            </Select>
          </FormControl>
          <TextField disabled={popupUser.popupType === 'edit'} size='small' label='이름' name='name' value={popupUser.name || ''} onChange={(e) => handleUser(e)} />
          <TextField disabled={popupUser.popupType === 'edit'} size='small' label='이메일' name='email' value={popupUser.email || ''} onChange={(e) => handleUser(e)} />
          <FormControl fullWidth variant='outlined'>
            <InputLabel size='small' htmlFor='user-password'>
              비밀번호
            </InputLabel>
            <OutlinedInput
              id='user-password'
              type={showPassword ? 'text' : 'password'}
              name='password'
              value={popupUser.password || ''}
              onChange={(e) => handleUser(e)}
              size='small'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton size='small' onClick={() => setShowPassword((show) => !show)} onMouseDown={(e) => e.preventDefault()} edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='비밀번호'
            />
          </FormControl>
          <Button onClick={handleSelectGroupPopupOpen} sx={{ borderColor: muiBorderColor }} variant='outlined' color='secondary'>
            {!!selectedPopupGroup && (!!selectedPopupGroup.id || selectedPopupGroup.id === 0) && !!getGroupPathName(selectedPopupGroup, allGroupList)
              ? getGroupPathName(selectedPopupGroup, allGroupList)
              : '조직 선택'}
          </Button>

          <PopupButtonWrapper>
            <Button variant='contained' size='medium' color='secondary' onClick={() => setPopupToggle(() => false)}>
              취소
            </Button>
            {popupUser.popupType === 'edit' ? (
              <Button onClick={handleEditUser} disabled={!popupUser || !popupUser.name || !popupUser.email} color='warning' variant='contained' size='medium'>
                수정
              </Button>
            ) : (
              <Button
                disabled={
                  !popupUser || !popupUser.name || !popupUser.email || !popupUser.password || !popupUser.permission || (!selectedPopupGroup.id && selectedPopupGroup.id !== 0)
                }
                variant='contained'
                size='medium'
                onClick={handleAddUser}
              >
                추가
              </Button>
            )}
          </PopupButtonWrapper>
        </PopupContentWrapper>
      </Popup>
      <Popup toggle={groupSelectPopupToggle} setToggle={setGroupSelectPopupToggle}>
        <ManagementGroupSelect selected={tempPopupGroup} setSelected={setTempPopupGroup} />
        <PopupButtonWrapper>
          <Button variant='contained' size='medium' color='secondary' onClick={handleCancelPopupSelectGroup}>
            취소
          </Button>
          <Button variant='contained' size='medium' onClick={handleConfirmPopupSelectGroup}>
            확인
          </Button>
        </PopupButtonWrapper>
      </Popup>
    </>
  );
};

export default ManagementUser;
